<h1>Fragebogen Auswertung in SPSS </h1>

<h2>Import der Daten</h2>

<p>Zuerst musst du die Daten importieren. Viele Umfragetools bieten bereits das SPSS .sps Format an. 
    Einfach geht es auch mit Excel. Eine .xlsx Datei kannst du einfach über <i> Datei🡪öffnen🡪Daten </i> einlesen. Wichtig! 
    Du musst den Datentyp ändern in Alle Formate. <br>
Optional kannst du auch .csv Daten einlesen. Hier musst du jedoch überprüfen, mit welchen Sonderzeichen Variablen und Werte getrennt sind. SPSS bietet dir eine Vorschau an.</p>
<img src="../../assets/img/fragenbogen/fragebogen_oeffnen.png" alt="fragebogen öffnen">
<h2>
Datenaufbereitung</h2>
<p>In SPSS stellt die Datenanasicht nun eine einfache zweidimensionale Übersicht deiner Daten dar. 
    Zeilen sind Versuchspersonen und Spalten Variablen. Unter Variablenansicht kannst du die Variablen bearbeiten.
</p>
<img src="../../assets/img/fragenbogen/fragenbogen_ansicht.png" alt="fragenbogen ansicht">
<h4>Überflüssige Variablen Löschen</h4>

<p>Datenanalyse lebt von Übersichtlichkeit. Lösche also was du nicht brauchst, z.B. Browserdaten, Time Tracker, Indexvariablen etc...</p>
<img src="../../assets/img/fragenbogen/fragebogen_spalte_bearbeitenn.png" alt="fragebogen_spalte_bearbeitenn">
<h4>Versuchspersonen filtern</h4>
Lösche die Versuchspersonen, die den Fragebogen abgebrochen oder gar nicht ausgefüllt haben.
<img src="../../assets/img/fragenbogen/fragebogen_zeile bearbeiten.png" alt="fragebogen_zeile bearbeiten">

<h4>
Variablen labeln und beschriften</h4>

<p>Beschrifte die Variablen, damit die Grafiken richtig beschriftet und die Häufigkeitstabellen übersichtlicher sind.</p>
<img src="../../assets/img/fragenbogen/fragenbogen_werte_beschriftung.png" alt="fragenbogen_werte_beschriftung">

<h4>
Fehlende Werte löschen oder ersetzen</h4>
Was machst du mit einzelnen fehlenden Werten? Man kann hier die gesamte Versuchsperson entfernen oder fehlende Werte ersetzten, z.B. durch eine Mittelwert Imputation. <br><br>
<h4>
Skalenniveaus anpassen</h4>
Skalenniveaus stellen ein sehr wichtiges Thema dar. Von Ihnen hängt ab, was du mit deinen Daten machen kannst. Bestimme also, ob es nominale, ordinale oder metrische Daten sind. <br><br>
<img src="../../assets/img/fragenbogen/fragenbogen_einstellen.png" alt="fragenbogen_einstellen">
<p><u style="cursor: pointer" routerLink="/skalenniveau">Zu den Skalenniveaus </u></p>
<h4>
Reliabilitätsanalyse</h4>
Üblicherweise werden Konstrukte durch mehrere Items operationalisiert. Damit dies jedoch funktioniert, solltest du einmal die Reliabilität überprüfen. Hier kannst du Cronbachs-Alpha oder McDonalds-Omega bestimmen. <br><br>
<h4>
Skalen erstellen</h4>
Ist deine Skala reliabel, kannst du die Items zu einem Score zusammenfassen. Dabei wird häufig der Mittelwert gewählt. <br><br>

<h2>Deskriptive Statistik</h2>
Im ersten Schritt musst du deine Daten nun einmal beschrieben. Hier spielt das Skalenniveau wieder eine entscheidende Rolle, denn von diesem ist der Informationsgehalt deiner Daten abhängig. Dieser entscheidet, was du mit den Daten machen kannst. Einen Mittelwert der Variable, die das Geschlecht einer Person angibt, zu berechnen, ergibt keinen Sinn. Genau so wenig wie einfach Häufigkeiten einer Variablen anzugeben, die das Alter in Jahren misst. Am besten gehst du wie folgt vor: <br><br>
<b>
Häufigkeiten für nominale und ordinale Daten<br>
Mediane für ordinale Daten<br>
Statistische Kennwerte und Test auf Normalverteilung für metrische Daten</b><br><br>

<h2>Inferenzstatistik</h2>
	Nun folgen die finalen Tests deiner Hypothesen
<h4>Den richtigen Test finden. </h4>

<p>Hier spielen nun deine Hypothesen und die Skalenniveaus eine entscheidende Rolle. In unserer Testübersicht kannst du dir einen Überblick verschaffen. </p>
<u style="cursor: pointer" routerLink="/statistische-tests/testuebersicht">Zur Testübersicht </u>
<h4>
Im Hinblick auf Hypothesen durchführen</h4>


<p>Hier kannst du dir einen Überblick über die verschiedenen Arten der Hypothesentests verschaffen, wie Signifikanzwerte, Effektstärke oder Bayes-Statistiken. 

</p>
<u style="cursor: pointer" routerLink="/statistische-tests/hypothesen-tests">Zu den Hypothesentests </u>
