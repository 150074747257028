import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-effekstaerkenrechner',
  templateUrl: './effekstaerkenrechner.component.html',
  styleUrls: ['./effekstaerkenrechner.component.scss'],
})
export class EffekstaerkenrechnerComponent implements OnInit {
  constructor(private titleService: Title, private route: ActivatedRoute) {}
  formulaOneForm: UntypedFormGroup;
  formulaOneForm2: UntypedFormGroup;
  formulaOneForm3: UntypedFormGroup;
  formulaTwoForm: UntypedFormGroup;
  formulaThreeForm: UntypedFormGroup;
  formulaFourForm: UntypedFormGroup;
  formulaFourForm2: UntypedFormGroup;
  formulaFourForm3: UntypedFormGroup;
  formulaFourForm4: UntypedFormGroup;
  formulaFourForm5: UntypedFormGroup;
  formulaFiveForm: UntypedFormGroup;
  formulaFiveForm2: UntypedFormGroup;
  formulaSixForm: UntypedFormGroup;
  formulaSevenForm: UntypedFormGroup;
  formulaEightForm: UntypedFormGroup;
  formulaNineForm: UntypedFormGroup;
  formulaTenForm: UntypedFormGroup;

  solutionOne: string = null;
  solutionOne2: string = null;
  solutionOne3: string = null;
  solutionTwo: string = null;
  solutionThree: string = null;
  solutionFour: string = null;
  solutionFour2: string = null;
  solutionFour3: string = null;
  solutionFour4: string = null;
  solutionFour5: string = null;
  solutionFive: string = null;
  solutionFive2: string = null;
  solutionSix: string = null;
  solutionSeven: string = null;
  solutionEight: string = null;
  solutionNine: string = null;
  solutionTen: string = null;

  ngOnInit(): void {
    this.titleService.setTitle('Hanseatic Statistics - Effektstärkenrechner');
    this.route.fragment.subscribe((fragment: string) => {
      if (fragment) this.scroll(fragment);
    });

    this.formulaOneForm = new UntypedFormGroup({
      f1t: new UntypedFormControl('', Validators.required),
      f1f: new UntypedFormControl('', Validators.required),
    });

    this.formulaOneForm2 = new UntypedFormGroup({
      f1t: new UntypedFormControl('', Validators.required),
      f1f: new UntypedFormControl('', Validators.required),
    });

    this.formulaOneForm3 = new UntypedFormGroup({
      f1t: new UntypedFormControl('', Validators.required),
      f1f: new UntypedFormControl('', Validators.required),
    });

    this.formulaTwoForm = new UntypedFormGroup({
      f2m1: new UntypedFormControl('', Validators.required),
      f2m2: new UntypedFormControl('', Validators.required),
      f2d: new UntypedFormControl('', Validators.required),
    });

    this.formulaThreeForm = new UntypedFormGroup({
      f3m1: new UntypedFormControl('', Validators.required),
      f3m2: new UntypedFormControl('', Validators.required),
      f3s1: new UntypedFormControl('', Validators.required),
      f3s2: new UntypedFormControl('', Validators.required),
      f3n1: new UntypedFormControl('', Validators.required),
      f3n2: new UntypedFormControl('', Validators.required),
    });

    this.formulaFourForm = new UntypedFormGroup({
      f4z: new UntypedFormControl('', Validators.required),
      f4n: new UntypedFormControl('', Validators.required),
    });

    this.formulaFourForm2 = new UntypedFormGroup({
      f4z: new UntypedFormControl('', Validators.required),
      f4n: new UntypedFormControl('', Validators.required),
    });

    this.formulaFourForm3 = new UntypedFormGroup({
      f4z: new UntypedFormControl('', Validators.required),
      f4n: new UntypedFormControl('', Validators.required),
    });

    this.formulaFourForm4 = new UntypedFormGroup({
      f4z: new UntypedFormControl('', Validators.required),
      f4n: new UntypedFormControl('', Validators.required),
    });

    this.formulaFourForm5 = new UntypedFormGroup({
      f4z: new UntypedFormControl('', Validators.required),
      f4n: new UntypedFormControl('', Validators.required),
    });

    this.formulaFiveForm = new UntypedFormGroup({
      f5t: new UntypedFormControl('', Validators.required),
      f5n: new UntypedFormControl('', Validators.required),
    });

    this.formulaFiveForm2 = new UntypedFormGroup({
      f5t: new UntypedFormControl('', Validators.required),
      f5n: new UntypedFormControl('', Validators.required),
    });

    this.formulaSixForm = new UntypedFormGroup({
      f6n: new UntypedFormControl('', Validators.required),
    });

    this.formulaEightForm = new UntypedFormGroup({
      f8x: new UntypedFormControl('', Validators.required),
      f8n: new UntypedFormControl('', Validators.required),
      f8m: new UntypedFormControl('', Validators.required),
    });

    this.formulaNineForm = new UntypedFormGroup({
      f9r: new UntypedFormControl('', Validators.required),
    });

    this.formulaTenForm = new UntypedFormGroup({
      f10r: new UntypedFormControl('', Validators.required),
    });
  }

  scroll(id: string) {
    const el = document.getElementById(id);
    el.scrollIntoView();
    window.scrollBy(0, -100);
  }

  calculateFormulaOne() {
    const t: number = this.formulaOneForm.value.f1t;
    const f: number = this.formulaOneForm.value.f1f;
    this.solutionOne = Math.sqrt(Math.pow(t, 2) / (Math.pow(t, 2) + f)).toFixed(
      2
    );
  }

  calculateFormulaOne2() {
    const t: number = this.formulaOneForm2.value.f1t;
    const f: number = this.formulaOneForm2.value.f1f;
    this.solutionOne2 = Math.sqrt(
      Math.pow(t, 2) / (Math.pow(t, 2) + f)
    ).toFixed(2);
  }

  calculateFormulaOne3() {
    const t: number = this.formulaOneForm3.value.f1t;
    const f: number = this.formulaOneForm3.value.f1f;
    this.solutionOne3 = Math.sqrt(
      Math.pow(t, 2) / (Math.pow(t, 2) + f)
    ).toFixed(2);
  }

  calculateFormulaTwo() {
    const m1: number = this.formulaTwoForm.value.f2m1;
    const m2: number = this.formulaTwoForm.value.f2m2;
    const d: number = this.formulaTwoForm.value.f2d;
    this.solutionTwo = ((m1 - m2) / d).toFixed(2);
  }

  calculateFormulaThree() {
    const m1: number = this.formulaThreeForm.value.f3m1;
    const m2: number = this.formulaThreeForm.value.f3m2;
    const n1: number = this.formulaThreeForm.value.f3n1;
    const n2: number = this.formulaThreeForm.value.f3n2;
    const s1: number = this.formulaThreeForm.value.f3s1;
    const s2: number = this.formulaThreeForm.value.f3s2;
    this.solutionThree = (
      (m1 - m2) /
      Math.sqrt(
        ((n1 - 1) * Math.pow(s1, 2) + (n2 - 1) * Math.pow(s2, 2)) /
          (n1 + n2 - 2)
      )
    ).toFixed(2);
  }

  calculateFormulaFour() {
    const z: number = this.formulaFourForm.value.f4z;
    const n: number = this.formulaFourForm.value.f4n;
    this.solutionFour = Math.abs(z / Math.sqrt(n)).toFixed(2);
  }

  calculateFormulaFour2() {
    const z: number = this.formulaFourForm2.value.f4z;
    const n: number = this.formulaFourForm2.value.f4n;
    this.solutionFour2 = Math.abs(z / Math.sqrt(n)).toFixed(2);
  }

  calculateFormulaFour3() {
    const z: number = this.formulaFourForm3.value.f4z;
    const n: number = this.formulaFourForm3.value.f4n;
    this.solutionFour3 = Math.abs(z / Math.sqrt(n)).toFixed(2);
  }

  calculateFormulaFour4() {
    const z: number = this.formulaFourForm4.value.f4z;
    const n: number = this.formulaFourForm4.value.f4n;
    this.solutionFour4 = Math.abs(z / Math.sqrt(n)).toFixed(2);
  }

  calculateFormulaFour5() {
    const z: number = this.formulaFourForm5.value.f4z;
    const n: number = this.formulaFourForm5.value.f4n;
    this.solutionFour5 = Math.abs(z / Math.sqrt(n)).toFixed(2);
  }

  calculateFormulaFive() {
    const t: number = this.formulaFiveForm.value.f5t;
    const n: number = this.formulaFiveForm.value.f5n;
    this.solutionFive = (Math.abs(t) / Math.sqrt(n)).toFixed(2);
  }

  calculateFormulaFive2() {
    const t: number = this.formulaFiveForm2.value.f5t;
    const n: number = this.formulaFiveForm2.value.f5n;
    this.solutionFive2 = (Math.abs(t) / Math.sqrt(n)).toFixed(2);
  }

  calculateFormulaSix() {
    const n: number = this.formulaSixForm.value.f6n;
    this.solutionSix = Math.sqrt(n / (1 - n)).toFixed(2);
  }

  calculateFormulaSeven() {
    const h1: number = this.formulaSevenForm.value.f7h1;
    const h2: number = this.formulaSevenForm.value.f7h2;
    const h3: number = this.formulaSevenForm.value.f7h3;
    const h4: number = this.formulaSevenForm.value.f7h4;
  }

  calculateFormulaEight() {
    const x: number = this.formulaEightForm.value.f8x;
    const n: number = this.formulaEightForm.value.f8n;
    const m: number = this.formulaEightForm.value.f8m;

    this.solutionEight = Math.sqrt(Math.pow(x, 2) / (n * m)).toFixed(2);
  }
  calculateFormulaNine() {
    const r: number = this.formulaNineForm.value.f9r;

    this.solutionNine = (r / (1 - r)).toFixed(2);
  }
  calculateFormulaTen() {
    const r: number = this.formulaTenForm.value.f10r;

    this.solutionTen = (r / (1 - r)).toFixed(2);
  }
}
