import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { RoutingModule } from './routing.module';
import { StatTestComponent } from './stat-test/stat-test.component';
import { ErstgespraechComponent } from './erstgespraech/erstgespraech.component';
import { QualiComponent } from './quali/quali.component';
import { ReferenzenComponent } from './referenzen/referenzen.component';
import { PreiseComponent } from './preise/preise.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { DsgvoComponent } from './dsgvo/dsgvo.component';
import { LeistungenComponent } from './leistungen/leistungen.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { LinearRegComponent } from './stat-test/linear-reg/linear-reg.component';
import { TTestQComponent } from './stat-test/t-test-q/t-test-q.component';
import { LogRegComponent } from './stat-test/log-reg/log-reg.component';
import { TTestLComponent } from './stat-test/t-test-l/t-test-l.component';
import { UTestQComponent } from './stat-test/u-test-q/u-test-q.component';
import { LoginComponent } from './login/login.component';
import { StatistikAuswertungComponent } from './statistik-auswertung/statistik-auswertung.component';
import { SpssAuswertungComponent } from './statistik-auswertung/spss-auswertung/spss-auswertung.component';
import { RAuswertungComponent } from './statistik-auswertung/r-auswertung/r-auswertung.component';
import { JamoviAuswertungComponent } from './statistik-auswertung/jamovi-auswertung/jamovi-auswertung.component';
import { PythonAuswertungComponent } from './statistik-auswertung/python-auswertung/python-auswertung.component';
import { JaspAuswertungComponent } from './statistik-auswertung/jasp-auswertung/jasp-auswertung.component';
import { MatlabAuswertungComponent } from './statistik-auswertung/matlab-auswertung/matlab-auswertung.component';
import { PlsAuswertungComponent } from './statistik-auswertung/pls-auswertung/pls-auswertung.component';
import { StataAuswertungComponent } from './statistik-auswertung/stata-auswertung/stata-auswertung.component';
import { HypothesenComponent } from './hypothesen/hypothesen.component';
import { EffekstaerkenrechnerComponent } from './effekstaerkenrechner/effekstaerkenrechner.component';
import { FragebogenComponent } from './fragebogen/fragebogen.component';
import { FragebogenSpssComponent } from './fragebogen/fragebogen-spss/fragebogen-spss.component';
import { FragebogenRComponent } from './fragebogen/fragebogen-r/fragebogen-r.component';
import { FragebogenJamoviComponent } from './fragebogen/fragebogen-jamovi/fragebogen-jamovi.component';
import { FragebogenStataComponent } from './fragebogen/fragebogen-stata/fragebogen-stata.component';
import { FragebogenJaspComponent } from './fragebogen/fragebogen-jasp/fragebogen-jasp.component';
import { SkalenniveauComponent } from './skalenniveau/skalenniveau.component';
import { BlogViewComponent } from './blog-view/blog-view.component';
import { CookieService } from 'ngx-cookie-service';
import { EditBlogComponent } from './login/edit-blog/edit-blog.component';
import { EditArCountComponent } from './login/edit-ar-count/edit-ar-count.component';
import { EditReviewsComponent } from './login/edit-reviews/edit-reviews.component';
import { SingleBlogViewComponent } from './blog-view/single-blog-view/single-blog-view.component';
import {
  NgcCookieConsentConfig,
  NgcCookieConsentModule,
} from 'ngx-cookieconsent';
import { EditPriceComponent } from './login/edit-price/edit-price.component';
import { AnovaOneWayComponent } from './stat-test/anova-one-way/anova-one-way.component';
import { ModMedComponent } from './stat-test/mod-med/mod-med.component';
import { ModRComponent } from './stat-test/mod-med/mod-r/mod-r.component';
import { ModSpssComponent } from './stat-test/mod-med/mod-spss/mod-spss.component';
import { MedSpssComponent } from './stat-test/mod-med/med-spss/med-spss.component';
import { MedRComponent } from './stat-test/mod-med/med-r/med-r.component';
import { BusinessComponent } from './business/business.component';
import { TestDevComponent } from './test-dev/test-dev.component';
import { HomeCorporateComponent } from './home-corporate/home-corporate.component';
import { EntryComponent } from './entry/entry.component';
import { PreisCorpComponent } from './preis-corp/preis-corp.component';
import { VisualsierungComponent } from './visualsierung/visualsierung.component';
import { ForecastingComponent } from './forecasting/forecasting.component';
import { DatenanalyseComponent } from './datenanalyse/datenanalyse.component';
import { ErstgespraechBusinessComponent } from './erstgespraech-business/erstgespraech-business.component';
import { AnovaWiederholungComponent } from './stat-test/anova-wiederholung/anova-wiederholung.component';
import { KruskalComponent } from './stat-test/kruskal/kruskal.component';
import { WilcoxonComponent } from './stat-test/wilcoxon/wilcoxon.component';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'hanseatic-statistics.de', // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  palette: {
    popup: {
      background: '#ffff00',
    },
    button: {
      background: '#000',
    },
  },
  theme: 'edgeless',
  type: 'opt-in',
  content: {
    privacyPolicyLink: 'Datenschutz',
    privacyPolicyHref: 'https://www.hanseatic-statistics.de/datenschutz',

    tosLink: 'Nutzungsbedingungen',
    tosHref: 'https://www.hanseatic-statistics.de/disclaimer',
    dismiss: 'Got it!',
  },
  elements: {
    messagelink: `
    <span id="cookieconsent:desc" class="cc-message">{{message}} 
      <a aria-label="learn more about our privacy policy" tabindex="1" class="cc-link" href="{{privacyPolicyHref}}" target="_blank" rel="noopener">{{privacyPolicyLink}}</a> and our 
      <a aria-label="learn more about our terms of service" tabindex="2" class="cc-link" href="{{tosHref}}" target="_blank" rel="noopener">{{tosLink}}</a>
    </span>
    `,
  },
};

@NgModule({ declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        HomeComponent,
        StatTestComponent,
        ErstgespraechComponent,
        QualiComponent,
        ReferenzenComponent,
        PreiseComponent,
        ImpressumComponent,
        DsgvoComponent,
        LeistungenComponent,
        DisclaimerComponent,
        PageNotFoundComponent,
        LinearRegComponent,
        TTestQComponent,
        LogRegComponent,
        TTestLComponent,
        UTestQComponent,
        LoginComponent,
        StatistikAuswertungComponent,
        SpssAuswertungComponent,
        RAuswertungComponent,
        JamoviAuswertungComponent,
        PythonAuswertungComponent,
        JaspAuswertungComponent,
        MatlabAuswertungComponent,
        PlsAuswertungComponent,
        StataAuswertungComponent,
        HypothesenComponent,
        EffekstaerkenrechnerComponent,
        FragebogenComponent,
        FragebogenSpssComponent,
        FragebogenRComponent,
        FragebogenJamoviComponent,
        FragebogenStataComponent,
        FragebogenJaspComponent,
        SkalenniveauComponent,
        BlogViewComponent,
        EditBlogComponent,
        EditArCountComponent,
        EditReviewsComponent,
        SingleBlogViewComponent,
        EditPriceComponent,
        AnovaOneWayComponent,
        ModMedComponent,
        ModRComponent,
        ModSpssComponent,
        MedSpssComponent,
        MedRComponent,
        BusinessComponent,
        TestDevComponent,
        HomeCorporateComponent,
        EntryComponent,
        PreisCorpComponent,
        VisualsierungComponent,
        ForecastingComponent,
        DatenanalyseComponent,
        ErstgespraechBusinessComponent,
        AnovaWiederholungComponent,
        KruskalComponent,
        WilcoxonComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule.withServerTransition({ appId: 'serverApp' }),
        RoutingModule,
        ReactiveFormsModule,
        FormsModule,
        NgcCookieConsentModule.forRoot(cookieConfig),
        GoogleTagManagerModule.forRoot({
            id: "GTM-KV2HL5VL",
        })], providers: [Title, CookieService, provideHttpClient(withInterceptorsFromDi(), withFetch())] })
export class AppModule {}
