import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-referenzen',
  templateUrl: './referenzen.component.html',
  styleUrls: ['./referenzen.component.scss'],
})
export class ReferenzenComponent implements OnInit {
  constructor(private titleService: Title, private http: HttpClient) {}
  bewertungen = [];
  loadMore = false;
  ngOnInit(): void {
    this.titleService.setTitle('Hanseatic Statistics - Referenzen');
    this.http
      .get<[]>('https://desire-rest-api.de:3000/konrad/bewertungen')
      .subscribe((res) => {
        this.bewertungen = res;
        this.bewertungen.sort((a, b) => {
          const aDateParts = a.bewertung
            .split('.')
            .map((part: string) => part.replaceAll('<b>', ''));
          const bDateParts = b.bewertung
            .split('.')
            .map((part: string) => part.replaceAll('<b>', ''));

          // Create date objects for comparison
          const aDate = new Date(
            aDateParts[2].substring(0, 4),
            aDateParts[1] - 1,
            aDateParts[0]
          ); // month is 0-indexed
          const bDate = new Date(
            bDateParts[2].substring(0, 4),
            bDateParts[1] - 1,
            bDateParts[0]
          ); // month is 0-indexed

          // Return comparison result
          if (aDate < bDate) {
            return 1;
          } else if (aDate > bDate) {
            return -1;
          } else {
            return 0;
          }
        });
      });
  }

  toggleMore() {
    this.loadMore = !this.loadMore;
  }
}
