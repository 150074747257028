import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-jamovi-auswertung',
  templateUrl: './jamovi-auswertung.component.html',
  styleUrls: ['./jamovi-auswertung.component.scss']
})
export class JamoviAuswertungComponent implements OnInit {

  constructor(private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle('Hanseatic Statistics - Statistik Auswertung (JAMOVI)')
  }

}
