import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-erstgespraech-business',
  templateUrl: './erstgespraech-business.component.html',
  styleUrls: ['./erstgespraech-business.component.scss'],
})
export class ErstgespraechBusinessComponent implements OnInit {
  constructor(private http: HttpClient, private titleService: Title) {}

  contactForm: UntypedFormGroup;
  success = false;

  ngOnInit(): void {
    this.titleService.setTitle('Hanseatic Statistics - Kontakt');
    this.contactForm = new UntypedFormGroup({
      name: new UntypedFormControl(null, Validators.required),
      email: new UntypedFormControl(null, [
        Validators.required,
        Validators.email,
      ]),
      nachricht: new UntypedFormControl(null, Validators.required),
    });
  }

  onSubmit() {
    const mail = '<b>E-Mail: </b>' + this.contactForm.value.email + '<br>';
    const name = '<b>Name: </b>' + this.contactForm.value.name + '<br>';
    const nachricht =
      '<b>Nachricht: </b>' + this.contactForm.value.nachricht + '<br>';
    const contactMessage = name + mail + nachricht;
    this.http
      .post(
        'https://desire-rest-api.de:3000/konrad/contactb',
        { content: contactMessage },
        { responseType: 'text' }
      )
      .subscribe(() => {
        this.contactForm.reset();
        this.success = true;
        setTimeout(() => {
          this.success = false;
        }, 5000);
      });
  }
}
