<main>
  <h1>Statistik Auswertung in Matlab</h1>
  <img style="max-width: 100px;" src="../../../assets/img/matlab-logo.png" alt="MATLAB LOGO">
  <p>
    <b>Matlab</b> ist seit vielen Jahrzehnten aus den Disziplinen der Ingenieurs- und Naturwissenschaften nicht mehr
    wegzudenken. Als eigenständige Syntaxsprache und IDE kann sie im Bereich Datenanalyse auch beinahe alles. Der
    große Vorteil von <b>Matlab</b> liegt u.a. in der einfachen Programmierbarkeit von audiovisuellen Experimenten
    und deren Datengewinnung.
  </p>
  <p>
    Ein Nachteil stellt jedoch die Kosten dar. <b>Matlab</b> ist weder open source, noch kostenfrei nutzbar.
  </p>
  <a href="https://de.mathworks.com/products/matlab.html" rel="noopener noreferrer"
    target="_blank">https://de.mathworks.com/products/matlab.html</a><br>
  <img src="../../../assets/img/Matlab Screenshot/Matlab-IDE.jpg" alt="MATLAB IDE">
</main>
<p>Brauchst Du Hilfe bei deiner Auswertung in <b>Matlab</b> kannst Du uns jederzeit kontaktieren.</p>
<div class="button" routerLink="/kostenloses-erstgepraech"><span>Kostenloses Erstgespräch</span>
</div>
<p> Wir melden uns dann am selben Tag bei Dir. </p>
