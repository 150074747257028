import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NgcCookieConsentService } from 'ngx-cookieconsent';

export interface ConsentSettings {
  analytics: boolean;
  marketing: boolean;
  necessary: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CookieConsentService {
  private consentSettingsSubject = new BehaviorSubject<ConsentSettings>(this.getStoredConsent());
  public consentSettings$ = this.consentSettingsSubject.asObservable();

  constructor(private ccService: NgcCookieConsentService) {
    // Auf Statusänderungen des Cookie-Consent-Banners reagieren
    this.ccService.statusChange$.subscribe(
      (event) => {
        if (event.status === 'allow') {
          this.saveConsent({
            necessary: true,
            analytics: true,
            marketing: true
          });
        } else if (event.status === 'deny') {
          this.saveConsent({
            necessary: true,
            analytics: false,
            marketing: false
          });
        }
      }
    );
  }

  public getStoredConsent(): ConsentSettings {
    // Bei ngx-cookieconsent gibt es nur eine binäre Entscheidung (allow/deny)
    const status = localStorage.getItem('cookieconsent_status');
    
    return {
      necessary: true, // Notwendige Cookies sind immer aktiviert
      analytics: status === 'allow',
      marketing: status === 'allow'
    };
  }

  public hasStoredConsent(): boolean {
    return localStorage.getItem('cookieconsent_status') !== null;
  }

  public saveConsent(settings: Partial<ConsentSettings>): void {
    const updatedSettings = { ...this.getStoredConsent(), ...settings };
    this.consentSettingsSubject.next(updatedSettings);
    
    // Hier könnten Sie auch den Status in Ihrem ngx-cookieconsent aktualisieren
    // Da ngx-cookieconsent jedoch seine eigene Speicherlogik hat, ist dies in der Regel nicht nötig
  }

  public hasAnalyticsConsent(): boolean {
    return this.getStoredConsent().analytics;
  }
}