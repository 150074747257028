<div class="component-wrapper">
  <div class="content-wrapper">
    <main>
      <h1>Was wir für Ihr Business tun können</h1>
      <p>
        Data Science und -Analytics sind heute fundamental wichtig für
        Unternehmen, da sie eine Vielzahl von Möglichkeiten bieten, um wichtige
        Informationen und Erkenntnisse aus Daten zu gewinnen. Mit der
        fortschreitenden Digitalisierung und dem Wachstum des Internets der
        Dinge (IoT) generieren Unternehmen mehr Daten als je zuvor. Diese Daten
        können jedoch ohne geeignete Analysemethoden schwer zu verstehen und zu
        nutzen sein.
      </p>

      <p>
        Data Science-Techniken ermöglichen es Unternehmen, ihre Daten zu
        untersuchen, zu organisieren und zu interpretieren. Die Daten können
        genutzt werden, um Erkenntnisse über Kundenverhalten, Geschäftsprozesse
        und Markttrends zu gewinnen. Die Erkenntnisse können dazu beitragen,
        Entscheidungen zu treffen und die Effizienz und Effektivität von
        Geschäftsprozessen zu verbessern.
      </p>
      Dazu gehören:
      <ul>
        <li>
          <span
            >Mitarbeiter- & Teamschulungen in Statistik, Data Analytics der Data
            Science</span
          >
          <img
            src="../../assets/img/business/Team-Mitarbeiter-Schulung-Statistik-Data-Analytics.webp"
            alt="Team-Mitarbeiter-Schulung-Statistik-Data-Analytics"
          />
        </li>
        <li>
          <span>Komplexe Datenvisualisierung</span>
          <img
            src="../../assets/img/business/Komplexe Datenvisualisierung.gif"
            alt="Komplexe Datenvisualisierung"
          />
        </li>
        <li>
          <span>Machine Learning (LSTM, RNN)</span>
          <img
            src="../../assets/img/business/Machine Learning LSTM.png"
            alt="Machine Learning LSTM"
          />
        </li>
        <li>
          <span>Vorhersagemodelle (Holt-Winters, Arima...)</span>
          <img
            src="../../assets/img/business/Vorhersagemodelle.png"
            alt="Vorhersagemodelle"
          />
        </li>
        <li>
          <span> Validierung von Befragungstools</span>
          <img
            src="../../assets/img/business/Validierung Fragebogen.png"
            alt="Validierung Fragebogen"
          />
        </li>
        <li>
          <span>Data Mining</span>
          <img
            src="../../assets/img/business/Data Mining.png"
            alt="Data Mining"
          />
        </li>
        <li>
          <span>Jede Form von Datenanalyse</span>
          <img
            src="../../assets/img/business/Datenanalyse.png"
            alt="Datenanalyse"
          />
        </li>
      </ul>

      <p>
        Darüber hinaus können Data Science und -Analytics Unternehmen dabei
        helfen, Risiken zu minimieren und Wettbewerbsvorteile zu erlangen. Zum
        Beispiel können Unternehmen mithilfe von Data Science-Techniken
        Vorhersagemodelle entwickeln, um potenzielle Probleme im Voraus zu
        erkennen und zu vermeiden. Dies kann dazu beitragen, Risiken zu
        minimieren und die Wettbewerbsfähigkeit zu erhöhen.
      </p>
      <p>
        Insgesamt bieten Data Science und -Analytics Unternehmen eine Vielzahl
        von Möglichkeiten, um wertvolle Erkenntnisse aus Daten zu gewinnen und
        ihre Geschäftsprozesse zu optimieren. Unternehmen, die diese Techniken
        nicht nutzen, riskieren, von ihren Konkurrenten abgehängt zu werden und
        wertvolle Geschäftschancen zu verpassen.
      </p>

      <h2>Unsere Referenzen:</h2>
      <div class="kunde">
        <img
          src="../../assets/img/business/Daten Analyse Hamburg.jpg"
          alt="Daten Analyse Hamburg"
        />
        <p>
          HIW Hamburg Invest Wirtschaftsförderungsgesellschaft mbH, <br />
          mehrere Vorhersagestudien zur Entwicklung des Arbeitsmarktes in der
          Metropolregion Hamburg: <br />
          „[…] Ja, wir konnten die Analysen definitiv sehr gut verwenden. Die
          Informationen darauf brauchen wir vor allem für Unternehmen, die sich
          in Hamburg ansiedeln wollen. […] Wir kommen zeitnah wieder auf Sie
          zu.“
        </p>
      </div>
      <hr>
      <div class="kunde">
        <img
          src="../../assets/img/business/reha-norderney.png"
          alt="Institut für Rehabilitationsforschung Norderney"
        />
        <p>
          Erfolgreiche Erstellung eines STATA Programms zur Fragebogenauswertung im klinischen Bereich.
        </p>
      </div>
      <hr>
      <div class="kunde">
        <img
          src="../../assets/img/business/tcimpact.png"
          alt="TCX Marketing"
        />
        <p>
          TCX Marketing, Erstellung von Kundenclustern und Marketing Analyse.
        </p>
      </div>
    </main>
  </div>
</div>
