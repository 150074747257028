import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-linear-reg',
  templateUrl: './linear-reg.component.html',
  styleUrls: ['./linear-reg.component.scss'],
})
export class LinearRegComponent implements OnInit {
  constructor(
    private titleService: Title,
    private cookieService: CookieService
  ) {}

  allowCookies = false;

  ngOnInit(): void {
    this.titleService.setTitle('Hanseatic Statistics - Lineare Regression');
    this.cookieService.get('cookieconsent_status');
    if (this.cookieService.get('cookieconsent_status') == 'deny')
      this.allowCookies = false;
    if (this.cookieService.get('cookieconsent_status') == 'allow')
      this.allowCookies = true;
  }
}
