import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class GtmService {
  private gtmId: string = '';
  private isLoaded: boolean = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  /**
   * Initialisiert den Google Tag Manager mit der angegebenen ID
   */
  public initialize(gtmId: string): void {
    if (!isPlatformBrowser(this.platformId)) {
      return; // Nicht im Server-Side-Rendering ausführen
    }

    this.gtmId = gtmId;
  }

  /**
   * Lädt den Google Tag Manager in den DOM, wenn die Einwilligung vorhanden ist
   */
  public loadGtm(): void {
    if (!isPlatformBrowser(this.platformId) || !this.gtmId || this.isLoaded) {
      console.log("GTM wird nicht geladen, weil:", {
        isPlatformBrowser: isPlatformBrowser(this.platformId), 
        gtmId: this.gtmId, 
        isLoaded: this.isLoaded
      });
      return;
    }
    console.log("GTM wird geladen mit ID:", this.gtmId);
    // DataLayer initialisieren
    window['dataLayer'] = window['dataLayer'] || [];

    // GTM Script hinzufügen
    const script = document.createElement('script');
    script.id = 'gtm-script';
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtm.js?id=${this.gtmId}`;
    
    script.onload = () => {
      this.isLoaded = true;
      console.log('Google Tag Manager wurde geladen');
    };

    document.head.appendChild(script);

    // NoScript-Fallback hinzufügen
    const noscript = document.createElement('noscript');
    const iframe = document.createElement('iframe');
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${this.gtmId}`;
    iframe.height = '0';
    iframe.width = '0';
    iframe.style.display = 'none';
    iframe.style.visibility = 'hidden';
    
    noscript.appendChild(iframe);
    document.body.insertBefore(noscript, document.body.firstChild);
  }

  /**
   * Entfernt den Google Tag Manager aus dem DOM
   */
  public removeGtm(): void {
    if (!isPlatformBrowser(this.platformId) || !this.isLoaded) {
      return;
    }

    // GTM Script entfernen
    const script = document.getElementById('gtm-script');
    if (script) {
      script.remove();
    }

    // NoScript Element finden und entfernen
    const noscripts = document.getElementsByTagName('noscript');
    for (let i = 0; i < noscripts.length; i++) {
      const noscript = noscripts[i];
      if (noscript.innerHTML.includes(`ns.html?id=${this.gtmId}`)) {
        noscript.remove();
        break;
      }
    }

    this.isLoaded = false;
    console.log('Google Tag Manager wurde entfernt');
  }

  /**
   * Event an den DataLayer senden
   */
  public pushEvent(event: any): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    // DataLayer sollte vorhanden sein, auch wenn GTM noch nicht geladen ist
    window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push(event);
  }
}