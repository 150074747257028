import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  constructor(private http: HttpClient, private cookieService: CookieService) {}

  loginForm: FormGroup;
  password = '';
  loggedIn = false;

  showBewerungsform = false;
  showAbschlussarbeitenform = false;
  showBlogInterface = false;
  showPriceInterface = false;

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      password: new FormControl(null),
    });

    if (this.cookieService.get('Login') === 'Robot42!') {
      this.loggedIn = true;
    }
  }

  handleShowBewerbungen() {
    this.showBewerungsform = true;
    this.showAbschlussarbeitenform = false;
    this.showBlogInterface = false;
    this.showPriceInterface = false;
  }

  handleShowAbschlussarbeiten() {
    this.showBewerungsform = false;
    this.showAbschlussarbeitenform = true;
    this.showBlogInterface = false;
    this.showPriceInterface = false;
  }

  handleShowBlog() {
    this.showBewerungsform = false;
    this.showAbschlussarbeitenform = false;
    this.showBlogInterface = true;
    this.showPriceInterface = false;
  }

  handleShowPrice() {
    this.showPriceInterface = true;
    this.showAbschlussarbeitenform = false;
    this.showBewerungsform = false;
    this.showBlogInterface = false;
  }

  submitLogin() {
    const password = { password: this.loginForm.value.password };
    this.http
      .post('https://desire-rest-api.de:3000/konrad', password)
      .subscribe((res) => {
        if (res) {
          this.password = 'Robot42!';
          this.cookieService.set('Login', 'Robot42!')
          this.loggedIn = true;
        }
      });
  }

  ngOnDestroy() {
    
  }
}
