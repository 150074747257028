import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlogPost } from 'src/app/shared/BlogPost';
import { BlogViewService } from '../blog-view.service';

@Component({
  selector: 'app-single-blog-view',
  templateUrl: './single-blog-view.component.html',
  styleUrls: ['./single-blog-view.component.scss'],
})
export class SingleBlogViewComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private blogService: BlogViewService
  ) {}

  id: string;
  blogPost: BlogPost = {};
  keywords: string = '';

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = params.blogID;
      this.blogService.getBlogPost(this.id).subscribe((fetchedBlogPost) => {
        fetchedBlogPost.keywords = fetchedBlogPost.keywords.replaceAll(
          ',',
          ' - '
        );
        fetchedBlogPost.blogImage =
          'https://desire-rest-api.de:3000/images/blogImages/' +
          fetchedBlogPost.blogImage;
        this.blogPost = fetchedBlogPost;
      });
    });
  }
}
