import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-statistik-auswertung',
  templateUrl: './statistik-auswertung.component.html',
  styleUrls: ['./statistik-auswertung.component.scss']
})
export class StatistikAuswertungComponent implements OnInit {

  constructor(private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle('Hanseatic Statistics - Statistik Auswertung')
  }

}
