<div class="button-wrapper">
  <div class="button-small" (click)="onNewBlog()">Neuer Blogeintrag</div>
  <div class="button-small" (click)="onEditBlog()">Blogeintrag editieren</div>
</div>

<form #blogForm="ngForm" [formGroup]="addBlogForm" *ngIf="newBlogEntry">
  <label for="blogTitle">Blogtitel</label>
  <input
    formControlName="blogTitle"
    type="text"
    name="blogTitle"
    id="blogTitle"
  />
  <label for="upload"
    >Bildupload (bitte einen passenden Namen geben => SEO!)</label
  >
  <input
    type="file"
    name="upload"
    id="upload"
    formControlName="picture"
    class="file-picker"
    (change)="uploadFile($event.target.files)"
  />
  <label for="blogTitle">Bildbeschreibung (max. 1-2 Wörter => SEO!)</label>
  <input
    formControlName="blogImagedesc"
    type="text"
    name="blogImagedesc"
    id="blogImagedesc"
  />
  <span>
    <label for="blogText">Blogtext</label>
    <span
      (mouseleave)="toggleLegend()"
      (mouseover)="toggleLegend()"
      class="legendToggle"
      >🔍</span
    ><span class="legend" [ngClass]="{ legendVisible: hovered }"
      ><span style="font-size: 1.2rem"
        ><b><u>Text formatieren:</u></b></span
      >
      <p style="font-family: monospace">
        **TEXT** ⇢ <b>FETT</b><br />
        ***TEXT*** ⇢ <em>TEXT</em><br />
        __TEXT__ ⇢ <u>TEXT</u><br />
        ## TEXT ⇢
        <span
          style="
            border-left: 2px solid rgb(0, 113, 206);
            font-size: 1rem;
            padding-left: 0.5rem;
          "
          >TEXT</span
        ><br />
        ### TEXT ⇢
        <span
          style="
            border-left: 2px solid rgb(0, 113, 206);
            font-size: 0.8rem;
            padding-left: 0.5rem;
          "
          >TEXT</span
        >
      </p></span
    ></span
  >
  <textarea
    formControlName="blogText"
    name="blogText"
    id="blogText"
    cols="20"
    rows="10"
  ></textarea>
  <label for="blogKeywords">Keywords (per Komma trennen)</label>
  <input
    formControlName="blogKeywords"
    type="text"
    name="blogKeywords"
    id="blogKeywords"
  />
  <div class="released-wrapper">
    <label for="blogReleased">Blog veröffentlicht?</label>
    <input
      formControlName="blogReleased"
      type="checkbox"
      name="blogReleased"
      id="blogReleased"
    />
  </div>
  <button class="button" (click)="postBlog()"><span>Speichern</span></button>
</form>

<form #blogForm="ngForm" [formGroup]="findBlogForm" *ngIf="findBlogEntry">
  <label for="selectBlogEntry">Blogeintrag auswählen</label>
  <select
    name="selectBlogEntry"
    id="selectBlogEntry"
    formControlName="selectBlogEntry"
  >
    <option value=""></option>
    <option *ngFor="let blogEntry of allBlogPosts" [value]="blogEntry._id">
      {{ blogEntry.title }}
    </option>
  </select>
  <button class="button" (click)="onFindBlog()"><span>Bearbeiten</span></button>
</form>

<form #blogForm="ngForm" [formGroup]="editBlogForm" *ngIf="editBlogEntry">
  <label for="blogTitle">Blogtitel</label>
  <input
    formControlName="blogTitle"
    type="text"
    name="blogTitle"
    id="blogTitle"
  />
  <span
    ><label for="blogText">Blogtext </label>
    <span
      (mouseleave)="toggleLegend()"
      (mouseover)="toggleLegend()"
      class="legendToggle"
      >🔍</span
    ><span class="legend" [ngClass]="{ legendVisible: hovered }"
      ><span style="font-size: 1.2rem"
        ><b><u>Text formatieren:</u></b></span
      >
      <p style="font-family: monospace">
        **TEXT** ⇢ <b>FETT</b><br />
        ***TEXT*** ⇢ <em>TEXT</em><br />
        __TEXT__ ⇢ <u>TEXT</u><br />
        ## TEXT ⇢
        <span
          style="
            border-left: 2px solid rgb(0, 113, 206);
            font-size: 1rem;
            padding-left: 0.5rem;
          "
          >TEXT</span
        ><br />
        ### TEXT ⇢
        <span
          style="
            border-left: 2px solid rgb(0, 113, 206);
            font-size: 0.8rem;
            padding-left: 0.5rem;
          "
          >TEXT</span
        >
      </p></span
    ></span
  >
  <textarea
    formControlName="blogText"
    name="blogText"
    id="blogText"
    cols="20"
    rows="10"
  ></textarea>
  <label for="blogKeywords">Keywords (per Komma trennen)</label>
  <input
    formControlName="blogKeywords"
    type="text"
    name="blogKeywords"
    id="blogKeywords"
  />
  <div class="released-wrapper">
    <label for="blogReleased">Blog veröffentlicht?</label>
    <input
      formControlName="blogReleased"
      type="checkbox"
      name="blogReleased"
      id="blogReleased"
    />
  </div>
  <div class="button-wrapper">
    <button class="button" (click)="saveEditedBlogEntry()">
      <span>Speichern</span>
    </button>
    <button class="button button-delete" (click)="deleteBlogEntry()">
      <span>Löschen</span>
    </button>
  </div>
</form>
