<main>
  <h1>Statistik Auswertung in JASP</h1>
  <img style="max-width: 100px;" src="../../../assets/img/jasp-logo.png" alt="JASP LOGO">
  <p>
    Die Software <b>JASP</b> ist ebenfalls ein kostenfreies open source Analyseprogramm. Der eindeutige Vorteil lag
    hier
    lange Zeit in der umfangreichen Integration bayesianischer Statistik und einer sehr benutzerfreundlichen
    Oberfläche. Jedoch haben andere Programme auf diesen Ebenen stark aufgeholt. <b>JASP</b> erinnert sehr stark an
    <b>JAMOVI</b>. Die Analysen werden zudem ebenfalls in <b>R</b> und <b>C++</b> programmiert. Und genau wie
    <b>Jamovi</b> produziert <b>JASP</b> ebenfalls einen APA-formatierten Output.
  </p>
  Download <b>JASP</b>: <a href="https://jasp-stats.org/download/" rel="noopener noreferrer"
    target="_blank">https://jasp-stats.org/download/</a>
  <br>
  <img src="../../../assets/img/JASP Screenshot/jasp-ide.png" alt="JASP IDE">
</main>
<p>Brauchst Du Hilfe bei deiner Auswertung in <b>JASP</b> kannst Du uns jederzeit
  kontaktieren. </p>
<div class="button" routerLink="/kostenloses-erstgepraech"><span>Kostenloses
    Erstgespräch</span>
</div>
<p>Wir melden uns dann am selben Tag bei Dir.</p>
