import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router, NavigationEnd } from '@angular/router';

import { Subscription } from 'rxjs';
import { EntryService } from '../entry/entry.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  constructor(private router: Router, private entryService: EntryService) {}

  sub: Subscription;
  routeSub: Subscription;
  navActive = false;
  // randomBackgroundNumber: number;
  hideAlert = false;
  corporate = false;
  websiteEntered: boolean;

  ngOnInit(): void {
    this.routeSub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (event.navigationTrigger === 'popstate') {
          window.location.reload();
        }
      }

      if (event instanceof NavigationEnd) {
        if (event.url.includes('business') && !this.corporate) {
          this.corporate = true;
        }

        if (this.entryService.entered) {
          this.websiteEntered = true;
        }
      }
    });
  }

  reload() {
    window.location.reload();
  }

  toggleNav() {
    this.navActive = !this.navActive;

    this.sub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.navActive = false;
        window.scroll(0, 0);
        this.sub.unsubscribe();
      }
    });
  }

  hide() {
    this.hideAlert = true;
  }
}
