import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-jasp-auswertung',
  templateUrl: './jasp-auswertung.component.html',
  styleUrls: ['./jasp-auswertung.component.scss']
})
export class JaspAuswertungComponent implements OnInit {

  constructor(private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle('Hanseatic Statistics - Statistik Auswerung (JASP)')
  }

}
