import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { EntryService } from './entry.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-entry',
  templateUrl: './entry.component.html',
  styleUrls: ['./entry.component.scss'],
})
export class EntryComponent implements OnInit {
  constructor(
    private entryService: EntryService,
    private http: HttpClient,
    private cookieService: CookieService
  ) {
    entryService.entered = false;
  }

  enterWebsite = () => {
    this.entryService.entered = true;
  };
  allowCookies = false;
  contactForm: UntypedFormGroup;
  success = false;
  ngOnInit(): void {
    this.cookieService.get('cookieconsent_status');
    if (this.cookieService.get('cookieconsent_status') == 'deny')
      this.allowCookies = false;
    if (this.cookieService.get('cookieconsent_status') == 'allow')
      this.allowCookies = true;
    this.contactForm = new UntypedFormGroup({
      name: new UntypedFormControl(null, Validators.required),
      email: new UntypedFormControl(null, [
        Validators.required,
        Validators.email,
      ]),
      nachricht: new UntypedFormControl(null, Validators.required),
    });
  }

  onSubmit() {
    const mail = '<b>E-Mail: </b>' + this.contactForm.value.email + '<br>';
    const name = '<b>Name: </b>' + this.contactForm.value.name + '<br>';
    const nachricht =
      '<b>Nachricht: </b>' + this.contactForm.value.nachricht + '<br>';
    const contactMessage = name + mail + nachricht;
    this.http
      .post(
        'https://desire-rest-api.de:3000/konrad/contactc',
        { content: contactMessage },
        { responseType: 'text' }
      )
      .subscribe(() => {
        this.contactForm.reset();
        this.success = true;
        setTimeout(() => {
          this.success = false;
        }, 5000);
      });
  }
}
