<div class="component-wrapper preise">
  <main>
    <div class="content-wrapper">
      <h1>Preise, individuell für Deine Bedürfnisse</h1>
      <p>
        Wir bieten dir eine individuelle Beratung, die ganz auf dein Projekt
        zugeschnitten ist. Dabei stehen uns 3 Optionen zur Verfügung:
      </p>
      <h2>1. Statistisches Coaching</h2>
      <p>
        Pro einzelner Unterrichtseinheit (45 Minuten) berechnen wir
        <b>39.99€</b> (zzgl. MwSt.). Wir werten deine Arbeit gemeinsam mit dir aus. Hier kannst du deine Stundenzahl selbst
        bestimmen.
      </p>
      <p>
        Ihr schreibt eure Hausarbeit/Thesis gemeinsam? Dann wird es günstiger!
        Jede weitere Person kostet +10€ (zzgl. Mwst.) im Coaching und ihr könnt
        die Kosten teilen!
      </p>
    </div>
    <div class="zitat">
      <div class="zitat-text">
        Danke Euch! Durch eure Preisgestaltung konnte selbst ich, ein armer
        Student, mir genau die Menge an Hilfe nehmen die ich brauchte und mein
        Budget her gab :D Grüße!!!
        <div class="name">- Tobi</div>
      </div>
    </div>
    <div class="content-wrapper">
      <h2>2. Statisitk auswerten lassen</h2>
      <p>
        Du willst die Analyse komplett abgeben und brauchst am Ende nur deine
        Ergebnisse + Interpretation und Grafiken?
      </p>
      <p>Kontaktiere uns dazu und wir machen dir ein faires Angebot.</p>
      <p>
        Um dir einen Überblick über die Kosten zu verschaffen, kannst du hier
        unseren Kostenrechner benutzen (Bitte beachte, dass es sich dabei nur um
        eine grobe Einschätzung handelt, die auf deinen Angaben basiert)
      </p>
      <div class="dropdown-container">
        <h3>Unser Statistik Kostenrechner</h3>
        <div class="dropdown" *ngFor="let dropdown of dropdowns">
          <div>{{ dropdown.label }}</div>
          <select
            [(ngModel)]="dropdown.selectedValue"
            (change)="calculateTotal()"
          >
            <option
              *ngFor="let option of dropdown.options"
              [value]="option.value"
            >
              {{ option.label }}
            </option>
          </select>
        </div>
        <p id="Gesamtpreis">
          <b style="font-size: 2rem">{{ totalPriceMessage }}</b>
        </p>
      </div>
      <p *ngIf="showMessage">
        Bitte beachte: Dies ist nur eine vorläufige Kostenschätzung. Ohne
        vorliegende Daten, können wir keine genaue Abschätzung geben.
      </p>
      <h2>3. Eine Kombination aus beiden</h2>
      <p>
        Am beliebtesten ist eine Kombination aus beiden. Wir übernehmen deine
        Auswertung und sprechen die Ergebnisse am Ende durch. So kannst du
        Kosten sparen und deinen Ergebnis- und Diskussionsteil besser in deine
        Arbeit einbetten.
      </p>
      <h1>Dein Coaching für deinen Erfolg</h1>
      <p>
        Um eine individuelle Beratung mit größtmöglicher Flexibilität
        gewährleisten zu können, kannst du uns kontaktieren und dir dein Angebot
        bei einem unserer Berater einholen.
      </p>
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Statistik Coaching</th>
            <th>Statistik Auswertung</th>
            <th>Kombination</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Nachvollziehbarkeit</td>
            <td>Sehr hoch, da du im Coaching alle Fragen stellen kannst</td>
            <td>
              Eingeschränkt, du musst am Ende mit den Resultaten zurechtkommen
            </td>
            <td>
              Sehr hoch, da du im anschließenden Coaching alle Fragen stellen
              kannst
            </td>
          </tr>
          <tr>
            <td>Dein Eigenanteil</td>
            <td>Hoch</td>
            <td>Niedrig</td>
            <td>Medium</td>
          </tr>
          <tr>
            <td>Umsetzungsdauer</td>
            <td>Abhängig von deinen Kapazitäten</td>
            <td>
              Sehr schnelle Auswertung, je nach Service kommt aber Zeit für die
              Verschriftlichung hinzu
            </td>
            <td>
              Sehr schnelle Auswertung, je nach Bedarf kommt aber Zeit für
              Fragen hinzu
            </td>
          </tr>
          <tr>
            <td>Preis/Leistung</td>
            <td>Sehr gut, in der Regel 4-6 Stunden</td>
            <td>
              Es können hohe Kosten entstehen bei Verschriftlichung der
              Ergebnisse
            </td>
            <td>
              Sehr gut, in der Regel 3-5 Stunden für die Auswertung + Stunden
              für Fragen
            </td>
          </tr>
          <tr>
            <td>Kontrolle</td>
            <td>
              Du hast die volle Kontrolle über die Auswertung, Zeit und Kosten
            </td>
            <td>
              Wir führen die Auswertung und ggf. Verschriftlichung durch.
              Anschließende Änderungen von z.B. Hypothesen sind in der Regel nicht mehr möglich
            </td>
            <td>
              Im anschließenden Coaching können wir deine Analyse nach Belieben
              anpassen
            </td>
          </tr>
          <tr>
            <td>Akademische Akzeptanz</td>
            <td>Hoch, da es sich um Nachhilfe in einem Projekt handelt</td>
            <td>
              Niedrig, da es sich bei der Verschriftlichung um Ghost Writing
              handelt
            </td>
            <td>Medium</td>
          </tr>
          <tr>
            <td>Integrierbarkeit in deine Thesis</td>
            <td>Sehr gut, da du die Ergebnisse selbst zu Papier bringst</td>
            <td>
              Eine wissenschaftliche Arbeit besteht aus verschiedenen Kapiteln.
              Wenn du Teile des Methoden-, Ergebnis- und Diskussionsteils
              schreiben lässt, wirst du deinen Schreibstil anschließend anpassen
              müssen, damit die Arbeit den gleichen Stil hat
            </td>
            <td>Sehr gut, da du die Ergebnisse selbst zu Papier bringst</td>
          </tr>
        </tbody>
      </table>
      <br /><br>
      <p>Vereinbare jetzt Dein kostenloses Erstgespräch</p>
      <div class="button" routerLink="/kostenloses-erstgepraech">
        <span>Kostenloses Erstgespräch</span>
      </div>
    </div>
  </main>
</div>
