<div class="component-wrapper">
  <div class="content-wrapper">
    <h1>Statistik Auswertung</h1>
    <p>Hier siehst du einen Überblick aller Programme und Programmiersprachen, bei denen wir dich unterstützen können:
    </p>
    <div class="programm-links">
      <a routerLink="spss-auswertung">
        <div class="img-container"><img src="../../assets/img/spss-logo.png" alt="SPSS Logo"></div>
        <div>SPSS</div>
      </a>
      <a routerLink="r-auswertung">
        <div class="img-container"><img src="../../assets/img/r-logo.png" alt="R Logo"></div>
        <div>R</div>
      </a>
      <a routerLink="jamovi-auswertung">
        <div class="img-container"><img src="../../assets/img/jamovi-logo.png" alt="JAMOVI Logo"></div>
        <div>JAMOVI</div>
      </a>
      <a routerLink="stata-auswertung">
        <div class="img-container"><img src="../../assets/img/stata-logo.png" alt="STATA Logo"></div>
        <div>STATA</div>
      </a>
      <a routerLink="python-auswertung">
        <div class="img-container"><img src="../../assets/img/python-logo.png" alt="PYTHON Logo"></div>
        <div>PYTHON</div>
      </a>
      <a routerLink="jasp-auswertung"><img src="../../assets/img/jasp-logo.png" alt="JASP Logo">
        <div>JASP</div>
      </a>
      <a routerLink="matlab-auswertung">
        <div class="img-container"><img src="../../assets/img/matlab-logo.png" alt="MATLAB Logo"></div>
        <div>MATLAB</div>
      </a>
      <a routerLink="smartpls-auswertung">
        <div class="img-container"><img src="../../assets/img/pls-logo.png" alt="PLS Logo"></div>
        <div>SmartPLS</div>
      </a>
    </div>
    <router-outlet></router-outlet>
  </div>
</div>
