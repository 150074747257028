import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-preise',
  templateUrl: './preise.component.html',
  styleUrls: ['./preise.component.scss'],
})
export class PreiseComponent implements OnInit {
  constructor(private titleService: Title, private http: HttpClient) {}

  single: string;
  packet: string;
  packetComplete: string;
  basePrice: number = 170;
  totalPriceMessage: string;
  showMessage: boolean;

  dropdowns = [
    {
      label: 'Software',
      selectedValue: '1',
      options: [
        { label: 'R', value: 1 },
        { label: 'SPSS', value: 1 },
        { label: 'STATA', value: 1.5 },
        { label: 'JASP', value: 1.1 },
        { label: 'JAMOVI', value: 1.1 },
        { label: 'Excel', value: 1.2 }
      ]
    },
    {
      label: 'Komplexität',
      selectedValue: '1',
      options: [
        { label: 'Einfache Analysen', value: 1 },
        { label: 'Fortgeschrittene Analysen', value: 1.5 },
        { label: 'Komplexe Analyse', value: 2 }
      ]
    },
    {
      label: 'Zeitraum',
      selectedValue: '1',
      options: [
        { label: '24h', value: 3 },
        { label: '3 Tage', value: 2 },
        { label: '1 Woche', value: 1.2 },
        { label: '2 Wochen', value: 1 },
        { label: '4 Wochen', value: 1 }
      ]
    },
    {
      label: 'Leistung',
      selectedValue: '1',
      options: [
        { label: 'Reines Script/Output', value: 1 },
        { label: 'Stichpunkte zur Methode/Ergebnisse', value: 1.2 },
        { label: 'Übernahme der Verschriftlichung', value: 2 }
      ]
    },
    {
      label: 'Datenstruktur (Anzahl Spalten)',
      selectedValue: '1',
      options: [
        { label: 'Weniger als 50', value: 1 },
        { label: '50-100', value: 1.1 },
        { label: '101-150', value: 1.2 },
        { label: 'Mehr als 150', value: 1.3 }
      ]
    },
    {
      label: 'Aufbereitung erfolgt',
      selectedValue: '1',
      options: [
        { label: 'Ja', value: 1 },
        { label: 'Nein', value: 1.2 }
      ]
    },
    {
      label: 'Anzahl Hypothesen',
      selectedValue: '1',
      options: [
        { label: '1-4', value: 1 },
        { label: '5-9', value: 1.15 },
        { label: '10 oder mehr', value: 1.3 }
      ]
    }
  ];

  ngOnInit(): void {
    this.titleService.setTitle('Hanseatic Statistics - Preise');
    this.calculateTotal();
    this.http
      .get<{ packet: number; single: number }>(
        'https://desire-rest-api.de:3000/konrad/price'
      )
      .subscribe((fetchedPrices) => {
        (this.packet = fetchedPrices[0].packet),
          (this.single = fetchedPrices[0].single);

        this.packetComplete = (+this.packet * 5).toFixed(2);
      });
  }

  calculateTotal() {
    let totalFactor = this.dropdowns.reduce((factor, dropdown) => factor * parseFloat(dropdown.selectedValue), 1);
    let totalPrice = this.basePrice * totalFactor;

    if (totalPrice > 1500) {
      this.showMessage = false;
      this.totalPriceMessage = 'Bitte kontaktiere uns, damit wir dir ein unverbindliches Angebot machen können!';
    } else {
      this.showMessage = true;
      this.totalPriceMessage = totalPrice.toFixed(2) + '€';
    }
  }
}
