<div class="component-wrapper">
  <div class="content-wrapper">
    <h1>Fragebogen auswerten in 3 einfachen Schritten</h1>
    <p>
      In vielen natur-, wirtschafts- und sozialwissenschaftlichen Bereichen ist
      die Befragung das Mittel der Wahl für das Testen empirischer Hypothesen.
      In diesen einfachen 3 Schritten erklären wir die, wie du vorgehen kannst:
    </p>
    <ul>
      - Daten aufbereiten
      <br />
      - Daten deskriptiv darstellen
      <br />
      - Daten inferenzstatistisch prüfen
    </ul>
    <h2>Schritt 1 Daten aufbereiten (70%)</h2>
    <p>
      Dieser Schritt stellt die umfangreichste Arbeit dar und macht ca. 70% der
      gesamten Analyse aus. Zur Datenaufbereitung zählen:
    </p>
    <p>
      Datenbereinigung (löschen überflüssiger Variablen) Bestimmung der
      Skalenniveaus, labeln der Variablen und Werte Fehlende Werte löschen oder
      ersetzen (z.B. durch eine Mittelwertimputation) Überprüfung der Daten auf
      Plausibilität (stimmen die Daten?), Validität (stellen die Daten dar, was
      ich messen will?) und Reliabilität (Sind die Konstrukte messgenau?). In
      der Regel werden etablierte Messinstrumente verwendet, weshalb eine
      Validierung nicht notwendig ist. Soll ein eignes Messinstrument verwendet
      werden, müssen aufwändige Faktoranalysen (explorative, konfirmatorische)
      durchgeführt werden Erstellung der Konstrukte und Datentransformation
      (Recodierung negativ gepolter Items), dabei kann man sich in der Regel
      nach dem Manual der Originalstudien der Messinstrumente richten. Für viele
      Modelle müssen zusätzlich Dummy Variablen erstellt werden Sortieren des
      Datensatzes. Datenanalyse lebt von Übersichtlichkeit!
    </p>
    <h2>Schritt 2 Daten deskriptiv darstellen (10%)</h2>
    <p>
      Je nach Skalenniveau werden die Daten nun dargestellt. Für nominale Daten
      werden Häufigkeiten und Balkendiagramme abgebildet. Dasselbe erfolgt für
      ordinale Daten, nur kann hier der Median ergänzt werden. Metrische und
      quasi-metrische Daten werden mit allen Parametern von Verteilungen
      beschrieben (Mittelwert, Standardabweichung, Median…) Metrische- und
      quasi-metrische Daten werden mit Boxplots und Histogrammen abgebildet.
      Zudem werden sie auf Normalverteilung überprüft.
    </p>
    <h2>Schritt 3 Daten inferenzstatistisch prüfen (20%)</h2>
    <p>
      Dieser Schritt verursacht wohl die meisten Probleme. In Abhängigkeit der
      Hypothesen, des Skalenniveaus und der Verteilung der Daten muss der
      richtige Test auf Signifikanz gewählt werden. Zusätzlich muss daraus die
      Effektstärke berechnet werden. Ergänzend kann auch bayesianische Statistik
      erfolgen.
    </p>
    <h2>
      Hier beschreiben wir ausführlich, wie man den richtigen Test finden kann
    </h2>
    <span
      routerLink="/statistische-tests/testuebersicht"
      style="cursor: pointer"
      >https://www.hanseatic-statistics.de/statistische-tests/testuebersicht</span
    >

    <h2>Hast du Fragen oder brauchst du Hilfe? Kontaktiere uns</h2>
    <span style="cursor: pointer" routerLink="/kostenloses-erstgepraech"
      >https://www.hanseatic-statistics.de/kostenloses-erstgepraech</span
    >

    <p>
      Im Folgenden siehst du Beispiele für Fragebogenauswertung in SPSS und R,
      jedoch können wir dich auch in JAMOVI, JASP, STATA und Python
      unterstützen:
    </p>

    <h2>Fragebogen Auswertung</h2>
    <p>
      Hier siehst du einen Überblick, bei welchen Fragebogen Auswertungen wir
      dich unterstützen können:
    </p>
    <div class="programm-links">
      <a routerLink="fragebogen-auswertung-spss">
        <div class="img-container">
          <img src="../../assets/img/spss-logo.png" alt="SPSS Logo" />
        </div>
        <div>SPSS</div>
      </a>
      <a routerLink="fragebogen-auswertung-r">
        <div class="img-container">
          <img src="../../assets/img/r-logo.png" alt="R Logo" />
        </div>
        <div>R</div>
      </a>
      <!-- <a routerLink="jamovi-auswertung">
          <div class="img-container"><img src="../../assets/img/jamovi-logo.png" alt="JAMOVI Logo"></div>
          <div>JAMOVI</div>
        </a>
        <a routerLink="stata-auswertung">
          <div class="img-container"><img src="../../assets/img/stata-logo.png" alt="STATA Logo"></div>
          <div>STATA</div>
        </a>
        <a routerLink="python-auswertung">
          <div class="img-container"><img src="../../assets/img/python-logo.png" alt="PYTHON Logo"></div>
          <div>PYTHON</div>
        </a> -->
      <a routerLink="fragebogen-auswertung-jasp"
        ><img src="../../assets/img/jasp-logo.png" alt="JASP Logo" />
        <div>JASP</div>
      </a>
      <!-- <a routerLink="matlab-auswertung">
          <div class="img-container"><img src="../../assets/img/matlab-logo.png" alt="MATLAB Logo"></div>
          <div>MATLAB</div>
        </a>
        <a routerLink="smartpls-auswertung">
          <div class="img-container"><img src="../../assets/img/pls-logo.png" alt="PLS Logo"></div>
          <div>SmartPLS</div>
        </a> -->
    </div>
    <router-outlet></router-outlet>
  </div>
</div>
