<div class="component-wrapper">
  <div class="content-wrapper">
    <h1>Skalenniveaus</h1>
    <p>
      Eins der wichtigsten Kriterien im Datenanalyseprozess ist das Festlegen
      des richtiges Skalenniveaus. Für die Datenaufbereitung,
      Datenvisualisierung, deskriptive Statistik und die Wahl des richtigen
      Hypothesentests ist es daher von entscheidender Bedeutung. Wir
      unterscheiden in der Theorie zwischen 5 Niveaus.
    </p>
    <img src="../../assets/img/skalenniveau/Skalenniveaus.png" alt="Skalenniveaus">
    <p>
      Den Skalenniveaus liegt dabei ein verschiedener Informationsgehalt
      zugrunde.
    </p>
    <img src="../../assets/img/skalenniveau/Skalenniveaus Info.png" alt="Skalenniveaus Info">
    <p>
      einfache Beispiele für nominale-, ordinale-, intervallskalierte-,
      verhältnisskalierte- und absolutskalierte Daten sind:
    </p>
    <img src="../../assets/img/skalenniveau/Skalenniveaus Beispiele.png" alt="Skalenniveaus Beispiele">
    <p>
      <b>Nominale Daten</b> sind einfache Kategorien, wie das Geschlecht
      (m,w,d).
    </p>
    <p>
      <b>Ordinale Daten</b> sind Kategorien mit Rangfolge, wie die Hierarchie im
      Unternehmen oder ungleichgroße Zeiträume (z.B. Wie oft machst du Sport?
      Einmal am Tag – Einmal die Woche – Mehrfach die Woche - Einmal im Monat).
    </p>
    <p>
      <b>Intervall Daten</b> haben dagegen gleichgroße Abstände, z.B. Likert
      Skalen (sehr gut – gut – teils/teils – schlecht – sehr schlecht).
    </p>
    <p>
      <b>Verhältnis Daten</b> haben, wie der Begriff bereits vermuten lässt,
      funktionierende Relationen, wie das Alter. Ein 80-Jähriger ist genau
      doppelt so alt, wie eine 40-Jährige. Hingegen sind -5°C nicht doppelt so
      kalt wie 5°C. Aufgrund des fehlenden natürlichen Nullpunkts bei Grad
      Celsius funktioniert diese Logik hier nicht mehr. Ähnlich sieht es bei
      ordinalen Daten aus. Die Schulnote 4 ist nicht doppelt so schlecht wie die
      Note 2. Aufgrund unterschiedlicher Abstände zwischen den Noten können
      keine Relationen hergestellt werden.
    </p>
    <p>
      <b>Absolute Daten</b> beschreiben natürliche, nicht teilbare Einheiten.
      Zum Beispiel die Kinder einer Familie.
    </p>
    <p>
      Du kannst also je nach Datentyp verschiedene Operationen durchführen oder
      eben nicht. Bei einer Variable, die das Geschlecht misst (1=weiblich,
      2=männlich, 3=divers) ergibt die Berechnung einer Standardabweichung oder
      des Mittelwertes keinen Sinn. Hingegen interessieren einfache Häufigkeiten
      bei einer Altersvariable (in Jahren) nicht mehr. Hier sind Mittelwert und
      Standardabweichung entscheidender.
    </p>
    <img src="../../assets/img/skalenniveau/Skalenniveaus rechnen.png" alt="Skalenniveaus rechnen">
    <p>
      In der Praxis interessieren aber niemanden alle fünf Skalenniveaus.
      Sondern lediglich die drei: Nominal, Ordinal und Metrisch.
    </p>
    <img src="../../assets/img/skalenniveau/Skalenniveaus Metrisch.png" alt="Skalenniveaus metrisch">
    <p>
      <b>Wichtig! Likert Skalen</b> können aufgrund der gleichgroßen Abstände
      als quasimetrisch oder pseudometrisch betrachtet werden.
    </p>
    <p>
      Verschiedene Programme/Programmiersprachen bieten zur Einstellung des
      Skalenniveaus verschiedene, jedoch sehr ähnliche Möglichkeiten an.
    </p>

    <p>In <b>SPSS</b> findest du es unter Variablenansicht.</p>
    <img src="../../assets/img/skalenniveau/Skalenniveaus in SPSS.png" alt="Skalenniveaus in SPSS">
    <p>
      In <b>R</b> bzw. <b>RStudio</b> musst du deine Daten mit Funktionen
      bestimmen.
    </p>
    <iframe
      width="100%"
      height="300"
      src="https://rdrr.io/snippets/embed/?code=%23Nominal%0A%23%23create%20Vector%0A%0AGeschlecht%3C-c(1%2C2%2C3%2C1%2C2%2C3)%0Astr(Geschlecht)%0A%0A%23%23%23Geschlecht%20ist%20jetzt%20numerisch%2C%20das%20verhindert%20zum%20Teil%2C%20das%20bestimmte%20Tests%20durchgef%C3%BChrt%20werden%20k%C3%B6nnen!%0A%0A%23%23factor%0A%0AGeschlecht_nominal%3C-factor(Geschlecht%2C%20levels%3Dc(1%2C2%2C3)%2C%20labels%3Dc('maennlich'%2C'weibich'%2C'divers'))%0A%0Astr(Geschlecht_nominal)%0A%0A%23%23%23Jetzt%20ist%20Geschlecht%20ein%20Faktor%20(nominal)%2C%20den%20du%20auch%20gleich%20noch%20gelabelt%20hast%0A%0A%0A%23Ordinal%0A%0ASport%3C-c(1%2C2%2C3%2C1%2C2%2C3)%0Astr(Sport)%0A%0A%23%23%23Sport%20pro%20Woche%20ist%20jetzt%20numerisch%2C%20das%20verhindert%20zum%20Teil%2C%20das%20bestimmte%20Tests%20durchgef%C3%BChrt%20werden%20k%C3%B6nnen!%0A%0A%23%23Ordered%20factor%0A%0ASport_ordinal%3C-factor(Sport%2C%20levels%3Dc(1%2C2%2C3)%2C%20labels%3Dc('einmal%20pro%20Woche'%2C'mehrfach%20pro%20Woche'%2C't%C3%A4glich')%2C%20ordered%3DTRUE)%0A%0Astr(Sport_ordinal)%0A%0A%23%23%23Jetzt%20ist%20Sport%20ein%20geordneter%20Faktor%20(ordinal)%2C%20den%20du%20auch%20gleich%20noch%20gelabelt%20hast%0A%0A%0A%23Metrisch%20bzw%20numeric%0A%23%23Metrische%20Daten%20erstellst%20du%20ganz%20einfach%20mit%20%0A%0AVector_num%3C-as.numeric(c(1%2C2%2C3%2C4%2C5%2C6%2C7%2C8))%0A%0Astr(Vector_num)"
      frameborder="0"
    ></iframe>
  </div>
</div>
