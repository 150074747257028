import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-edit-price',
  templateUrl: './edit-price.component.html',
  styleUrls: ['./edit-price.component.scss'],
})
export class EditPriceComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private cookieService: CookieService
  ) {}

  priceForm: FormGroup;
  password: string;
  id: string;

  ngOnInit(): void {
    this.priceForm = this.fb.group({
      packet: '',
      single: '',
    });

    this.http
      .get<{ packet: number; single: number }>(
        'https://desire-rest-api.de:3000/konrad/price'
      )
      .subscribe((fetchedPrices) => {
        this.priceForm = this.fb.group({
          packet: fetchedPrices[0].packet,
          single: fetchedPrices[0].single,
        });
      });
  }

  submitPrice() {
    this.password = this.cookieService.get('Login');
    const newPrice = {
      single: this.priceForm.value.single,
      packet: this.priceForm.value.packet,
      password: this.password
    }
    
    this.http
      .put(
        'https://desire-rest-api.de:3000/konrad/price/63cfa9a9d3cbf2bf9b22d7e3',
        newPrice
      )
      .subscribe(() => {
        this.priceForm.reset();
      });
  }
}
