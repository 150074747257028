<div class="component-wrapper">
  <main class="content-wrapper">
    <h1>Effektstärken Rechner</h1>
    <h2 id="rechner-navigation">Test-Navigation</h2>
    <div class="rechner-navlinks">
      <div
        class="rechner-navlink"
        routerLink="/statistische-tests/effektstärkenrechner"
        fragment="T-Test-U"
      >
        T-Test (unabhängig)
      </div>
      <div
        class="rechner-navlink"
        routerLink="/statistische-tests/effektstärkenrechner"
        fragment="Mann-Whitney-U-Test"
      >
        Mann-Whitney-U-Test
      </div>
      <div
        class="rechner-navlink"
        routerLink="/statistische-tests/effektstärkenrechner"
        fragment="T-Test-V"
      >
        T-Test (verbunden)
      </div>
      <div
        class="rechner-navlink"
        routerLink="/statistische-tests/effektstärkenrechner"
        fragment="Wilcoxon-Test"
      >
        Wilcoxon-Test
      </div>
      <div
        class="rechner-navlink"
        routerLink="/statistische-tests/effektstärkenrechner"
        fragment="T-Test-E"
      >
        T-Test (einstichproben)
      </div>
      <div
        class="rechner-navlink"
        routerLink="/statistische-tests/effektstärkenrechner"
        fragment="ANOVA"
      >
        ANOVA
      </div>
      <div
        class="rechner-navlink"
        routerLink="/statistische-tests/effektstärkenrechner"
        fragment="Kruskal-Wallis-Test"
      >
        Kruskal-Wallis-Test
      </div>
      <div
        class="rechner-navlink"
        routerLink="/statistische-tests/effektstärkenrechner"
        fragment="Friedman-Test"
      >
        Friedman-Test
      </div>
      <div
        class="rechner-navlink"
        routerLink="/statistische-tests/effektstärkenrechner"
        fragment="Chi2"
      >
        Chi²
      </div>
      <div
        class="rechner-navlink"
        routerLink="/statistische-tests/effektstärkenrechner"
        fragment="Pearson-Spearman-Kendall"
      >
        Korrelationen (Person, Spearman,Kendall)
      </div>
      <div
        class="rechner-navlink"
        routerLink="/statistische-tests/effektstärkenrechner"
        fragment="Lineare-Regressionen"
      >
        Lineare Regressionen
      </div>
      <div
        class="rechner-navlink"
        routerLink="/statistische-tests/effektstärkenrechner"
        fragment="Logistische-Regressionen"
      >
        Logistische Regressionen
      </div>
    </div>
    <h2 id="T-Test-U">T-Test (unabhängig)</h2>
    <p>
      Für die Berechnung der Effektstärke bei einem T-Test für unabhängige
      Stichproben gibt es mehrere Möglichkeiten, wie zum Beispiel Cohens-d oder
      r (nach Cohen 1992). Besonders r ist dabei sehr nützlich, da es wie der
      Korrelationskoeffizient interpretiert werden kann. Es berechnet sich ganz
      einfach aus:
    </p>
    <div class="img-container">
      <img
        src="../../assets/img/effekstärkenrechner/effektstärke-t-test-f1.png"
        alt="effektstärke-t-test"
      />
    </div>
    <div class="rechner">
      <form [formGroup]="formulaOneForm" (ngSubmit)="calculateFormulaOne()">
        <div class="inner-form center">
          <div class="form-group">
            <span>t</span>
            <input type="number" name="f1t" id="f1t" formControlName="f1t" />
          </div>
          <div class="form-group">
            <span>df</span>
            <input type="number" name="f1f" id="f1f" formControlName="f1f" />
          </div>
        </div>
        <button
          [disabled]="formulaOneForm.invalid"
          class="button button-green"
          type="submit"
        >
          <span>Berechnen</span>
        </button>
      </form>
      <div *ngIf="solutionOne" class="solution">
        <p>Ergebnis</p>
        <p>r = {{ solutionOne }}</p>
      </div>
    </div>
    <p>Die Interpretation ist dieselbe wie bei Korrelationen.</p>
    <ul class="center">
      <li>r &lt; .1 &#10142; kein Effekt</li>
      <li>r &lt; .3 &#10142; schwacher Effekt</li>
      <li>r &lt; .5 &#10142; mittlerer Effekt</li>
      <li>r &gt;= .5 &#10142; starker Effekt</li>
    </ul>
    <p>
      Bei Cohens-d spielt hingegen die Stichprobengröße eine Rolle zur
      Berechnung und eignet sich, wenn sich die Stichprobengrößen besonders
      stark unterscheiden. Bei gleichgroßen Stichproben ist die Formel:
    </p>
    <div class="img-container">
      <img
        src="../../assets/img/effekstärkenrechner/effektstärke-cohens-d-f2.png"
        alt="effektstärke-cohens"
      />
    </div>
    <div class="rechner">
      <form [formGroup]="formulaTwoForm" (ngSubmit)="calculateFormulaTwo()">
        <div class="inner-form">
          <div class="form-group">
            <span>M<sub>1</sub></span>
            <input type="number" name="f2m1" id="f2m1" formControlName="f2m1" />
          </div>
          <div class="form-group">
            <span>M<sub>2</sub></span>
            <input type="number" name="f2m2" id="f2m2" formControlName="f2m2" />
          </div>
          <div class="form-group">
            <span>SD</span>
            <input type="number" name="f2d" id="f2d" formControlName="f2d" />
          </div>
        </div>
        <button
          [disabled]="formulaTwoForm.invalid"
          class="button button-green"
          type="submit"
        >
          <span>Berechnen</span>
        </button>
      </form>
      <div *ngIf="solutionTwo" class="solution">
        <p>Ergebnis</p>
        <p>d = {{ solutionTwo }}</p>
      </div>
    </div>
    <p>
      Bei ungleichgroßen Stichproben ist die Formel um die gepoolte
      Standardabweichung erweitert:
    </p>
    <div class="img-container">
      <img
        src="../../assets/img/effekstärkenrechner/effekstärke-gepolte-standardabweichung-f3.png"
        alt="effekstärke-gepolte-standardabweichung"
      />
    </div>
    <div class="rechner">
      <form [formGroup]="formulaThreeForm" (ngSubmit)="calculateFormulaThree()">
        <div class="inner-form">
          <div class="form-group">
            <span>M<sub>1</sub></span>
            <input type="number" name="f3m1" id="f3m1" formControlName="f3m1" />
          </div>
          <div class="form-group">
            <span>M<sub>2</sub></span>
            <input type="number" name="f3m2" id="f3m2" formControlName="f3m2" />
          </div>
          <div class="form-group">
            <span>S<sub>1</sub></span>
            <input type="number" name="f3s1" id="f3s1" formControlName="f3s1" />
          </div>
          <div class="form-group">
            <span>S<sub>2</sub></span>
            <input type="number" name="f3s2" id="f3s2" formControlName="f3s2" />
          </div>
          <div class="form-group">
            <span>n<sub>1</sub></span>
            <input type="number" name="f3n1" id="f3n1" formControlName="f3n1" />
          </div>
          <div class="form-group">
            <span>n<sub>2</sub></span>
            <input type="number" name="f3n2" id="f3n2" formControlName="f3n2" />
          </div>
        </div>
        <button
          [disabled]="formulaThreeForm.invalid"
          class="button button-green"
          type="submit"
        >
          <span>Berechnen</span>
        </button>
      </form>
      <div *ngIf="solutionThree" class="solution">
        <p>Ergebnis</p>
        <p>d = {{ solutionThree }}</p>
      </div>
    </div>
    <p>Die Interpretation ist:</p>
    <div class="center">
      d &lt; .01 &#10142; kein Effekt <br />
      d &lt; .2 &#10142; sehr schwacher Effekt <br />
      d &lt; .5 &#10142; schwacher Effekt <br />
      d &lt; .8 &#10142; mittlerer Effekt <br />
      d &lt; 1.2 &#10142; starker Effekt <br />
      d &gt;= 1.2 &#10142; sehr starker Effekt <br />
    </div>

    <h2 id="Mann-Whitney-U-Test">Mann-Whitney-U-Test</h2>
    <p>
      Bei nicht parametrischen Testverfahren bietet sich wieder die Effektstärke
      r nach Cohen (1992) an. Die Formel ist recht einfach:
    </p>
    <div class="img-container">
      <img
        src="../../assets/img/effekstärkenrechner/effektstärke-chohens-r-f4.png"
        alt="effektstärke-chohens"
      />
    </div>
    <div class="rechner">
      <form [formGroup]="formulaFourForm" (ngSubmit)="calculateFormulaFour()">
        <div class="inner-form center">
          <div class="form-group">
            <span>Z</span>
            <input type="number" name="f4z" id="f4z" formControlName="f4z" />
          </div>
          <div class="form-group">
            <span>N</span>
            <input type="number" name="f4n" id="f4n" formControlName="f4n" />
          </div>
        </div>
        <button
          [disabled]="formulaFourForm.invalid"
          class="button button-green"
          type="submit"
        >
          <span>Berechnen</span>
        </button>
      </form>
      <div *ngIf="solutionFour" class="solution">
        <p>Ergebnis</p>
        <p>r = {{ solutionFour }}</p>
      </div>
    </div>
    <p>
      Der Betrag von Z wird durch die Wurzel der Stichprobengröße geteilt. Die
      Interpretation ist dieselbe wie bei Korrelationen.
    </p>
    <div class="center">
      r &lt; .1 &#10142; kein Effekt <br />
      r &lt; .3 &#10142; schwacher Effekt <br />
      r &lt; .5 &#10142; mittlerer Effekt <br />
      r &gt;= .5 &#10142; starker Effekt <br />
    </div>

    <h2 id="T-Test-V">T-Test (verbunden)</h2>
    <p>
      Nach Cohen (1988) lässt sich die Effektstärke für verbundene T-Tests
      einfach berechnen durch:
    </p>
    <div class="img-container">
      <img
        src="../../assets/img/effekstärkenrechner/effektstärke-t-test-verbunden-f5.png"
        alt="effektstärke-t-test-verbunden"
      />
    </div>
    <div class="rechner">
      <form [formGroup]="formulaFiveForm" (ngSubmit)="calculateFormulaFive()">
        <div class="inner-form center">
          <div class="form-group">
            <span>t</span>
            <input type="number" name="f5t" id="f5t" formControlName="f5t" />
          </div>
          <div class="form-group">
            <span>N</span>
            <input type="number" name="f5n" id="f5n" formControlName="f5n" />
          </div>
        </div>
        <button
          [disabled]="formulaFiveForm.invalid"
          class="button button-green"
          type="submit"
        >
          <span>Berechnen</span>
        </button>
      </form>
      <div *ngIf="solutionFive" class="solution">
        <p>Ergebnis</p>
        <p>d = {{ solutionFive }}</p>
      </div>
    </div>
    <p>
      Da es ja eine verbundene Stichprobe ist, gibt es nur eine Größe von N.
      <br />
      Die Interpretation ist:
    </p>
    <div class="center">
      d &lt; .01 &#10142; kein Effekt <br />
      d &lt; .2 &#10142; sehr schwacher Effekt <br />
      d &lt; .5 &#10142; schwacher Effekt <br />
      d &lt; .8 &#10142; mittlerer Effekt <br />
      d &lt; 1.2 &#10142; starker Effekt <br />
      d &gt;= 1.2 &#10142; sehr starker Effekt <br />
    </div>
    <p>Auch hier lässt sich noch r nach Cohen berechnen.</p>
    <div class="img-container">
      <img
        src="../../assets/img/effekstärkenrechner/effektstärke-t-test-f1.png"
        alt="effektstärke-t-test"
      />
    </div>
    <div class="rechner">
      <form [formGroup]="formulaOneForm2" (ngSubmit)="calculateFormulaOne2()">
        <div class="inner-form center">
          <div class="form-group">
            <span>t</span>
            <input type="number" name="f1t" id="f1t" formControlName="f1t" />
          </div>
          <div class="form-group">
            <span>df</span>
            <input type="number" name="f1f" id="f1f" formControlName="f1f" />
          </div>
        </div>
        <button
          [disabled]="formulaOneForm2.invalid"
          class="button button-green"
          type="submit"
        >
          <span>Berechnen</span>
        </button>
      </form>
      <div *ngIf="solutionOne2" class="solution">
        <p>Ergebnis</p>
        <p>r = {{ solutionOne2 }}</p>
      </div>
    </div>
    <p>Die Interpretation ist dieselbe wie bei Korrelationen.</p>
    <div class="center">
      r &lt; .1 &#10142; kein Effekt <br />
      r &lt; .3 &#10142; schwacher Effekt <br />
      r &lt; .5 &#10142; mittlerer Effekt <br />
      r &gt;= .5 &#10142; starker Effekt <br />
    </div>

    <h2 id="Wilcoxon-Test">Wilcoxon-Test</h2>
    <p>
      Bei nicht parametrischen Testverfahren bietet sich wieder die Effektstärke
      r nach Cohen (1992) an. Die Formel ist recht einfach:
    </p>
    <div class="img-container">
      <img
        src="../../assets/img/effekstärkenrechner/effektstärke-chohens-r-f4.png"
        alt="effektstärke-chohens-r"
      />
    </div>
    <div class="rechner">
      <form [formGroup]="formulaFourForm2" (ngSubmit)="calculateFormulaFour2()">
        <div class="inner-form center">
          <div class="form-group">
            <span>Z</span>
            <input type="number" name="f4z" id="f4z" formControlName="f4z" />
          </div>
          <div class="form-group">
            <span>N</span>
            <input type="number" name="f4n" id="f4n" formControlName="f4n" />
          </div>
        </div>
        <button
          [disabled]="formulaFourForm2.invalid"
          class="button button-green"
          type="submit"
        >
          <span>Berechnen</span>
        </button>
      </form>
      <div *ngIf="solutionFour2" class="solution">
        <p>Ergebnis</p>
        <p>r = {{ solutionFour2 }}</p>
      </div>
    </div>
    <p>
      Der Betrag von Z wird durch die Wurzel der Stichprobengröße geteilt.
      <br />
      Die Interpretation ist dieselbe wie bei Korrelationen.
    </p>
    <div class="center">
      r &lt; .1 &#10142; kein Effekt <br />
      r &lt; .3 &#10142; schwacher Effekt <br />
      r &lt; .5 &#10142; mittlerer Effekt <br />
      r &gt;= .5 &#10142; starker Effekt <br />
    </div>

    <h2 id="T-Test-E">T-Test (einstichproben)</h2>
    <p>
      Nach Cohen (1988) lässt sich die Effektstärke für einstichproben T-Tests
      einfach berechnen durch:
    </p>
    <div class="img-container">
      <img
        src="../../assets/img/effekstärkenrechner/effektstärke-t-test-verbunden-f5.png"
        alt="effektstärke-t-test-verbunden"
      />
    </div>
    <div class="rechner">
      <form [formGroup]="formulaFiveForm2" (ngSubmit)="calculateFormulaFive2()">
        <div class="inner-form center">
          <div class="form-group">
            <span>t</span>
            <input type="number" name="f5t" id="f5t" formControlName="f5t" />
          </div>
          <div class="form-group">
            <span>N</span>
            <input type="number" name="f5n" id="f5n" formControlName="f5n" />
          </div>
        </div>
        <button
          [disabled]="formulaFiveForm.invalid"
          class="button button-green"
          type="submit"
        >
          <span>Berechnen</span>
        </button>
      </form>
      <div *ngIf="solutionFive2" class="solution">
        <p>Ergebnis</p>
        <p>d = {{ solutionFive2 }}</p>
      </div>
    </div>
    <p>
      Da es eine verbundene Stichprobe ist, gibt es nur eine Größe von N. <br />
      Die Interpretation ist:
    </p>
    <div class="center">
      d&lt;.01 &#10142; kein Effekt <br />
      d&lt;.2 &#10142; sehr schwacher Effekt <br />
      d&lt;.5 &#10142; schwacher Effekt <br />
      d&lt;.8 &#10142; mittlerer Effekt <br />
      d&lt;1.2 &#10142; starker Effekt <br />
      d&gt;=1.2 &#10142; sehr starker Effekt <br />
    </div>
    <p>Auch hier lässt sich noch r nach Cohen berechnen.</p>
    <div class="img-container">
      <img
        src="../../assets/img/effekstärkenrechner/effektstärke-t-test-f1.png"
        alt="effektstärke-t-test"
      />
    </div>
    <div class="rechner">
      <form [formGroup]="formulaOneForm3" (ngSubmit)="calculateFormulaOne3()">
        <div class="inner-form center">
          <div class="form-group">
            <span>t</span>
            <input type="number" name="f1t" id="f1t" formControlName="f1t" />
          </div>
          <div class="form-group">
            <span>df</span>
            <input type="number" name="f1f" id="f1f" formControlName="f1f" />
          </div>
        </div>
        <button
          [disabled]="formulaOneForm3.invalid"
          class="button button-green"
          type="submit"
        >
          <span>Berechnen</span>
        </button>
      </form>
      <div *ngIf="solutionOne3" class="solution">
        <p>Ergebnis</p>
        <p>r = {{ solutionOne3 }}</p>
      </div>
    </div>
    <p>Die Interpretation ist dieselbe wie bei Korrelationen.</p>
    <div class="center">
      r &lt; .1 &#10142; kein Effekt <br />
      r &lt; .3 &#10142; schwacher Effekt <br />
      r &lt; .5 &#10142; mittlerer Effekt <br />
      r &gt;= .5 &#10142; starker Effekt <br />
    </div>

    <h2 id="ANOVA">ANOVA (Gruppenvergleich, Messwiederholung, beides)</h2>
    <p>
      Bei Varianzanalysen (egal ob Quer-, Längs- oder gemischt-) lässt sich f
      nach Cohen (1992) ganz einfach aus dem partiellen ETA² berechnet. Dieses
      stellt, ähnlich wie bei Regressionen, ein Maß für die Varianzaufklärung
      dar.
    </p>
    <div class="img-container">
      <img
        src="../../assets/img/effekstärkenrechner/effektstärke-partielles-eta-f6.png"
        alt="effektstärke-partielles-eta"
      />
    </div>
    <div class="rechner">
      <form [formGroup]="formulaSixForm" (ngSubmit)="calculateFormulaSix()">
        <div class="inner-form">
          <div class="form-group">
            <span>&eta;²</span>
            <input type="number" name="f6n" id="f6n" formControlName="f6n" />
          </div>
        </div>
        <button
          [disabled]="formulaSixForm.invalid"
          class="button button-green"
          type="submit"
        >
          <span>Berechnen</span>
        </button>
      </form>
      <div *ngIf="solutionSix" class="solution">
        <p>Ergebnis</p>
        <p>f = {{ solutionSix }}</p>
      </div>
    </div>
    <p>
      Es wird die aufgeklärte ins Verhältnis zur unaufgeklärten Varianz gesetzt.
      Die Formel ist im Grunde dieselbe wie bei Regressionen, weil
      Varianzanalysen im Grunde dasselbe wie Regressionen sind. Beides sind
      lineare Modelle.
    </p>
    <p>Die Interpretation ist:</p>
    <div class="center">
      f &lt; .10 &#10142; schwacher Effekt <br />
      f &lt; .25 &#10142; mittlerer Effekt <br />
      f &lt; .40 &#10142; starker Effekt <br />
      f &gt;= .40 &#10142; sehr starker Effekt <br />
    </div>

    <h2 id="Kruskal-Wallis-Test">Kruskal-Wallis-Test</h2>
    <p>
      Bei nicht parametrischen Testverfahren bietet sich wieder die Effektstärke
      r nach Cohen (1992) an. Die Formel ist recht einfach:
    </p>
    <div class="img-container">
      <img
        src="../../assets/img/effekstärkenrechner/effektstärke-chohens-r-f4.png"
        alt="effektstärke-chohens-r"
      />
    </div>
    <div class="rechner">
      <form [formGroup]="formulaFourForm3" (ngSubmit)="calculateFormulaFour3()">
        <div class="inner-form center">
          <div class="form-group">
            <span>Z</span>
            <input type="number" name="f4z" id="f4z" formControlName="f4z" />
          </div>
          <div class="form-group">
            <span>N</span>
            <input type="number" name="f4n" id="f4n" formControlName="f4n" />
          </div>
        </div>
        <button
          [disabled]="formulaFourForm3.invalid"
          class="button button-green"
          type="submit"
        >
          <span>Berechnen</span>
        </button>
      </form>
      <div *ngIf="solutionFour3" class="solution">
        <p>Ergebnis</p>
        <p>r = {{ solutionFour3 }}</p>
      </div>
    </div>
    <p>Der Betrag von Z wird durch die Wurzel der Stichprobengröße geteilt.</p>
    <p>Die Interpretation ist dieselbe wie bei Korrelationen.</p>
    <div class="center">
      r &lt; .1 &#10142; kein Effekt <br />
      r &lt; .3 &#10142; schwacher Effekt <br />
      r &lt; .5 &#10142; mittlerer Effekt <br />
      r &gt;= .5 &#10142; starker Effekt <br />
    </div>

    <h2 id="Friedman-Test">Friedman-Test</h2>
    <p>
      Bei nicht parametrischen Testverfahren bietet sich wieder die Effektstärke
      r nach Cohen (1992) an. Die Formel ist recht einfach:
    </p>
    <div class="img-container">
      <img
        src="../../assets/img/effekstärkenrechner/effektstärke-chohens-r-f4.png"
        alt="effektstärke-chohens-r"
      />
    </div>
    <div class="rechner">
      <form [formGroup]="formulaFourForm5" (ngSubmit)="calculateFormulaFour5()">
        <div class="inner-form center">
          <div class="form-group">
            <span>Z</span>
            <input type="number" name="f4z" id="f4z" formControlName="f4z" />
          </div>
          <div class="form-group">
            <span>N</span>
            <input type="number" name="f4n" id="f4n" formControlName="f4n" />
          </div>
        </div>
        <button
          [disabled]="formulaFourForm5.invalid"
          class="button button-green"
          type="submit"
        >
          <span>Berechnen</span>
        </button>
      </form>
      <div *ngIf="solutionFour5" class="solution">
        <p>Ergebnis</p>
        <p>r = {{ solutionFour5 }}</p>
      </div>
    </div>
    <p>Der Betrag von Z wird durch die Wurzel der Stichprobengröße geteilt.</p>
    <p>Die Interpretation ist dieselbe wie bei Korrelationen.</p>
    <div class="center">
      r &lt; .1 &#10142; kein Effekt <br />
      r &lt; .3 &#10142; schwacher Effekt <br />
      r &lt; .5 &#10142; mittlerer Effekt <br />
      r &gt;= .5 &#10142; starker Effekt <br />
    </div>

    <h2 id="Chi2">Chi²</h2>
    <p>
      Bei Chi²-Tests ist die Effektstärkenberechnung in erster Linie von der
      Größe der Kreuztabelle abhängig. Für einfache 2x2 Tabellen nutzt man Phi,
      bei größeren Tabellen Cramers-V (Cohen, 1988).
    </p>
    <div class="img-container">
      <img
        src="../../assets/img/effekstärkenrechner/effektstärke-chi2-phi-f7.png"
        alt="effektstärke-chi2-phi"
      />
    </div>
    <div class="img-container">
      <img
        src="../../assets/img/effekstärkenrechner/effektstärke-chi2-cramers-v-f8.png"
        alt="effektstärke-chi2-cramers-v"
      />
    </div>
    <div class="rechner">
      <form [formGroup]="formulaEightForm" (ngSubmit)="calculateFormulaEight()">
        <div class="inner-form">
          <div class="form-group">
            <span>&chi;²</span>
            <input type="number" name="f8x" id="f8x" formControlName="f8x" />
          </div>
          <div class="form-group">
            <span>n</span>
            <input type="number" name="f8n" id="f8n" formControlName="f8n" />
          </div>
          <div class="form-group">
            <span>m</span>
            <input type="number" name="f8m" id="f8m" formControlName="f8m" />
          </div>
        </div>
        <button
          [disabled]="formulaEightForm.invalid"
          class="button button-green"
          type="submit"
        >
          <span>Berechnen</span>
        </button>
      </form>
      <div *ngIf="solutionEight" class="solution">
        <p>Ergebnis</p>
        <p>V = {{ solutionEight }}</p>
      </div>
    </div>
    <p>
      Die meisten Analyseprogramme geben Phi und Cramers-V jedoch automatisch
      mit aus, wie z.B. SPSS oder Jamovi.
    </p>
    <p>Die Interpretation ist dieselbe wie bei Korrelationen.</p>
    <div class="center">
      &phi; / V &lt; .1 &#10142; kein Effekt <br />
      &phi; / V &lt; .3 &#10142; schwacher Effekt <br />
      &phi; / V &lt; .5 &#10142; mittlerer Effekt <br />
      &phi; / V &lt;= .5 &#10142; starker Effekt <br />
    </div>

    <h2 id="Pearson-Spearman-Kendall">
      Korrelationen (Person, Spearman,Kendall)
    </h2>
    <p>
      Bei Korrelationskoeffizienten handelt es sich bereits um Maße für
      Effektstärken.
    </p>
    <p>Die Interpretation ist dieselbe wie bei Korrelationen.</p>
    <div class="center">
      r &lt; .1 &#10142; kein Effekt <br />
      r &lt; .3 &#10142; schwacher Effekt <br />
      r &lt; .5 &#10142; mittlerer Effekt <br />
      r &gt;= .5 &#10142; starker Effekt <br />
    </div>

    <h2 id="Lineare-Regressionen">Lineare Regressionen</h2>
    <p>
      Hier lässt sich das f² nach Cohen (1992) ganz einfach aus dem angepassten
      R² berechnen. Dieses stellt, ähnlich wie bei Regressionen, ein Maß für die
      Varianzaufklärung dar.
    </p>
    <div class="img-container">
      <img
        src="../../assets/img/effekstärkenrechner/effektstärke-lineare-regression-f9.png"
        alt="effektstärke-lineare-regression"
      />
    </div>
    <div class="rechner">
      <form [formGroup]="formulaNineForm" (ngSubmit)="calculateFormulaNine()">
        <div class="inner-form">
          <div class="form-group">
            <span>R²</span>
            <input type="number" name="f9r" id="f9r" formControlName="f9r" />
          </div>
        </div>
        <button
          [disabled]="formulaNineForm.invalid"
          class="button button-green"
          type="submit"
        >
          <span>Berechnen</span>
        </button>
      </form>
      <div *ngIf="solutionNine" class="solution">
        <p>Ergebnis</p>
        <p>f² = {{ solutionNine }}</p>
      </div>
    </div>
    <p>
      Es wird die aufgeklärte ins Verhältnis zur unaufgeklärten Varianz gesetzt.
      Die Formel ist im Grunde dieselbe wie bei Regressionen, weil
      Varianzanalysen im Grunde dasselbe wie Regressionen sind. Beides sind
      lineare Modelle.
    </p>
    <p>Die Interpretation ist:</p>
    <div class="center">
      f² &lt; .02 &#10142; kein Effekt <br />
      f² &lt; .15 &#10142; schwacher Effekt <br />
      f² &lt; .35 &#10142; mittlerer Effekt <br />
      f² &gt;= .35 &#10142; starker Effekt <br />
    </div>

    <h2 id="Logistische-Regressionen">Logistische Regressionen</h2>
    <p>
      Genau wie bei der linearen Regression wird hier das f² nach Cohen (1992)
      berechnet. Dafür wird das Nagelkerke R² genutzt.
    </p>
    <div class="img-container">
      <img
        src="../../assets/img/effekstärkenrechner/effektstärke-logistische-regression-f10.png"
        alt="effektstärke-logistische-regression"
      />
    </div>
    <div class="rechner">
      <form [formGroup]="formulaTenForm" (ngSubmit)="calculateFormulaTen()">
        <div class="inner-form">
          <div class="form-group">
            <span>R²</span>
            <input type="number" name="f10r" id="f10r" formControlName="f10r" />
          </div>
        </div>
        <button
          [disabled]="formulaTenForm.invalid"
          class="button button-green"
          type="submit"
        >
          <span>Berechnen</span>
        </button>
      </form>
      <div *ngIf="solutionTen" class="solution">
        <p>Ergebnis</p>
        <p>f² = {{ solutionTen }}</p>
      </div>
    </div>
    <p>Die Interpretation ist:</p>
    <div class="center">
      f² &lt; .02 &#10142; kein Effekt <br />
      f² &lt; .15 &#10142; schwacher Effekt <br />
      f² &lt; .35 &#10142; mittlerer Effekt <br />
      f² &gt;= .35 &#10142; starker Effekt <br />
    </div>

    <div class="quellen">
      Quellen: <br />
      Warner, R.M. (2013). Applied Statistics (2nd. Edition). Thousand Oaks, CA:
      SAGE. <br />
      Agresti, A. & Franklin, C. (2014). Statistics. The Art & Science of
      Learning from Data. Essex: Pearson Education Limited. <br />
      Field, A. (2013). Discovering Statistics with IBM SPSS Statistics. Newbury
      Park, CA: Sage. <br />
      Howell, D.C. (2002). Statistical Methods for Psychology (5th ed.). Pacific
      Grove CA: Duxbury. <br />
      Siegel, S. & Castellan, N.J. (1989). Nonparametric Statistics for the
      Behavioral Sciences (2nd ed.). Singapore: McGraw-Hill. <br />
      Cohen, J (1988). Statistical Power Analysis for the Social Sciences (2nd.
      Edition). Hillsdale, New Jersey, Lawrence Erlbaum Associates.
    </div>
  </main>
</div>
