<div class="button-wrapper">
  <div
    class="button-small"
    (click)="newReview()"
    [ngClass]="{ greenBg: neueBewertung }"
  >
    Neue Bewertung
  </div>
  <div
    class="button-small"
    (click)="findReview()"
    [ngClass]="{ greenBg: editBewertung }"
  >
    Bewertung editieren
  </div>
</div>

<div class="new-review" *ngIf="neueBewertung">
  <form [formGroup]="bewertungenForm" (ngSubmit)="submitBewertung()">
    <label for="headline">Überschrift</label>
    <input
      type="text"
      name="headline"
      id="headline"
      formControlName="headline"
    />
    <label for="maintext">Text</label>
    <textarea
      cols="30"
      rows="10"
      name="maintext"
      id="maintext"
      formControlName="maintext"
    ></textarea>
    <button class="button"><span>Speichern</span></button>
    <p *ngIf="bewertungenSubmitted" style="color: green; font-weight: bold">
      Gespeichert
    </p>
  </form>
</div>

<div class="choose-review" *ngIf="selectBewertung">
  <form [formGroup]="selectReviewForm" (ngSubmit)="findReview()">
    <label for="selectReview">Bewertung auswählen</label>
    <select
      name="selectReview"
      id="selectReview"
      formControlName="selectReview"
    >
      <option value=""></option>
      <option *ngFor="let review of alleBewertungen" [value]="review._id">
        {{ review.bewertung }}
      </option>
    </select>
    <button class="button" (click)="editReview()"><span>Edtieren</span></button>
  </form>
</div>

<div class="edit-review" *ngIf="editBewertung">
  <form [formGroup]="editBewertungenForm">
    <label for="headline">Überschrift</label>
    <input
      type="text"
      name="headline"
      id="headline"
      formControlName="headline"
    />
    <label for="maintext">Text</label>
    <textarea
      cols="30"
      rows="10"
      name="maintext"
      id="maintext"
      formControlName="maintext"
    ></textarea>
    <div class="button-wrapper">
      <button class="button" (click)="submitEditedBewertung()"><span>Speichern</span></button>
      <button class="button button-delete" (click)="deleteBewerung()"><span>Löschen</span></button>
    </div>
    <p *ngIf="bewertungenSubmitted" style="color: green; font-weight: bold">
      Gespeichert
    </p>
  </form>
</div>
