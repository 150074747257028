<div class="component-wrapper erstgespraech-wrapper">
  <div class="content-wrapper">
    <div class="coming-soon" *ngIf="!allBlogPosts">
      <div style="font-size: 2rem; margin-top: 3rem" class="soon">
        Wir sind schon fleißig am tippen, um euch demnächst hier alles rund um
        Statistik, Datenanalyse und mehr liefern zu können.
      </div>
    </div>
    <div class="blog-wrapper-mobile">
      <div class="blog-post" *ngFor="let blogPost of releasedBlogPosts">
        <img
          class="blog-image"
          [routerLink]="['/blog/' + blogPost._id]"
          src="{{ blogPost.blogImage }}"
          alt="{{ blogPost.imageDesc }}"
        />
        <div class="keyword-wrapper">
          <span class="blog-keywords">{{ blogPost.keywords }} </span>
        </div>
        <h1 [routerLink]="['/blog/' + blogPost._id]" class="blog-title">
          {{ blogPost.title }}
        </h1>
        <div class="date-author">{{ blogPost.date }} - Konrad Krahl</div>
        <div class="kurztext">
          {{ blogPost.rawtext
          }}<span [routerLink]="['/blog/' + blogPost._id]"> [...]</span>
        </div>
        <hr />
      </div>
    </div>

    <div class="blog-wrapper-desktop" *ngIf="allBlogPosts">
      <div class="blog-post-d" *ngFor="let blogpostd of releasedBlogPosts">
        <div class="inner-wrapper">
          <img
            class="blog-image-d"
            [routerLink]="['/blog/' + blogpostd._id]"
            src="{{ blogpostd.blogImage }}"
            alt=""
          />
          <div class="blog-info-wrapper">
            <h1 class="blog-title-d" [routerLink]="['/blog/' + blogpostd._id]">
              {{ blogpostd.title }}
            </h1>
            <div class="date-author-d">Konrad Krahl - {{ blogpostd.date }}</div>
            <div class="keywords-d"></div>
            <div class="kurztext-d">
              {{ blogpostd.rawtext
              }}<span [routerLink]="['/blog/' + blogpostd._id]"> [...]</span>
            </div>
            <div class="key-wrapper">
              <div class="keywords-d">
                {{ blogpostd.keywords }}
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
    </div>
  </div>
</div>
