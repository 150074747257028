<div class="component-wrapper">
  <div class="content-wrapper">
    <main>
      <h1>Kruskal-Walis Test</h1>
      <p>
        Ein Kruskal-Walis Test gehört zu den einfachen nicht-parametrischen
        Verfahren im Querschnitt, um Unterschiede zu untersuchen. Er kommt immer
        dann zum Einsatz, wenn eine einfaktorielle Varianzanalyse (ANOVA) nicht
        durchgeführt werden kann und/oder eine ordinale Variable auf
        Gruppenunterschiede getestet werden soll.
      </p>

      <h2>Typ und Anwendung</h2>

      <p>
        <b>Unterschiedsanalyse im Querschnitt. </b> Gibt es eine zentrale
        Tendenz bei >2 Gruppen hinsichtlich einer ordinalen abhängigen Variable
      </p>
      <h2>Beispiel</h2>
      <p>
        Unterscheiden sich Experimentalgruppe und zwei Kontrollgruppen
        hinsichtlich des Alters (&lt;18, 19-29, 30-59, &gt;=60)?
      </p>
      <p>
        Welche Automarke (VW, BMW, Mercedes) bekommt die meisten Strafzettel
        (nie, einmal pro Jahr, mehrmals pro Jahr, monatlich)?
      </p>
      <p>
        Gibt es signifikante Unterschiede zwischen Hamburg, Berlin und München
        hinsichtlich der Einkommensverteilung (<2000€, 2001-4000€, 4001-6000€,
        >6000€)?
      </p>
      <h2>Voraussetzungen</h2>
      <p>Abhängige Variablen mindestens ordinal</p>
      <b>
        <p>
          Unabhängige Stichprobe mit mehr als 2 Gruppen (bei 2 Gruppen U-Test).
          Gruppenvariable sollte zudem nominal sein (wenn ordinal, dann Spearman
          Korrelation).
        </p>
      </b>
      <h2>Beispiel in SPSS</h2>
      <p>
        Gegeben sei ein einfacher Datensatz. Wir haben 60 Studierende befragt,
        aus welcher Stadt sie kommen (Hamburg, München oder Berlin) und wie sehr
        sie Statistik auf einer Likert Skala (1-5) präferieren. Wir gehen davon
        aus, dass die Likert Skala in diesem Beispiel ordinal und unsere
        Stichprobe unabhängig ist. Daraus ergibt sich folgende einfache
        Datenstruktur:
      </p>
      <img
        src="../../../assets/img/kruskal/SPSS Datensatz Kruskal Wallis Test.png"
      />
      <p>
        Vorab können wir die Daten deskriptiv Darstellen. Über -> Deskriptive
        Statistik -> Explorative Datenanalyse oder Mittelwerte vergleichen
        können wir uns Mittelwerte, Mediane und Standardabweichung angeben
        lassen (Likert Skalen sind eigentlich Pseudometrisch, bei rein ordinalen
        Daten reichen Mediane).
      </p>
      <img
        src="../../../assets/img/kruskal/Kruskal Wallis Test SPSS deskriptive.png"
      />
      <p>
        Hier können wir bereits deutliche Unterschiede in den Mittelwerten
        sehen.
      </p>
      <p>
        Über Analysieren->Nicht-parametrische Tests->Alte Dialogfelder->K
        unabhängige Stichproben können wir unser Modell bauen. Wichtig ist hier,
        dass die Daten im long format vorliegen. Zudem müssen die Daten
        numerisch sein.
      </p>
      <img
        src="../../../assets/img/kruskal/Bild Kruskal Wallis Test SPSS Auswahl.png"
      />
      <p>Als nächsten definieren wir die Gruppen und die Testvariable.</p>
      <img
        src="../../../assets/img/kruskal/Bild Kruskal Wallis Test SPSS Auswahl Gruppe.png"
      />
      <p>
        Danach erfolgt der Post-Hoc-Test, wir wollen noch wissen welche Gruppen
        sich denn genau unterscheiden. Um eine Alpha-Kumulierung zu verhindern,
        benutzen wir die Bonferroni-Korrektur.
      </p>
      <img
        src="../../../assets/img/kruskal/Bild Kruskal Wallis Test SPSS Post Hoc 1.png"
      />
      <img
        src="../../../assets/img/kruskal/Bild Kruskal Wallis Test SPSS Post Hoc 2.png"
      />
      <p>
        Haben wir alles richtig gemacht, erhalten wir diesen Output, den wir
        weiter unten interpretieren.
      </p>
      <img
        src="../../../assets/img/kruskal/Bild Kruskal Wallis Test SPSS interpretieren.png"
      />
      <p>
        In älteren SPSS-Versionen muss man den Post hoc Test doppelt anklicken
        und auf paarweise Vergleich stellen.
      </p>
      <img
        src="../../../assets/img/kruskal/Kruskal Wallis Test SPSS paarweise Vergleich.png"
      />
      <h2>Beispiel in R</h2>
      <p>
        Gegeben sei ein einfacher Datensatz. Wir haben 60 Studierende befragt,
        aus welcher Stadt sie kommen (Hamburg, München oder Berlin) und wie sehr
        sie Statistik auf einer Likert Skala (1-5) präferieren. Wir gehen davon
        aus, dass die Likert Skala in diesem Beispiel ordinal und unsere
        Stichprobe unabhängig ist. Daraus ergibt sich folgende einfache
        Datenstruktur:
      </p>
      <iframe
        width="100%"
        height="300"
        src="https://rdrr.io/snippets/embed/?code=%23data%0A%0Adata%3C-data.frame(Stadt%3Dc(rep('HH'%2C20)%2Crep('B'%2C20)%2Crep('M'%2C20))%2CStatistik%3Dsample(c(1%3A5)%2C60%2C%20replace%3DTRUE))%0Adata%24Statistik%3C-ifelse(data%24Stadt%3D%3D'HH'%20%26%20data%24Statistik%3C5%2Cdata%24Statistik%2B1%2Cdata%24Statistik)%0A%0A%23%23decriptives%0A%0Alibrary(ggplot2)%0Alibrary(psych)%0A%0Adata_plot%3C-data.frame(Stadt%3Dc('HH'%2C'B'%2C'M')%2C%20Statistik%3Dc(median(data%5Bdata%24Stadt%3D%3D'HH'%2C2%5D)%2Cmedian(data%5Bdata%24Stadt%3D%3D'B'%2C2%5D)%2Cmedian(data%5Bdata%24Stadt%3D%3D'M'%2C2%5D)))%0A%0Aggplot(data%3Ddata_plot%2C%20aes(x%3DStadt%2C%20y%3DStatistik))%20%2B%0A%20%20geom_bar(stat%3D%22identity%22)%0A%0AdescribeBy(data%24Statistik%2Cdata%24Stadt)%0A%0A%0A%23kruskal%20test%20one%20factorial%0A%0Akruskal.test(Statistik%20~%20Stadt%2C%20data%20%3D%20data)%20%0A%0A%23post%20hoc%20test%0A%0Alibrary(tidyr)%0A%0Apairwise.wilcox.test(data%24Statistik%2C%20data%24Stadt%2C%20p.adjust.method%20%3D%20'BH')%0A%0A%0A"
        frameborder="0"
      ></iframe>
      <p>
        Zunächst laden wir die Daten und erstellen eine Deskriptive Statistik.
        Wenn wir bei Likert Skalen von ordinalen Daten ausgehen, können wir auf
        den Test der Normalverteilung verzichten
      </p>
      <img
        src="../../../assets/img/kruskal/Bild Kruskal Wallis Test deskriptive in r.png"
      />

      <img
        src="../../../assets/img/kruskal/Bild Kruskal Wallis Test deskriptive Grafik in r.png"
      />
      <p>
        Anschließend können wir Kruskal-Wallis-Test und den paarweise Vergleich
        mit Bonferroni-Holm Korrektur durchführen.
      </p>
      <img
        src="../../../assets/img/kruskal/Bild Kruskal Wallis Test Ergebnisse interpretieren.png"
      />
      <h2>Interpretation</h2>
      <p>
        Die Interpretation des Kruskal-Wallis-Tests ist relativ einfach. Im
        Modell zeigen sich signifikanten Unterschiede (X²(2)=12.218, p=.001).
        Bei der Überprüfung der paarweisen Vergleiche zeigen sich signifikante
        Unterschiede zwischen Hamburg und München, nicht jedoch zwischen München
        und Berlin und Hamburg und Berlin. Während Personen aus Hamburg
        Statistik am meisten präferieren (M=3.95, Mdn=4.5, SD=1.23), liegt
        München auf dem letzten Platz (M=2.25, Mdn=2.00, SD=1.37 und Berlin
        (3.05, Mdn=2.5, SD=1.64) dazwischen.
      </p>
      <p>
        Fragen, Probleme, Anmerkungen? Dann schreib und einfach und wir helfen
        dir sehr gerne weiter. .
      </p>
      <div class="button" routerLink="/kostenloses-erstgepraech">
        <span>Kostenloses Erstgespräch</span>
      </div>
      <p>Dein Team von Hanseatic-Statistics.</p>
      <p>
        Quellen: Bortz, J. & Döring, N. (2016). Forschungsmethoden und
        Evaluation für Human- und Sozialwissenschaftler. 5. Auflage. Heidelberg:
        Springer. Backhaus, K., Erichson, B., Plinke, W. & Weiber, R. (2016).
        Multivariate Analysemethoden. Eine anwendungsorientierte Einführung. 14.
        Auflage. Berlin: Springer. Hirsig, R. (2006a). Statistische Methoden in
        den Sozialwissenschaften. Eine Einführung im Hinblick auf
        computergestützte Datenanalysen mit SPSS. Band I. 5. Auflage. Zürich:
        Seismo. .
      </p>
    </main>
  </div>
</div>
