import { Component } from '@angular/core';

@Component({
  selector: 'app-preis-corp',
  templateUrl: './preis-corp.component.html',
  styleUrls: ['./preis-corp.component.scss']
})
export class PreisCorpComponent {

}
