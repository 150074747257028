import { Component } from '@angular/core';

@Component({
  selector: 'app-visualsierung',
  templateUrl: './visualsierung.component.html',
  styleUrls: ['./visualsierung.component.scss']
})
export class VisualsierungComponent {

}
