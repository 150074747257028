import { Component } from '@angular/core';

@Component({
  selector: 'app-datenanalyse',
  templateUrl: './datenanalyse.component.html',
  styleUrls: ['./datenanalyse.component.scss']
})
export class DatenanalyseComponent {

}
