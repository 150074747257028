<main>
  <h1>Statistik Auswertung in SmartPLS</h1>
  <img style="max-width: 100px;" src="../../../assets/img/pls-logo.png" alt="PLS LOGO">
  <p>
    <b>SmartPLS</b> ist ein Analyseprogramm, dass, ähnlich wie SPSS AMOS, eine Grafikoberfläche für Konfirmatorische
    Faktoranalysen (CFA) oder Strukturgleichungsmodelle (SEM/SGM) benutzt. <br>
    Damit ist <b>SmartPLS</b> sehr benutzerfreundlich und eignet sich hervorragend für beispielweise die Validierung
    von psychometrischen Erhebungsinstrumenten. <b>SmartPLS</b> benutzt dabei die Methode der Partial Least
    Squares Regression (PLS). Seit einigen Jahren wird es zunehmend beliebter, auch wenn es nicht open
    source und kostenpflichtig ist.
  </p>
  <a href="https://www.smartpls.com/" rel="noopener noreferrer" target="_blank">https://www.smartpls.com/</a><br>
  <img src="../../../assets/img/SmartPLS Screenshots/pls-measurement.png" alt="SmartPLS Measurement">
</main>
<p>Brauchst Du Hilfe bei deiner Auswertung in <b>SmartPLS</b> kannst Du uns jederzeit kontaktieren.</p>
<div class="button" routerLink="/kostenloses-erstgepraech"><span>Kostenloses Erstgespräch</span>
</div>
<p>Wir melden uns dann am selben Tag bei Dir.</p>
