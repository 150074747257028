import { Component, OnInit } from '@angular/core';
import { BlogViewService } from 'src/app/blog-view/blog-view.service';
import { BlogPost } from 'src/app/shared/BlogPost';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-edit-blog',
  templateUrl: './edit-blog.component.html',
  styleUrls: ['./edit-blog.component.scss'],
})
export class EditBlogComponent implements OnInit {
  constructor(private blogService: BlogViewService, private fb: FormBuilder) {}

  allBlogPosts: BlogPost[] = [];
  blogPostToEdit: BlogPost;
  blogShortText: string;
  ausgabe: string;
  hovered = false;
  addBlogForm: FormGroup;
  findBlogForm: FormGroup;
  editBlogForm: FormGroup;
  imageUrl: string;
  fileToUpload;

  newBlogEntry = false;
  editBlogEntry = false;
  findBlogEntry = false;

  ngOnInit(): void {
    this.blogService.getAllBlogPosts().subscribe((fetchedBlogPosts) => {
      this.allBlogPosts = fetchedBlogPosts;
    });

    this.addBlogForm = this.fb.group({
      blogTitle: '',
      blogText: '',
      blogKeywords: '',
      blogReleased: false,
      blogImagedesc: '',
      picture: '',
    });

    this.findBlogForm = this.fb.group({
      selectBlogEntry: '',
    });

    this.editBlogForm = this.fb.group({
      blogTitle: '',
      blogText: '',
      blogKeywords: '',
      blogReleased: false,
      blogImagedesc: '',
    });
  }

  uploadFile(image: File) {
    this.fileToUpload = image[0];
  }

  postBlog() {
    const newBlogPost: FormData = new FormData();
    newBlogPost.append('title', this.addBlogForm.value.blogTitle);
    newBlogPost.append('text', this.addBlogForm.value.blogText);
    newBlogPost.append('keywords', this.addBlogForm.value.blogKeywords);
    newBlogPost.append('released', this.addBlogForm.value.blogReleased);
    newBlogPost.append(
      'rawtext',
      this.getRawText(this.addBlogForm.value.blogText)
    );
    newBlogPost.append('date', this.getDate());
    newBlogPost.append('blogImage', this.fileToUpload);
    newBlogPost.append('imageDesc', this.addBlogForm.value.blogImagedesc);

    this.blogService.postBlog(newBlogPost).subscribe(
      (res) => {
        this.addBlogForm.reset();
        this.newBlogEntry = false;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  onFindBlog(): void {
    this.newBlogEntry = false;
    this.editBlogEntry = true;
    this.findBlogEntry = true;

    const blogEntryID = this.findBlogForm.value.selectBlogEntry;

    this.blogService.getBlogPost(blogEntryID).subscribe((fetchedBlogEntry) => {
      this.blogPostToEdit = fetchedBlogEntry;
      this.editBlogForm = this.fb.group({
        blogTitle: this.blogPostToEdit.title,
        blogText: this.convertHTMLtoRawText(this.blogPostToEdit.text),
        blogKeywords: this.blogPostToEdit.keywords,
        blogReleased: this.blogPostToEdit.released,
        blogImagedesc: this.blogPostToEdit.imageDesc,
      });
    });
  }

  saveEditedBlogEntry(): void {
    let updatedBlogPost = this.blogPostToEdit;

    updatedBlogPost.title = this.editBlogForm.value.blogTitle;
    updatedBlogPost.text = this.editBlogForm.value.blogText;
    updatedBlogPost.keywords = this.editBlogForm.value.blogKeywords;
    updatedBlogPost.released = this.editBlogForm.value.blogReleased;
    updatedBlogPost.rawtext = this.getRawText(this.editBlogForm.value.blogText);
    updatedBlogPost.imageDesc = this.editBlogForm.value.blogImagedesc;

    const blogEntryID = this.findBlogForm.value.selectBlogEntry;
    this.blogService.updateBlog(blogEntryID, updatedBlogPost).subscribe(() => {
      this.editBlogEntry = false;
      this.findBlogEntry = false;
      this.editBlogForm.reset();
    });
  }

  deleteBlogEntry(): void {
    if (confirm('Blogeintrag wirklich löschen?')) {
      this.blogService.deleteBlog(this.blogPostToEdit._id).subscribe(() => {
        this.editBlogForm.reset();
        this.delteBlogFromArray(this.blogPostToEdit._id);
        this.editBlogEntry = false;
        this.findBlogEntry = false;
      });
    }
  }

  delteBlogFromArray(id: string): void {
    const index = this.allBlogPosts.findIndex(
      (blogpost) => blogpost._id === id
    );
    this.allBlogPosts.splice(index, 1);
  }

  onNewBlog() {
    this.newBlogEntry = true;
    this.editBlogEntry = false;
    this.findBlogEntry = false;
  }

  onEditBlog() {
    this.newBlogEntry = false;
    this.editBlogEntry = false;
    this.findBlogEntry = true;
  }

  getDate() {
    const now = new Date();
    let month = now.getMonth() + 1 + '';
    if (now.getMonth() < 10) month = '0' + month;
    let day = now.getDate() + '';
    if (now.getDate() < 10) day = '0' + day;
    let year = now.getFullYear() + '';

    return this.getDay(now.getDay()) + ', ' + day + '.' + month + '.' + year;
  }

  getDay(day: number): string {
    if (day == 0) return 'Sonntag';
    if (day == 1) return 'Montag';
    if (day == 2) return 'Dienstag';
    if (day == 3) return 'Mittwoch';
    if (day == 4) return 'Donnerstag';
    if (day == 5) return 'Freitag';
    if (day == 6) return 'Samstag';
  }

  toggleLegend() {
    this.hovered = !this.hovered;
  }

  getRawText(string: string): string {
    return string
      .replaceAll(/\*\*\*/gm, '')
      .replaceAll(/\*\*/gm, '')
      .replaceAll('###', '')
      .replaceAll('##', '')
      .split('\n')
      .join(' ');
  }

  getBlogTextInHTML(string: string) {
    let stepOne = string
      .split('\n')
      .join('<br>')
      .split('<br>')
      .map((line) => {
        if (line.match('###')) {
          const newLine = line.replaceAll('### ', '');
          return '<h4>' + newLine + '</h4>';
        } else {
          return line;
        }
      })
      .map((line) => {
        if (line.match('##')) {
          const newLine = line.replaceAll('## ', '');
          return '<h2>' + newLine + '</h2>';
        } else {
          return line;
        }
      })
      .join('<br>');

    let stepTwo = stepOne
      .replaceAll(' ***', ' <em>')
      .replaceAll('*** ', '</em> ')
      .replaceAll('***.', '</em>.')
      .replaceAll('***,', '</em>,')
      .replaceAll('***<br>', '</em><br>')
      .replaceAll('<br>***', '<br><em>');
    let stepThree = stepTwo
      .replaceAll(' **', ' <strong>')
      .replaceAll('** ', '</strong> ')
      .replaceAll('**.', '</strong>. ')
      .replaceAll('**,', '</strong>, ')
      .replaceAll('<br>**', '<br><strong>')
      .replaceAll('**<br>', '</strong><br>');
    let stepFour = stepThree
      .replaceAll('<br>```', '<br><pre><code>')
      .replaceAll('```<br>', '</pre></code><br>');
    return stepFour;
  }

  convertHTMLtoRawText(html: string) {
    return html
      .replaceAll(/<br>/gm, '\n')
      .replaceAll(/<strong>/gm, '**')
      .replaceAll(/<\/strong>/gm, '**')
      .replaceAll(/<em>/gm, '***')
      .replaceAll(/<\/em>/gm, '***')
      .replaceAll(/<\/h4>/gm, '\n')
      .replaceAll(/<\/h2>/gm, '\n')
      .replaceAll(/<h2>/gm, '## ')
      .replaceAll(/<h4>/gm, '### ');
  }
}
