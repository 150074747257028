import { Component } from '@angular/core';

@Component({
  selector: 'app-anova-one-way',
  templateUrl: './anova-one-way.component.html',
  styleUrls: ['./anova-one-way.component.scss']
})
export class AnovaOneWayComponent {

}
