<div class="component-wrapper erstgespraech-wrapper">
  <div class="content-wrapper">
    <main>
      <h1>Kontaktanfrage</h1>
      <p>
        Wir laden Sie herzlich ein, bei Fragen oder Anliegen nicht zu zögern,
        Kontakt mit uns aufzunehmen. Unser engagiertes Team steht Ihnen
        jederzeit mit Rat und Tat zur Seite, um Ihnen schnellstmöglich
        weiterzuhelfen. Ihre Zufriedenheit ist unser oberstes Anliegen, und wir
        sind stets bemüht, Ihre Fragen zeitnah und kompetent zu beantworten.
        Zögern Sie also nicht, uns zu kontaktieren. Wir freuen uns darauf, Ihnen
        behilflich zu sein und Ihr Anliegen professionell zu bearbeiten.
      </p>
      <br />
      <p class="contact">
        <img src="../../assets/img/icons/call-24px.svg" alt="" />
        <a style="color: black" href="tel:+494022634973">
          <b>+49 40 22634973</b>
        </a>
      </p>
      <p class="contact">
        <img src="../../assets/img/icons/mail-24px.svg" alt="" />
        <a style="color: black" href="mailto:beratung@hanseatic-statistics.de">
          <b>beratung&#64;hanseatic-statistics.de</b>
        </a>
      </p>
      <p>Sie können auch über unser Webformular mit uns Kontakt aufnehmen</p>
      <div class="contact-form">
        <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label for="name">Name / Firma<span>*</span></label>
            <input
              [ngClass]="{
                'invalid-input':
                  contactForm.controls.name.touched &&
                  !contactForm.controls.name.valid
              }"
              type="text"
              name="name"
              id="name"
              formControlName="name"
            />
            <span
              class="warning"
              *ngIf="
                contactForm.controls.name.touched &&
                !contactForm.controls.name.valid
              "
              >Feld bitte ausfüllen</span
            >
          </div>
          <div class="form-group">
            <label for="email">E-Mail <span>*</span></label>
            <input
              [ngClass]="{
                'invalid-input':
                  contactForm.controls.email.touched &&
                  !contactForm.controls.email.valid
              }"
              type="email"
              name="email"
              id="email"
              formControlName="email"
            />
            <span
              class="warning"
              *ngIf="
                contactForm.controls.email.touched &&
                !contactForm.controls.email.valid
              "
              >Feld bitte ausfüllen</span
            >
          </div>
          <div class="form-group">
            <label for="nachricht">Nachricht <span>*</span></label>
            <textarea
              [ngClass]="{
                'invalid-input':
                  contactForm.controls.nachricht.touched &&
                  !contactForm.controls.nachricht.valid
              }"
              name="nachricht"
              id="nachricht"
              cols="30"
              rows="10"
              formControlName="nachricht"
            ></textarea>
            <span
              class="warning"
              *ngIf="
                contactForm.controls.nachricht.touched &&
                !contactForm.controls.nachricht.valid
              "
              >Feld bitte ausfüllen</span
            >
          </div>
          <span style="font-size: 0.9rem"
            ><span style="color: red">*</span> Pflichtfeld</span
          ><br /><br />
          <div class="success" *ngIf="success">
            Vielen Dank für Ihre Nachricht! Wir werden Ihre Anfrage umgehend
            bearbeiten.
          </div>
          <button [disabled]="!contactForm.valid" type="submit" class="button">
            <span>Nachricht senden</span>
          </button>
        </form>
      </div>
    </main>
  </div>
</div>
