import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-med-spss',
  templateUrl: './med-spss.component.html',
  styleUrls: ['./med-spss.component.scss'],
})
export class MedSpssComponent implements OnInit {
  ngOnInit(): void {
    window.scrollTo(0, 0);
  }
}
