<div class="component-wrapper">
  <div class="content-wrapper">
    <main>
      <h1>Wilcoxon-Test</h1>
      <p>
        Der Wilcoxon-Test für abhängige Stichproben ist ein sehr einfacher
        Vergleich zweier Messzeitpunkte. Die Merkmale (abhängige Variable)
        müssen dabei mindestens ein ordinales Skalenniveau aufweisen. Die
        unabhängige Variable stellt hier der Zeitfaktor bzw. die beiden
        Zeitpunkte dar. Jedoch muss es sich nicht immer um reine
        Längsschnittanalysen handeln. Man könnte beispielsweise auch in einer
        Studie zu einem Zeitpunkt zwei ordinale Variablen hinsichtlich ihrer
        Ausprägungen vergleichen.
      </p>

      <h2>Typ und Anwendung</h2>

      <p>
        <b>Unterschiedsanalyse im Längsschnitt.</b> Gibt es einen Unterschied
        zwischen zwei Zeitpunkten? Wichtig ist zudem, dass es sich hier um eine
        Längsschnittanalyse handelt. Während der U-Test für unabhängige
        Stichproben zwei Gruppen hinsichtlich eines Merkmals (Männlich-Weiblich
        hinsichtlich Klausurnoten) vergleicht, prüft der Wilcoxon-Test für
        abhängige Stichproben einen Unterscheid zwischen zwei Zeitpunkten oder
        zwei Variablen. Der eigentliche Unterschied liegt in der Versuchsperson/
        Beobachtungseinheit. Hat jede Versuchsperson nur einen Wert und
        vergleichen wir am Ende zwei Gruppen, ist es eine unabhängige Stichprobe
        (Jede Versuchsperson kann nur Gruppe A oder Gruppe B sein). Hat jede
        Versuchsperson/ Beobachtungseinheit zwei Werte, liegt eine verbundene
        Stichprobe vor (Zeitpunkt/Variable A und B). Zudem kann der
        Wilcoxon-Test immer dann benutzt werden, wenn die Voraussetzungen des
        T-Tests für abhängige Stichproben, verletzt sind.
      </p>

      <h2>Beispiel</h2>

      <p>
        Unterscheiden sich die Klausurnoten (ordinal) zweier Klausuren eines
        Jahrgangs?
      </p>
      <p>
        Unterscheiden sich die Einkommenskategorien (&lt;2000€, 2001-4000€,
        &gt;4000€) zwischen zwei Jahren?
      </p>
      <p>
        Welche Social-Media-Plattform präferieren Versuchspersonen (Skala 1-4,
        ordinal)? TikTok oder Instagram?
      </p>

      <h2>Voraussetzungen</h2>

      <p>Abhängige Variablen mindestens ordinal</p>
      <p>2 Zeitpunkte bzw. 2 abhängige Variablen, wenn >2 dann Friedman-Test</p>

      <h2>Beispiel in SPSS</h2>

      <p>
        Gegeben sei ein einfacher Datensatz. Wir haben von 120 Studierenden die
        Statistik und Biopsychologie Noten erhoben. Da Noten kategorisierte
        Prozentpunkte darstellen, kann hier von einem ordinalen Merkmal
        gesprochen werden. Jeder Studierende hat daher ein Wertepaar und somit
        abhängige Werte:
      </p>

      <img src="../../../assets/img/wilcox/Bild SPSS Datensatz Wilcoxon-Test.png" alt="Bild SPSS Datensatz Wilcoxon-Test">

      <p>
        Vorab können wir die Daten deskriptiv Darstellen. Über -> Deskriptive
        Statistik -> Explorative Datenanalyse oder Mittelwerte vergleichen
        können wir uns Mediane und Häufigkeiten angeben lassen.
      </p>

      <img src="../../../assets/img/wilcox/Bild Kruskal Wilcoxon-Test SPSS deskriptive Grafik.png" alt="Bild Kruskal Wilcoxon-Test SPSS deskriptive Grafik">

      <p>
        Hier können wir bereits deutliche Unterschiede in den Medianen sehen.
      </p>

      <p>
        Über Analysieren->Nicht-parametrische Tests-> 2 verbundene Stichproben
        können wir unser Modell bauen. Wichtig ist hier, dass die Daten im wide
        format vorliegen. Zudem müssen die Daten numerisch sein.
      </p>

      <img src="../../../assets/img/wilcox/Bild Wilcoxon-Test SPSS Auswahl.png" alt="Wilcoxon-Test SPSS Auswahl">


      <p>Als nächsten definieren wir die Testvariablen.</p>


      <img src="../../../assets/img/wilcox/Bild Wilcoxon-Test SPSS Auswahl Innersubjekt.png" alt="Wilcoxon-Test SPSS Auswahl Innersubjekt">
      <p>
        Haben wir alles richtig gemacht, erhalten wir diesen Output, den wir
        weiter unten interpretieren.
      </p>

      <img src="../../../assets/img/wilcox/Bild Wilcoxon-Test SPSS interpretieren.png" alt="Wilcoxon-Test SPSS interpretieren">
      <h2>Beispiel in R</h2>
      <p>
        Gegeben sei ein einfacher Datensatz. Wir haben von 120 Studierenden die
        Statistik und Biopsychologie Noten erhoben. Da Noten kategorisierte
        Prozentpunkte darstellen, kann hier von einem ordinalen Merkmal
        gesprochen werden. Jeder Studierende hat daher ein Wertepaar und somit
        abhängige Werte:
      </p>


      <img src="../../../assets/img/wilcox/Bild r Datensatz Kruskal Wallis Test.png" alt="r Datensatz Kruskal Wallis Test">
      <iframe
        width="100%"
        height="300"
        src="https://rdrr.io/snippets/embed/?code=%0A%23data%0A%0ABiopsychologie%3C-sample(c(1.0%2C1.3%2C1.7%2C2.0%2C2.3%2C2.7%2C3.0%2C3.3%2C3.7%2C4.0%2C5.0)%2C%20120%2C%20replace%3DTRUE)%0A%0AStatistik%3C-c()%0Arange%3C-c(1.0%2C1.3%2C1.7%2C2.0%2C2.3%2C2.7%2C3.0%2C3.3%2C3.7%2C4.0%2C5.0)%0A%0Afor%20(i%20in%20c(1%3Alength(Biopsychologie)))%0A%7B%0A%20Statistik%5Bi%5D%3C-ifelse(Biopsychologie%5Bi%5D%3C5.0%2Crange%5Bwhich(range%3D%3DBiopsychologie%5Bi%5D)%2B1%5D%2CBiopsychologie%5Bi%5D)%20%20%20%0A%7D%0A%0Adata%3C-data.frame(Biopsychologie%2CStatistik)%0A%20%0A%20%20%0A%0A%23descriptives%0A%0Alibrary(psych)%0A%0Adescribe(data%24Statistik)%0Adescribe(data%24Biopsychologie)%0A%0A%23barplot%0A%0Alibrary(ggplot2)%0Alibrary(stats)%0A%0Adata_plot%3C-data.frame(Kurs%3Dc('Statistik'%2C'Biopsychologie')%2CNote%3Dc(median(data%24Statistik)%2Cmedian(data%24Biopsychologie)))%0A%0Aggplot(data%3Ddata_plot%2C%20aes(x%3DKurs%2C%20y%3DNote))%20%2B%0A%20%20geom_bar(stat%3D%22identity%22)%0A%0A%0A%23Wilcoxon%20Test%0A%0Awilcox.test(data%24Biopsychologie%2Cdata%24Statistik)"
        frameborder="0"
      ></iframe>

      <p>
        Zunächst laden wir die Daten und erstellen eine Deskriptive Statistik.
        Wenn wir bei Noten von ordinalen Daten ausgehen, können wir auf den Test
        der Normalverteilung verzichten
      </p>

      <img src="../../../assets/img/wilcox/Bild Kruskal Wilcoxon-Test SPSS deskriptive Grafik.png" alt="Kruskal Wilcoxon-Test SPSS deskriptive Grafik">
      <p>Anschließend können wir den Wilcoxon-Test durchführen.</p>

      <img src="../../../assets/img/wilcox/Bild Wilcoxon-Test Ergebnisse interpretieren.png" alt="Bild Wilcoxon-Test Ergebnisse interpretieren">
      <h2>Interpretation</h2>
      <p>
        Die Interpretation Wilcoxon-Test ist relativ einfach. Im Wilcoxon-Test
        zeigen sich signifikanten Unterschiede (W=5901, p=.015). Die
        Biopsychologie Klausur (Mdn=2.7) ist signifikant besser ausgefallen als
        die Statistik Klausur (Mdn=3.00). Zu beachten ist an dieser Stelle, dass
        verschiedene Datenanalyse Tools wie SPSS, R, STATA, SAS, Jamovi, Jasp,
        Excel…etc. unterschiedliche Parameter ausgeben. Während in R das W
        berichtet wird, ist es in SPSS das U.
      </p>

      <p>
        Fragen, Probleme, Anmerkungen? Dann schreib und einfach und wir helfen
        dir sehr gerne weiter. .
      </p>

      <div class="button" routerLink="/kostenloses-erstgepraech">
        <span>Kostenloses Erstgespräch</span>
      </div>

      <p>Dein Team von Hanseatic-Statistics.</p>

      <p>
        Quellen: Bortz, J. & Döring, N. (2016). Forschungsmethoden und
        Evaluation für Human- und Sozialwissenschaftler. 5. Auflage. Heidelberg:
        Springer. Backhaus, K., Erichson, B., Plinke, W. & Weiber, R. (2016).
        Multivariate Analysemethoden. Eine anwendungsorientierte Einführung. 14.
        Auflage. Berlin: Springer. Hirsig, R. (2006a). Statistische Methoden in
        den Sozialwissenschaften. Eine Einführung im Hinblick auf
        computergestützte Datenanalysen mit SPSS. Band I. 5. Auflage. Zürich:
        Seismo. .
      </p>
    </main>
  </div>
</div>
