import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-r-auswertung',
  templateUrl: './r-auswertung.component.html',
  styleUrls: ['./r-auswertung.component.scss'],
})
export class RAuswertungComponent implements OnInit {
  constructor(
    private titleService: Title,
    private cookieService: CookieService
  ) {}

  allowCookies = false;

  ngOnInit(): void {
    this.titleService.setTitle(
      'Hanseatic Statistics - Statistik Auswertung (R)'
    );

    this.cookieService.get('cookieconsent_status');
    if (this.cookieService.get('cookieconsent_status') == 'deny')
      this.allowCookies = false;
    if (this.cookieService.get('cookieconsent_status') == 'allow')
      this.allowCookies = true;
  }
}
