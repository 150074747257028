import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-edit-ar-count',
  templateUrl: './edit-ar-count.component.html',
  styleUrls: ['./edit-ar-count.component.scss'],
})
export class EditArCountComponent implements OnInit {
  constructor(private http: HttpClient, private fb: FormBuilder, private cookieService: CookieService) {}

  abschlussarbeitenForm: FormGroup;

  password: string;
  abschlussarbeitenSubmitted = false;

  async ngOnInit() {
    this.abschlussarbeitenForm = this.fb.group({
      anzahl: '',
    });

    this.http.get('https://desire-rest-api.de:3000/konrad').subscribe((res) => {
      this.abschlussarbeitenForm = this.fb.group({
        anzahl: res[0].anzahl,
      });
    });
  }

  submitArbeiten() {
    const arbeiten = {
      anzahl: this.abschlussarbeitenForm.value.anzahl,
      password: this.cookieService.get('Login'),
    };

    this.http
      .put(
        'https://desire-rest-api.de:3000/konrad/602d3cf36d27181f54eeaf60',
        arbeiten
      )
      .subscribe();
    this.abschlussarbeitenForm.reset();
    this.abschlussarbeitenSubmitted = true;
    setTimeout(() => {
      this.abschlussarbeitenSubmitted = false;
    }, 5000);
  }
}
