import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-test-dev',
  templateUrl: './test-dev.component.html',
  styleUrls: ['./test-dev.component.scss'],
})
export class TestDevComponent implements OnInit {
  items = [
    { id: 'item1', text: '1' },
    { id: 'item2', text: '2' },
    { id: 'item3', text: '3' },
    { id: 'item4', text: '4' },
    { id: 'item5', text: '5' },
    { id: 'item6', text: '6' },
    { id: 'item7', text: '7' },
    { id: 'item8', text: '8' },
    { id: 'item9', text: '9' },
    { id: 'item10', text: '10' },
    { id: 'item11', text: '11' },
    { id: 'item12', text: '12' },
    { id: 'item13', text: '13' },
    { id: 'item14', text: '14' },
    { id: 'item15', text: '15' },
    { id: 'item16', text: '16' },
    { id: 'item17', text: '17' },
    { id: 'item18', text: '18' },
    { id: 'item19', text: '19' },
    { id: 'item20', text: '20' },
    { id: 'item21', text: '21' },
    { id: 'item22', text: '22' },
    { id: 'item23', text: '23' },
    { id: 'item24', text: '24' },
    { id: 'item25', text: '25' },
    { id: 'item26', text: '26' },
    { id: 'item27', text: '27' },
  ];

  constructor() {}

  ngOnInit(): void {}

  // a function to toggle the highlight class and remove it from the other elements

  highlight(id: string) {
    const element = document.getElementById(id);
    element.classList.toggle('highlight');
    const otherElements = document.querySelectorAll('.item:not(#' + id + ')');
    otherElements.forEach((el) => {
      el.classList.remove('highlight');
    });
  }
}
