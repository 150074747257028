<div class="component-wrapper quali">
  <main>
    <div class="content-wrapper">
      <h1>Höchste Qualifikation für Deinen Erfolg</h1>
      <p>
        Die wissenschaftliche Methode ist der Grundpfeiler unserer Arbeit. Alle
        unsere Mitarbeiter haben erfolgreich ein naturwissenschaftliches Studium
        absolviert und besitzen eine hohe Expertise in der wissenschaftlichen
        Methodik, Informatik und Statistik.
      </p>
    </div>
    <div class="zitat">
      <div class="zitat-text">
        "Huhu, ihr habt es echt drauf! ist eine 1,1 geworden!!! Danke euch !!!"
        <div class="name">- Julia</div>
      </div>
    </div>

    <div class="content-wrapper">
      <p>
        Unser Team besteht aus 18 Dozenten und Dozentinnen aus den Bereichen
        Psychologie, Statistik, Data Science, Ökonometrie, BWL &
        Politikwissenschaften.
      </p>

      <p>
        Somit können wir Dir nicht nur einen persönlichen Dozenten zuteilen,
        sondern auch die fachliche Abstimmung mit Deinem Thema berücksichtigen.
      </p>

      <h2>Ohne Risiko</h2>

      <p>
        Profitiere von unserem kollektiven Know-How und plane mit uns Dein
        individuelles Programm.
      </p>

      <h2>Verantwortlich</h2>

      <div class="wrapper">
        <div class="left">
          <img
            src="../../assets/img/Konrad Krahl.jpg"
            alt="Profilbild Konrad Krahl"
          />
        </div>
        <div class="right">
          <p>
            Hi, mein Name ist Konrad Krahl und ich habe meinen Abschluss in
            Psychologie (M.Sc.) mit den Schwerpunkten Neurowissenschaften und
            Datenanalyse an der Universität zu Lübeck (Sektion MINT) absolviert.
            Seit 2019 werte ich gemeinsam mit meinem Team empirische Daten im
            wissenschaftlichen Bereich aus und unterstütze Studierende,
            Institute und Unternehmen bei Publikationen und der Analyse von
            Daten. Zudem arbeite ich als Honorardozent an verschiedenen
            Hochschulen in den Modulen Statistik, computergestützter
            Datenanalyse und wissenschaftlicher Methodik.
          </p>

          <p>
            Hier findest du zudem exemplarische Publikationen, an denen ich
            mitgewirkt habe, wie Paper oder mein eigenes Package in der
            Programmiersprache R
          </p>
          <a
            href="http://www.dustri.com/nc/de/article-response-page.html?artId=189897&doi=10.5414%2FPRX0583&abstractLang=en"
            >Link Paper</a
          ><br />
          <a
            href="https://www.sciencedirect.com/science/article/abs/pii/S0949328X24001273?dgcid"
            >Link Paper</a
          ><br />
          <a href="https://cran.r-project.org/web/packages/HanStat/index.html"
            >Link Package</a
          >
          <div class="button" routerLink="/kostenloses-erstgepraech">
            <span>Kostenloses Erstgespräch</span>
          </div>
        </div>
      </div>
      <hr />
      <h2>Mein Team</h2>
      <div class="wrapper" *ngFor="let ma of mas">
        <div class="left">
          <img src="{{ ma.img }}" alt="Profilbild {{ ma.name }}" />
        </div>
        <div class="right">
          <h3>{{ ma.name }}</h3>
          <p>{{ ma.text }}</p>
        </div>
        <hr />
      </div>
    </div>
  </main>
</div>
