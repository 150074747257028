<div class="component-wrapper">
  <div class="content-wrapper">
    <main>
      <h1>Moderation in R</h1>
      <p>
        Die einfachste Möglichkeit eine Moderation in R durchzuführen ist es,
        das externe Tool PROCESS von Andrew Hays (2015) zu installieren. Diese
        etablierte und wissenschaftlich fundierte Funktion kann hier
        heruntergeladen werden
      </p>
      <a href="https://haskayne.ucalgary.ca/CCRAM/resource-hub">Download</a>

      <img
        src="../../../assets/img/modmed/PROCESS Download.png"
        alt="PROCESS Download"
      />

      <p>Danach öffnen wir das .R Script und führen es einmal komplett aus.</p>

      <img
        src="../../../assets/img/modmed/Bild PROCESS Moderation in R.png"
        alt="PROCESS Moderation in R"
      />

      <p>
        Die Funktion ist jetzt in der Environment. Nachdem wir unsere Daten
        geladen haben, können wir loslegen und definieren unser Modell. Einfache
        Moderationen werden mit Modell 1 berechnet. Die abhängige Variable ist
        Punkte, die unabhängige- Zeit und der Moderator die besuchten
        Veranstaltungen. Zudem stellen wir 10.000 Bootstrapping Iterationen ein.
        Das heißt, dass 10.000 Zufallsstichproben mit Zurücklegen, gezogen
        werden. Daraus werden die Parameter berechnet, die robust gegen
        Verletzungen sind. Gegen Heteroskedastizität benutzen wir die HC3
        Anpassung des Standardfehlers und wir mean-centern die Variablen.
      </p>

      <p>Der Code sollte dann so aussehen:</p>
      <p>
        <b
          >process(data=Moderation_Example,y=
          "Punkte_Statistik",x="Zeit_gelernt",w="Veranstaltungen_besucht",model=1,boot=10000,
          modelbt = 1, center=2,hc=3)</b
        >
      </p>

      <p>data Unser Datensatz</p>
      <p>y Die abhängige Variable</p>
      <p>x unabhängige Variable</p>
      <p>w Der Moderator</p>
      <p>model Der Model Typ, in unserem Fall 1 für einfache Moderationen</p>
      <p>boot Zufallstichproben</p>
      <p>modelbt Bootstrapping</p>
      <p>center mean centern</p>
      <p>hc Anpassung für Heteroskedastizität</p>

      <img
        src="../../../assets/img/modmed/Moderation in R PROCESS Bootstrapping.png"
        alt="Moderation in R PROCESS Bootstrapping"
      />

      <p>
        Wenn wir uns den Output näher anschauen, sehen wir, dass es sich um eine
        multiple lineare Regression mit Interaktionsterm handelt
      </p>

      <img
        src="../../../assets/img/modmed/Moderation in R interpretieren.png"
        alt="Moderation in R interpretiere"
      />

      <p>
        An dieser Stelle können wir sehen, dass unser Modell eindeutig
        signifikant wird (F(3,44)=132.808, p &lt;.001, R²=.760). Das heißt, dass
        76.0 % der Varianz der Klausurleistung durch die in das Modell
        aufgenommen Variablen erklärt werden können. Wenn wir die Effektstärke
        nach Cohen (1992) berechnen, zeigt sich ein sehr starker Effekt
        (f²=3.17).
      </p>

      <div class="button" routerLink="/statistische-tests/effektstärkenrechner">
        <span>Effektstärkenrechner</span>
      </div>

      <p>
        Was sagen uns nun die einzelnen Koeffizienten? Wir können sehen, dass
        die investierte Lernleistung einen Einfluss ausübt. Steigt diese um eine
        Stunde, so steigt der Klausurerfolg 1.093 Prozentpunkte (b=1.093,
        t=8.097, p&lt;.001). Die Bootstrapping Konfidenzintervalle können das
        Nullmodell (b=0) eindeutig ausschließen (CI 95 [.845 1.417]).
      </p>
      <p>
        Die Interpretation der Zahl der Veranstaltung erfolgt analog. Wir sehen,
        dass der Besuch von Veranstaltungen jedoch keinen Einfluss ausübt
        (b=1.108, t=.953, p=.346). Die Bootstrapping Konfidenzintervalle können
        das Nullmodell (b=0) nicht ausschließen (CI 95 [-1.430 3.378]).
      </p>

      <p>
        Allerdings beobachten wir einen signifikanten Interaktionsterm (b=.057,
        t=2.418, p=.019). Die Bootstrapping Konfidenzintervalle kann das
        Nullmodell (b=0) knapp ausschließen (CI 95 [.004 .112]). Eine
        Interpretation des Steigungskoeffizienten ergibt hier jedoch keinen
        Sinn. Dazu schauen wir uns die Conditional Effects an
      </p>

      <p>
        In der Tabelle werden die Prädiktoren alterniert. Anschließend wird die
        abhängige Variable berechnet. Wir sehen hier, dass mit steigender
        Veranstaltung der Effekt von Lernen auf die Punkte weiter steigt (.827->
        1.168->1.224)
      </p>

      <p>Deutlicher wird das Ganze in der Grafik</p>

      <img
        src="../../../assets/img/modmed/Moderation in R Grafik.png"
        alt="Moderation in R Grafik"
      />

      <p>
        Wir können sehen, dass mit steigender Zeit, die Zahl der erreichten
        Punkte steigt. Jedoch steigen die Geraden nicht gleichmäßig. Wenn man
        die Stichprobe in 3 Gruppen einteilt, der Anzahl der besuchten
        Veranstaltungen entsprechend, sehen wir, dass der Zusammenhang am
        niedrigsten bei der Gruppe mit wenig besuchten Veranstaltungen ausfällt
        (schwarz). Die Studierenden, die viele Statistikveranstaltungen besucht
        haben (gepunktet), zeigen den stärksten Zusammenhang zwischen gelernter
        Zeit und Punkten.
      </p>
      <div
        class="button"
        routerLink="/statistische-tests/testuebersicht/moderation-mediation"
      >
        <span>Zurück zur Übersicht</span>
      </div>
      <br />
      <p>
        Fragen, Probleme, Anmerkungen? Dann schreib uns einfach und wir helfen
        dir sehr gerne weiter.
      </p>

      <div class="button" routerLink="/kostenloses-erstgepraech">
        <span>Kostenloses Erstgespräch</span>
      </div>

      <p>Dein Team von Hanseatic-Statistics.</p>
    </main>
  </div>
</div>
