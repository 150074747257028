import { Component, OnChanges, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-stat-test',
  templateUrl: './stat-test.component.html',
  styleUrls: ['./stat-test.component.scss'],
})
export class StatTestComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    private cookieService: CookieService
  ) {}

  allowCookies = false;

  jahr = new Date().getFullYear();
  uvn = false;
  uvo = false;
  uvm = false;
  var1 = false;
  quer = false;
  laengs = false;
  avn = false;
  avo = false;
  avm = false;
  multav = false;
  multuv = false;
  l = false;
  q = false;

  uvnc = false;
  uvoc = false;
  uvmc = false;
  var1c = false;
  querc = false;
  laengsc = false;
  avnc = false;
  avoc = false;
  avmc = false;
  multavc = false;
  multuvc = false;
  lc = false;
  qc = false;

  selectedTest = '';
  showTest = true;

  ngOnInit(): void {
    this.titleService.setTitle('Hanseatic Statistics - Statistiktests');
    this.cookieService.get('cookieconsent_status');
    if (this.cookieService.get('cookieconsent_status') == 'deny')
      this.allowCookies = false;
    if (this.cookieService.get('cookieconsent_status') == 'allow')
      this.allowCookies = true;
  }

  resetTests() {
    this.uvn = false;
    this.uvo = false;
    this.uvm = false;
    this.var1 = false;
    this.quer = false;
    this.laengs = false;
    this.avn = false;
    this.avo = false;
    this.avm = false;
    this.multav = false;
    this.multuv = false;
    this.l = false;
    this.q = false;

    this.uvnc = false;
    this.uvoc = false;
    this.uvmc = false;
    this.var1c = false;
    this.querc = false;
    this.laengsc = false;
    this.avnc = false;
    this.avoc = false;
    this.avmc = false;
    this.multavc = false;
    this.multuvc = false;
    this.lc = false;
    this.qc = false;
  }
}
