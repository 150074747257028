<div class="header-wrapper component-wrapper">
  <div class="header">
    <div class="headerbg">
      <img
        src="../../assets/img/header-bg.png"
        alt="statistic background"
        class="header-bg"
      />
      <img
        src="../../assets/img/header-bg-mobile.png"
        alt="statistic background"
        class="header-bg-mobile"
      />
    </div>
    <div class="logo">
      <img src="../../assets/img/logo_old.png" alt="hanseatic logo" />
    </div>
  </div>
  <div class="ham">
    <div
      class="hamburger hamburger--collapse"
      [ngClass]="{ 'is-active': navActive }"
      (click)="toggleNav()"
    >
      <span class="hamburger-box">
        <span class="hamburger-inner"></span>
      </span>
    </div>
  </div>
  <nav
    class="navigation"
    [ngClass]="{ 'nav-active': navActive }"
    (click)="reload()"
  >
    <div class="logo-container">
      <img
        routerLink="/"
        class="nav-logo"
        src="../../assets/img/logo-invert_old.png"
        alt="hanseatic logo"
      />
    </div>
    <ul class="nav-list" *ngIf="corporate && websiteEntered">
      <li>
        <a href="/business">Datenanalyse für Unternehmen</a>
      </li>
      <!-- <li>
        <a href="/business/preise">Preise</a>
      </li> -->
      <li>
        <a href="/business/kontakt">Kontakt</a>
      </li>
    </ul>
    <ul class="nav-list" *ngIf="!corporate && websiteEntered">
      <li>
        <a routerLinkActive="active-link" routerLink="/statistik-hilfe"
          >Statistik hilfe</a
        >
      </li>
      <li class="main-link" style="position: relative; cursor: default">
        <a>
          <span
            style="cursor: pointer"
            routerLinkActive="active-link"
            [routerLinkActiveOptions]="{ exact: false }"
            routerLink="/statistik-auswertung-datenanalyse/"
            >Statistik Auswertung / Datenanalyse</span
          >
          <ul class="dropdown">
            <li>
              <a
                routerLinkActive="active-link"
                routerLink="/statistik-auswertung-datenanalyse/spss-auswertung"
                >SPSS</a
              >
            </li>
            <li>
              <a
                routerLinkActive="active-link"
                routerLink="/statistik-auswertung-datenanalyse/r-auswertung"
                >R</a
              >
            </li>
            <li>
              <a
                routerLinkActive="active-link"
                routerLink="/statistik-auswertung-datenanalyse/jamovi-auswertung"
                >JAMOVI</a
              >
            </li>
            <li>
              <a
                routerLinkActive="active-link"
                routerLink="/statistik-auswertung-datenanalyse/stata-auswertung"
                >STATA</a
              >
            </li>
            <li>
              <a
                routerLinkActive="active-link"
                routerLink="/statistik-auswertung-datenanalyse/python-auswertung"
                >PYTHON</a
              >
            </li>
            <li>
              <a
                routerLinkActive="active-link"
                routerLink="/statistik-auswertung-datenanalyse/jasp-auswertung"
                >JASP</a
              >
            </li>
            <li>
              <a
                routerLinkActive="active-link"
                routerLink="/statistik-auswertung-datenanalyse/matlab-auswertung"
                >MATLAB</a
              >
            </li>
            <li>
              <a
                routerLinkActive="active-link"
                routerLink="/statistik-auswertung-datenanalyse/smartpls-auswertung"
                >SmartPLS</a
              >
            </li>
          </ul>
        </a>
      </li>
      <li class="main-link" style="position: relative; cursor: default">
        <a>
          <span
            style="cursor: pointer"
            routerLinkActive="active-link"
            [routerLinkActiveOptions]="{ exact: false }"
            routerLink="/fragebogen-auswertung/"
            >fragebogen auswertung</span
          >
          <ul class="dropdown">
            <li>
              <a
                routerLinkActive="active-link"
                routerLink="/fragebogen-auswertung/fragebogen-auswertung-spss"
                >fragebogen auswertung in SPSS</a
              >
            </li>
            <li>
              <a
                routerLinkActive="active-link"
                routerLink="/fragebogen-auswertung/fragebogen-auswertung-r"
                >fragebogen auswertung in R</a
              >
            </li>
            <li>
              <a
                routerLinkActive="active-link"
                routerLink="/fragebogen-auswertung/fragebogen-auswertung-jasp"
                >fragebogen auswerten in JASP</a
              >
            </li>
          </ul>
        </a>
      </li>
      <li class="main-link" style="cursor: default">
        <a
          routerLinkActive="active-link"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <span routerLink="/statistische-tests/testuebersicht"
            >statistische tests</span
          >
          <ul class="dropdown">
            <li>
              <a
                routerLinkActive="active-link"
                routerLink="/statistische-tests/testuebersicht"
                >Testübersicht</a
              >
            </li>
            <li class="secondary-link">
              <a>
                <span
                  style="cursor: pointer"
                  routerLink="/statistische-tests/hypothesen-tests"
                  >Hypothesen testen</span
                >
                <ul class="sub-dropdown">
                  <li>
                    <a
                      routerLinkActive="active-link"
                      routerLink="/statistische-tests/hypothesen-tests"
                      fragment="p-wert"
                      >P-Wert</a
                    >
                  </li>
                  <li>
                    <a
                      routerLinkActive="active-link"
                      routerLink="/statistische-tests/hypothesen-tests"
                      fragment="effektstaerken"
                      >Effektstärken</a
                    >
                  </li>
                  <li>
                    <a
                      routerLinkActive="active-link"
                      routerLink="/statistische-tests/hypothesen-tests"
                      fragment="bayes-faktor"
                      >Bayes Faktor</a
                    >
                  </li>
                </ul>
              </a>
            </li>
            <li class="secondary-link">
              <a>
                <span
                  style="cursor: pointer"
                  routerLink="/statistische-tests/effektstärkenrechner"
                  >Effektstärken-rechner</span
                >
                <ul class="sub-dropdown">
                  <li>
                    <a
                      routerLink="/statistische-tests/effektstärkenrechner"
                      fragment="T-Test-U"
                      >T-Test (unabhängig)</a
                    >
                  </li>
                  <li>
                    <a
                      routerLink="/statistische-tests/effektstärkenrechner"
                      fragment="Mann-Whitney-U-Test"
                      >Mann-Whitney-U-Test</a
                    >
                  </li>
                  <li>
                    <a
                      routerLink="/statistische-tests/effektstärkenrechner"
                      fragment="T-Test-V"
                      >T-Test (verbunden)</a
                    >
                  </li>
                  <li>
                    <a
                      routerLink="/statistische-tests/effektstärkenrechner"
                      fragment="Wilcoxon-Test"
                      >Wilcoxon-Test</a
                    >
                  </li>
                  <li>
                    <a
                      routerLink="/statistische-tests/effektstärkenrechner"
                      fragment="T-Test-E"
                      >T-Test (einstichproben-)</a
                    >
                  </li>
                  <li>
                    <a
                      routerLink="/statistische-tests/effektstärkenrechner"
                      fragment="ANOVA"
                      >ANOVA (Gruppenvergleich, Messwiederholung, beides)</a
                    >
                  </li>
                  <li>
                    <a
                      routerLink="/statistische-tests/effektstärkenrechner"
                      fragment="Kruskal-Wallis-Test"
                      >Kruskal-Wallis-Test</a
                    >
                  </li>
                  <li>
                    <a
                      routerLink="/statistische-tests/effektstärkenrechner"
                      fragment="Friedman-Test"
                      >Friedman-Test</a
                    >
                  </li>
                  <li>
                    <a
                      routerLink="/statistische-tests/effektstärkenrechner"
                      fragment="Chi2"
                      >Chi²</a
                    >
                  </li>
                  <li>
                    <a
                      routerLink="/statistische-tests/effektstärkenrechner"
                      fragment="Pearson-Spearman-Kendall"
                      >Korrelationen (Person, Spearman,Kendall)</a
                    >
                  </li>
                  <li>
                    <a
                      routerLink="/statistische-tests/effektstärkenrechner"
                      fragment="Lineare-Regressionen"
                      >Lineare Regressionen</a
                    >
                  </li>
                  <li>
                    <a
                      routerLink="/statistische-tests/effektstärkenrechner"
                      fragment="Logistische-Regressionen"
                      >Logistische Regressionen</a
                    >
                  </li>
                </ul>
              </a>
            </li>
            <li>
              <a routerLinkActive="active-link" routerLink="/skalenniveau"
                >Skalenniveau</a
              >
            </li>
          </ul>
        </a>
      </li>
      <li routerLinkActive="active-link" routerLink="/kostenloses-erstgepraech">
        <a>kostenloses erstgespräch</a>
      </li>
      <li routerLinkActive="active-link" routerLink="/qualifikationen">
        <a>qualifikationen</a>
      </li>
      <li routerLinkActive="active-link" routerLink="/referenzen">
        <a>referenzen</a>
      </li>
      <li routerLinkActive="active-link" routerLink="/preise"><a>preise</a></li>
      <li>
        <a
          [routerLinkActiveOptions]="{ exact: true }"
          routerLinkActive="active-link"
          routerLink="/blog"
          >Blog</a
        >
      </li>
    </ul>
  </nav>
</div>
