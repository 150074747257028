<div class="component-wrapper erstgespraech-wrapper">
  <div class="content-wrapper">
    <main>
      <h1>Erstgespräch - einfach und unverbindlich!</h1>
      <span>Vereinbare jetzt Dein kostenloses Erstgespräch</span>
      <p>
        Für Dein Anliegen haben wir eine Vielzahl von Kontaktmöglichkeiten zur
        Verfügung gestellt. Bitte wähle einfach die für dich Passende aus und
        kontaktiere uns.
      </p>
      <br />
      <p class="contact">
        <img src="../../assets/img/icons/call-24px.svg" alt="" />
        <a style="color: black" href="tel:+494022634973">
          <b>+49 40 22634973</b>
        </a>
      </p>
      <p class="contact">
        <img src="../../assets/img/icons/mail-24px.svg" alt="" />
        <a style="color: black" href="mailto:beratung@hanseatic-statistics.de">
          <b>beratung&#64;hanseatic-statistics.de</b>
        </a>
      </p>
      <p>Oder schreib uns einfach direkt über das Website-Formular</p>
      <p>Wir beantworten deine Anfrage noch am selben Tag!</p>
      <div class="contact-form">
        <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label for="name">Name <span>*</span></label>
            <input
              [ngClass]="{
                'invalid-input':
                  contactForm.controls.name.touched &&
                  !contactForm.controls.name.valid
              }"
              type="text"
              name="name"
              id="name"
              formControlName="name"
            />
            <span
              class="warning"
              *ngIf="
                contactForm.controls.name.touched &&
                !contactForm.controls.name.valid
              "
              >Feld bitte ausfüllen</span
            >
          </div>
          <div class="form-group">
            <label for="email">E-Mail <span>*</span></label>
            <input
              [ngClass]="{
                'invalid-input':
                  contactForm.controls.email.touched &&
                  !contactForm.controls.email.valid
              }"
              type="email"
              name="email"
              id="email"
              formControlName="email"
            />
            <span
              class="warning"
              *ngIf="
                contactForm.controls.email.touched &&
                !contactForm.controls.email.valid
              "
              >Feld bitte ausfüllen</span
            >
          </div>
          <div class="form-group">
            <label for="nachricht">Nachricht <span>*</span></label>
            <textarea
              [ngClass]="{
                'invalid-input':
                  contactForm.controls.nachricht.touched &&
                  !contactForm.controls.nachricht.valid
              }"
              name="nachricht"
              id="nachricht"
              cols="30"
              rows="10"
              formControlName="nachricht"
            ></textarea>
            <span
              class="warning"
              *ngIf="
                contactForm.controls.nachricht.touched &&
                !contactForm.controls.nachricht.valid
              "
              >Feld bitte ausfüllen</span
            >
          </div>
          <span style="font-size: 0.9rem"
            ><span style="color: red">*</span> Pflichtfeld</span
          ><br /><br />
          <div class="success" *ngIf="success">
            Vielen Dank für deine Nachricht! Wir werden deine Anfrage umgehend
            bearbeiten.
          </div>
          <button [disabled]="!contactForm.valid" type="submit" class="button">
            <span>Erstgespäch anfordern</span>
          </button>
        </form>
      </div>
    </main>
  </div>
</div>
