import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-u-test-q',
  templateUrl: './u-test-q.component.html',
  styleUrls: ['./u-test-q.component.scss']
})
export class UTestQComponent implements OnInit {

  constructor(private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle('Hanseatic Statistics - U-Test')
  }

}
