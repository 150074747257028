<div class="component-wrapper">
  <div class="content-wrapper">
    <main>
      <p>
        Um zu verstehen was Moderationen, Mediationen und deren unzählige
        Kombinationsmöglichkeiten sind, ist es ratsam sich mit
        <b
          ><a routerLink="/statistische-tests/testuebersicht/lineare-regression"
            >linearer Regressionen</a
          ></b
        >
        zu befassen.
      </p>
      <div class="mod">
        <h1 id="Moderation">Moderation</h1>
        <p>
          Eine Moderationsanalyse testet, ob mindestens 2 variablen (unabhängige
          Variable und Moderator) und deren Interaktion (eigentlicher
          Moderationseffekt) einen Einfluss auf eine abhängige Variable haben.
        </p>
        <h2>Typ und Anwendung</h2>

        <p>
          Zusammenhangsanalyse. Gibt es einen Zusammenhang zwischen zwei oder
          mehreren Variablen? Wie verhält sich die abhängige Variable Y, wenn
          die Variablen X1 & X2 um jeweils eine Einheit steigen? Interagieren
          zudem X1 und X2 oder auch: Wird der Einfluss von X auf Y seinerseits
          von M beeinflusst.
        </p>

        <img
          src="../../../assets/img/modmed/Moderation in R SPSS STATA JASP JAMOVI.png"
          alt="Moderation in R SPSS STATA JASP JAMOVI"
        />

        <h2>Beispiel</h2>

        <p>
          Gibt es einen Zusammenhang zwischen der gelernten Zeit für die
          Statistikklausur und der erreichten Punktzahl? Wird dieser
          Zusammenhang zudem von der Anzahl der besuchten Veranstaltungen
          beeinflusst (Interaktion = Moderation)? Der Gedanke dahinter ist ein
          einfach: Wir vermuten, dass die gelernte Zeit vermutlich nur einen
          geringen Einfluss auf die Punkte hat, wenn eine studierende Person nur
          wenige Veranstaltungen besucht hat. Hat sie hingegen viele
          Veranstaltungen besucht, so gibt es einen Zusammenhang zwischen der
          Klausurvorbereitung in Statistik und den erreichten Punkten.
        </p>

        <p>
          Wir vermuten also: Punkte = Zeit + Veranstaltungen +
          Zeit*Veranstaltungen
        </p>

        <p>oder als statistisches Modell:</p>

        <p>
          Punkte= ß0 + ß1*Zeit + ß2*Veranstaltungen + ß3* Zeit*Veranstaltungen +
          E
        </p>

        <p>
          Damit ist die Moderation nichts weiter als eine multiple lineare
          Regression mit einem Interaktionsterm.
        </p>

        <h2>Voraussetzungen</h2>

        <p>
          Darauf gehen wir an dieser Stelle nicht im Detail ein, da wir die
          Analyse mit Bootstrapping durchführen werden. Eine Übersicht der
          Analysevoraussetzungen befindet sich hier:
        </p>

        <div
          class="button"
          routerLink="/statistische-tests/testuebersicht/lineare-regression"
        >
          <span>Lineare Regression</span>
        </div>

        <p>
          wichtig ist jedoch, dass metrische Variablen im Modell gemittelt (Mean
          Centering) werden.
        </p>

        <div class="button-wrapper">
          <button routerLink="/moderation-in-spss" class="button">
            <span>Beispiel: Moderation in SPSS</span>
          </button>
          <button routerLink="/moderation-in-r" class="button">
            <span>Beispiel: Moderation in R</span>
          </button>
        </div>
      </div>
      <div class="med">
        <h1>Mediation</h1>

        <p>
          Einer Mediation liegt hingegen eine andere theoretische Annahme zu
          Grunde. Hier geht man davon aus, dass ein Effekt auf zwei Wegen
          zustande kommt. Einmal direkt von der unabhängigen auf die abhängige
          Variable und einmal indirekt über den Mediator. Hier geht man also
          nicht davon aus, dass ein Zusammenhang zwischen zwei Variablen
          beeinflusst wird, sondern dass ein Zusammenhang direkt und indirekt
          zustande kommt.
        </p>

        <h2>Typ und Anwendung</h2>

        <p>
          Zusammenhangsanalyse. Gibt es einen Zusammenhang zwischen mindestens
          zwei Variablen und kommt dieser direkt und/oder indirekt zustande?
        </p>

        <img
          src="../../../assets/img/modmed/Mediation in R SPSS STATA JASP JAMOVI.png"
          alt="Mediation in R SPSS STATA JASP JAMOVI"
        />

        <h2>Beispiel</h2>

        <p>
          Wir gehen davon aus, dass das Interesse für Statistik einen Einfluss
          auf die erreichten Punkte in der Klausur hat. Jedoch kommt dieser
          Effekt nicht nur direkt, sondern auch indirekt zustande. Eine Person
          mit einem hohen Interesse für Statistik bekommt vermutlich mehr
          Punkte, da es ihr leichter fällt zu lernen. Jedoch besucht diese
          Person auch mehr Veranstaltung, was ebenfalls zu mehr Punkten in der
          Klausur führt.
        </p>

        <p>Wir vermuten also:</p>

        <p>Pfad A: Interesse -> Veranstaltung</p>

        <p>Pfad B: Veranstaltungen -> Punkte</p>

        <p>Pfad C : Interesse -> Punkte</p>

        <p>Daraus ergibt sich der direkte Effekt:</p>

        <p>Direkt: Pfad C</p>

        <p>Und der indirekte Effekt:</p>

        <p>Indirekt: Pfad A * Pfad B</p>

        <p>Und dies ergibt am Ende den totalen bzw. Gesamteffekt:</p>

        <p>Effekt: Indirekter Effekt + Direkter Effekt</p>

        <p>
          Nach Baron & Kenny (1986) können an dieser Stelle einfach zwei Lineare
          Regressionen berechnet werden.
        </p>

        <p>
          Die erste testet den A Pfad und die zweite den B und C Pfad gemeinsam,
          weil die abhängige Variable ja dieselbe ist. Ist der A Pfad nicht
          signifikant kann bereits abgebrochen werden. Sind A und B Pfad
          signifikant kann von einer Mediation gesprochen werden. Dabei gilt:
        </p>

        <p>Sind nur A und B signifikant ist es eine totale Mediation</p>

        <p>Sind A, B und C signifikant ist es partielle Mediation</p>

        <p>Ist nur A, nicht aber B signifikant, ist es keine Mediation</p>

        <p>Ist nur B, nicht aber A signifikant, ist es keine Mediation</p>

        <p>Ist nur C signifikant, ist es ein einfacher direkter Effekt</p>

        <p>Sind weder A, noch B, noch C signifikant, erübrigt sich die Frage</p>

        <p>
          Allerdings ist dieser Ansatz recht veraltet. Nach Hays (2015) kann ein
          indirekter Effekt dennoch zustande kommen, auch wenn der A Pfad nicht
          signifikant ist. In den Beispielen erklären wir, was es damit auf sich
          hat.
        </p>

        <h2>Voraussetzungen</h2>

        <p>
          Darauf gehen wir an dieser Stelle nicht ein, da wir die Analyse mit
          Bootstrapping durchführen werden. Eine Übersicht der
          Analysevoraussetzungen befindet sich hier:
        </p>

        <div class="button-wrapper">
          <button routerLink="/mediation-in-spss" class="button">
            <span>Beispiel: Mediation in SPSS</span>
          </button>
          <button routerLink="/mediation-in-r" class="button">
            <span>Beispiel: Mediation in R</span>
          </button>
        </div>
        <br />
        <div
          class="button"
          routerLink="/statistische-tests/testuebersicht/lineare-regression"
        >
          <span>Lineare Regression</span>
        </div>

        <p>
          Der Unterschied zwischen einer Mediation und einer Moderation ist
          daher theoretischer Natur. Die Frage muss dabei vorab gestellt werden,
          ob man davon ausgeht, dass ein Zusammenhang durch eine dritte Variable
          abgeschwächt oder verstärkt wird oder ob der Zusammenhang durch eine
          dritte Variable überhaupt zustande kommt.
        </p>
      </div>
    </main>
  </div>
</div>
