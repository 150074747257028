<div class="component-wrapper">
  <main>
    <div class="content-wrapper">
      <h1>Hanseatic-Statistics</h1>
      <h2>Wir bringen dich weiter!</h2>
      <p>
        Hanseatic-Statistics. Wir helfen Dir bei deiner statistischen Auswertung
        und Datenanalyse deiner Abschlussarbeit in R, SPSS, STATA, Jamovi, JASP,
        Python, Matlab, SmartPLS, PSPP oder geben Dir ganz einfach Nachhilfe in
        Statistik.
      </p>
    </div>
    <div class="zitat">
      <div class="zitat-text">
        <i
          >"Hi, also das Coaching hat gut geklappt und am Ende ist es eine 1.3
          geworden."</i
        >
        <div class="name">- Paul</div>
      </div>
    </div>
    <div class="content-wrapper">
      <h2>Ortsunabhängig</h2>
      <p>
        Wir können Dich dabei in ganz Deutschland, Österreich und der Schweiz
        unterstützen.
      </p>
      <h2>Ohne Risiko</h2>
      <p>Fordere einfach und unverbindlich dein kostenloses Erstgespräch an!</p>
      <div class="button" routerLink="/kostenloses-erstgepraech">
        <span>Kostenloses Erstgespräch</span>
      </div>
      <hr />
      <div style="text-align: center; font-size: 2rem">
        Anzahl betreuter Abschlussarbeiten: <br />
        <h2
          style="
            text-align: center;
            color: rgb(0, 115, 255);
            font-size: 4rem;
            margin-bottom: 3rem;
          "
        >
          {{ arbeiten }}
        </h2>
      </div>
    </div>
  </main>
</div>
