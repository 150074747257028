<div class="component-wrapper">
  <div class="content-wrapper">
    <main>
      <h1>Lineare Regression</h1>
      <p>
        Die <b>lineare Regression</b>, oder auch die OLS Regression (ordinary
        least squares) gehört vermutlich zu den häufigsten Analysemethoden
        überhaupt. Sie dient als Grundlage für eine Vielzahl von Tests, von
        nicht-linearen Verfahren bis hin zu modernen Algorithmen in künstlicher
        Intelligenz. Dabei handelt es sich jedoch um ein sehr einfaches
        Verfahren, welches die allermeisten von uns bereits unbewusst in der 8.
        Oder 9. Klasse gelernt haben. Wer sich zurückerinnert, dem wird
        eventuell einfallen, wie man kleine Dreiecke an Geraden zeichnete um
        herauszufinden wieviel Einheiten man auf der Y-Achse verrutschen muss,
        wenn man auf der X-Achse um eine Einheit nach rechts verschiebt.
      </p>
      <p>Hier eine Erklärung auf YouTube</p>
      <div class="youtube-wrapper">
        <p *ngIf="allowCookies">
          Hier eine Hilfe, wie ihr das richtige Testverfahren findet
        </p>
        <iframe
          *ngIf="allowCookies"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/MCH0Ex8b1ag"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <h4 *ngIf="!allowCookies">Youtube Einbindung</h4>
        <div *ngIf="!allowCookies" class="youtube-denied">
          Hier solltest du eigentlich ein YouTube Video mit einer Erklärung
          sehen, wie man unsere Testauswahl benutzt. Da wir aber deine
          Privatsphäre respektieren, binden wir es aufgrund deiner
          Cookie-Entscheidung nicht ein. Falls du dich doch dafür entscheiden
          solltest, akzeptiere unten einfach unsere Cookie-Richtlinie.
        </div>
      </div>

      <h2>Typ und Anwendung</h2>
      <p>
        <b>Zusammenhangsanalyse.</b> Gibt es einen Zusammenhang zwischen zwei
        oder mehreren Variablen? Wie verhält sich die abhängige Variable Y, wenn
        die Variablen X1, X2,… um jeweils eine Einheit steigen? Die Regression
        kann dabei als eine Fortführung einer Korrelation gesehen werden.
        Während eine Korrelationsanalyse eine Aussage erzeugt, wie z.B. Steigt
        X, so steigt auch Y, kann die Regression den Zusammenhang
        quantifizieren. Es handelt sich daher um ein relativ einfaches
        Vorhersagemodel
      </p>
      <p>
        <b>Beispiel</b><br />
        Eine einfache lineare Regression besteht aus einer abhängigen und einer
        unabhängigen Variable. Beispielsweise: welchen Einfluss hat ein
        bestimmtes Persönlichkeitsmerkmal der BIG-5 auf die
        Arbeitszufriedenheit, oder auch: Wie verhält sich die
        Arbeitszufriedenheit, wenn ein Merkmal der BIG-5 um eine Einheit
        steigt?<br /><br />
        Eine multiple lineare Regression untersucht den Einfluss mehrerer
        unabhängiger Variablen auf eine abhängige Variable. Beispielsweise: wie
        verhält sich der Einfluss der fünf BIG-5 Faktoren auf die
        Arbeitszufriedenheit?
      </p>
      <p>
        Wichtig ist an dieser Stelle zudem, dass hier von univariaten
        Regressionen gesprochen wird. Oft wird multipel und multivariat
        verwechselt. Eine univariate Analyse hat eine abhängige Variable. Eine
        multivariate mehrere abhängige. Einfache und multiple- bezieht sich
        jedoch auf die Anzahl der unabhängigen variablen.
      </p>

      <h2>Voraussetzungen</h2>
      <ul>
        <li>Die UVs müssen metrisch oder Dummy Codiert sein (0-1)</li>
        <li>Die AV muss metrisch sein</li>
        <li>Der Zusammenhang muss linear sein</li>
        <li>Keine Autokorrelation &lt;- Durbin-Watson-Test</li>
        <li>Keine Multikollinearität &lt;- Varianzinflationsfaktoren</li>
        <li>Homoskedastizität &lt;- Breusch-Pagan-Test</li>
        <li>Ausreißer müssen eliminiert werden &lt;- Cook’s Distance</li>
        <li>Residuale Normalverteilung &lt;- Shapiro-Wilk-Test</li>
      </ul>
      <br />
      <h2>Beispiel SPSS</h2>
      <p>
        Gegeben sei ein einfacher Datensatz. 16 Studierende wurden hinsichtlich
        ihrer Statistikklausur befragt. Die Angabe erfolgte dabei in Prozent
        (Eine Schulnote stellt ein ordinales Skalenniveau dar und kann an dieser
        Stelle nicht verwendet werden!). Als unabhängige Variablen, die die
        Leistung beeinflusst haben, vermuten wir die gelernten Stunden, der
        Klausurversuch und das Geschlecht.
      </p>
      <img
        src="../../../assets/img/beispiel_spss_1.png"
        alt="beispiel 1 spss"
      />
      <p>
        Wichtig ist an dieser Stelle, wie eigentlich immer, das Skalenniveau.
        Alle Variablen sind metrisch, mit Ausnahme des Geschlechts. Dieses ist
        hier Dummy-codiert (0-weiblich, 1-männlich).
      </p>
      <img
        src="../../../assets/img/beispiel_spss_2.png"
        alt="beispiel 2 spss"
      />
      <p>
        Über Analysieren->Regression->Linear können wir unser Modell bauen.
        Wichtig sind an dieser Stelle auch die Tests der Voraussetzungen. In den
        Menüs „Statistiken“ und „Optionen“ können wir alles einstellen.
      </p>
      <p>
        Optional können wir uns das Modell auch selbst in der Syntax
        programmieren:
      </p>

      <div class="syntax" style="text-align: left; font-weight: bold">
        <span style="color: rgb(0, 132, 255)">REGRESSION</span><br />
        <span style="color: rgb(0, 128, 28)">/DESCRIPTIVES</span
        ><span style="color: rgb(194, 0, 0)"> MEAN STDDEV CORR SIG N</span
        ><br />
        <span style="color: rgb(0, 128, 28)">/MISSING</span
        ><span style="color: rgb(194, 0, 0)"> PAIRWISE</span> <br />
        <span style="color: rgb(0, 128, 28)">/STATISTICS</span
        ><span style="color: rgb(194, 0, 0)">
          COEFF OUTS R ANOVA COLLIN TOL</span
        >
        <br />
        <span style="color: rgb(0, 128, 28)">/CRITERIA=</span
        ><span style="color: rgb(194, 0, 0)">PIN</span>(.05)
        <span style="color: rgb(194, 0, 0)">POUT</span>(.10) <br />
        <span style="color: rgb(0, 128, 28)">/NOORIGIN</span>
        <span style="color: rgb(0, 128, 28)">/DEPENDENT</span>
        Resultat_in_Prozent <br />
        <span style="color: rgb(0, 128, 28)">/METHOD=</span
        ><span style="color: rgb(194, 0, 0)">ENTER</span> Stunden_gelernt
        Versuch Geschlecht <br />
        <span style="color: rgb(0, 128, 28)">/PARTIALPLOT</span
        ><span style="color: rgb(194, 0, 0)"> ALL</span><br />
        <span style="color: rgb(0, 128, 28)">/SCATTERPLOT=</span>(*ZRESID
        ,*ZPRED) <br />
        <span style="color: rgb(0, 128, 28)">/RESIDUALS</span
        ><span style="color: rgb(194, 0, 0)"> DURBIN HISTOGRAM</span>(<span
          style="color: rgb(255, 166, 0)"
          >ZRESID</span
        >) NORMPROB(<span style="color: rgb(255, 166, 0)">ZRESID</span>).
      </div>

      <p>Als Resultat zeigt sich folgendes Ergebnis:</p>
      <img
        src="../../../assets/img/regression.png"
        alt="regressions übersicht"
      />
      <h2>Beispiel in R</h2>
      <p>
        Die Funktion „LM“ ist in R vergleichsweise einfach aufgebaut. Nachdem
        wir unseren Datensatz aus SPSS eingelesen haben, können wir hier ganz
        einfach unser Modell bauen. Wenn wir den Datensatz "df" in der Funktion
        definiert haben, können wir an erster Stelle die abhängige Variable
        definieren und dann die unabhängigen.
      </p>
      <p>
        <i>Wir können einfach auf run drücken, um den R Code auszuführen.</i>
      </p>
      <iframe
        width="100%"
        height="800"
        src="https://rdrr.io/snippets/embed/?code=%23Datensatz%20erstellen%0A%23%23Variablen%20erstellen%0A%0AResultat_in_Prozent%3C-c(85.00%2C70.00%2C73.00%2C91.00%2C99.00%2C100.00%2C23.00%2C67.00%2C45.00%2C87.00%2C23.00%2C56.00%2C76.00%2C98.00%2C28.00%2C94.00)%0AStunden_gelernt%3C-c(21.00%2C18.00%2C19.00%2C23.00%2C25.00%2C30.00%2C4.00%2C16.00%2C10.00%2C25.00%2C5.00%2C10.00%2C19.00%2C28.00%2C10.00%2C27.00)%0AVersuch%3C-c(1.00%2C1.00%2C2.00%2C2.00%2C1.00%2C1.00%2C3.00%2C1.00%2C1.00%2C3.00%2C2.00%2C1.00%2C3.00%2C3.00%2C2.00%2C1.00)%0AGeschlecht%3C-c(.00%2C.00%2C.00%2C.00%2C.00%2C.00%2C.00%2C.00%2C1.00%2C1.00%2C1.00%2C1.00%2C1.00%2C1.00%2C1.00%2C1.00)%0A%0A%23%23Zu%20einem%20Datensatz%20zusammenf%C3%BChren%0A%0Adf%3C-data.frame(Resultat_in_Prozent%2CStunden_gelernt%2CVersuch%2CGeschlecht)%0A%0A%0A%23%23%23Lineare%20Regression%0A%0AlinearMod%20%3C-%20lm(Resultat_in_Prozent%20%20~%20%20Stunden_gelernt%20%2B%20Versuch%20%2B%20Geschlecht%2C%20data%3Ddf)%20%0Asummary(linearMod)"
        frameborder="0"
      ></iframe>
      <div class="youtube-wrapper">
        <p *ngIf="allowCookies">
          Hier eine Hilfe, wie ihr das richtige Testverfahren findet
        </p>
        <iframe
          *ngIf="allowCookies"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/qFqr7i_S31I"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <h4 *ngIf="!allowCookies">Youtube Einbindung</h4>
        <div *ngIf="!allowCookies" class="youtube-denied">
          Hier solltest du eigentlich ein YouTube Video mit einer Erklärung
          sehen, wie man unsere Testauswahl benutzt. Da wir aber deine
          Privatsphäre respektieren, binden wir es aufgrund deiner
          Cookie-Entscheidung nicht ein. Falls du dich doch dafür entscheiden
          solltest, akzeptiere unten einfach unsere Cookie-Richtlinie.
        </div>
      </div>

      <h2>Interpretation</h2>
      <p>
        Zu allererst sollten wir die Modellzusammenfassung interpretieren. In
        SPSS ist diese unter ANOVA aufgeführt. Was hat jetzt die Varianzanalyse
        (ANOVA) hier verloren? Ganz einfach, lineare Regressionen und ANOVA sind
        beides dasselbe. Beides sind lineare Modelle die mit Varianzen arbeiten.
      </p>
      <p>
        An dieser Stelle können wir sehen, dass unser Modell eindeutig
        signifikant wird (F(3,13)=71.236, p <.001, R²adj=.934). Das heißt, dass
        93.4 % der Varianz der Klausurleistung durch die in das Modell
        aufgenommen Variablen erklärt werden können. Das ist natürlich enorm und
        der Praxis würde man hier von einem Over Fitting sprechen. An dieser
        Stelle ignorieren wir dies aber und freuen uns, dass unser Modell so gut
        fittet.
      </p>
      <p>
        Was sagen uns nun die einzelnen Koeffizienten? Wir können sehen, dass
        lediglich die investierte Lernleistung einen Einfluss ausübt. Steigt
        diese um eine Stunde, so steigt der Klausurerfolg 3.149 Prozentpunkte
        (b=3.149, t=14.113, p<.001,ß=.954). Der standardisierte Koeffizient kann
        als Korrelation interpretiert werden und fällt dementsprechend hoch aus.
      </p>
      <p>
        Die beiden Variablen Klausurversuch und Geschlecht können keinen
        signifikanten Einfluss ausüben. Tendenziell lässt sich aber ablesen,
        dass der Erfolg mit steigenden Versuch geringer wird und weibliche
        Versuchspersonen besser als männliche angeschlossen haben.
      </p>
      <p>Wir erhalten als Modell also folgende lineare Gleichung:</p>
      <div class="center">
        Klausurleistung = Intercept(17.3724) + 3.1493 * Lernstunden - 1.8552 *
        Versuch - 3.0369 * Geschlecht + Messfehler(E)
      </div>
      <p>
        Fragen, Probleme, Anmerkungen? Dann schreib uns einfach und wir helfen
        dir sehr gerne weiter.
      </p>
      <div class="button" routerLink="/kostenloses-erstgepraech">
        <span>Kostenloses Erstgespräch</span>
      </div>
      <p>Dein Team von Hanseatic-Statistics</p>
      <div class="quellen">
        Quellen: <br />
        Bortz, J. & Döring, N. (2016). Forschungsmethoden und Evaluation für
        Human- und Sozialwissenschaftler. 5. Auflage. Heidelberg: Springer.
        <br />
        Backhaus, K., Erichson, B., Plinke, W. & Weiber, R. (2016). Multivariate
        Analysemethoden. Eine anwendungsorientierte Einführung. 14. Auflage.
        Berlin: Springer. <br />
        Hirsig, R. (2006a). Statistische Methoden in den Sozialwissenschaften.
        Eine Einführung im Hinblick auf computergestützte Datenanalysen mit
        SPSS. Band I. 5. Auflage. Zürich: Seismo.
      </div>
    </main>
  </div>
</div>
