<div class="component-wrapper">
  <div class="content-wrapper">
    <main>
      <h1>Mediation in SPSS</h1>

      <p>
        Die einfachste Möglichkeit eine Mediation in SPSS durchzuführen ist es,
        das externe Tool SPSS PROCESS von Andrew Hays (2015) zu installieren.
      </p>

      <img
        src="../../../assets/img/modmed/Moderation in SPSS PROCESS.jpg"
        alt="Moderation in SPSS PROCESS"
      />

      <p>
        Danach führen wir eine Mediation mit Interesse für Statistik, besuchte
        Veranstaltungen und Punkte in der Statistikklausur durch
      </p>

      <img
        src="../../../assets/img/modmed/Moderation in SPSS PROCESS 2.jpg"
        alt="Moderation in SPSS PROCESS 2"
      />

      <p>
        Im nächsten Schritt definieren wir unser Modell. Einfache Mediation
        werden mit Modell 4 berechnet. Die abhängige Variable ist Punkte, die
        unabhängige- Interesse und der Mediator die besuchten Veranstaltungen.
        Zudem stellen wir 10.000 Bootstrapping Iterationen ein. Das heißt, dass
        10.000 Zufallsstichproben mit Zurücklegen, gezogen werden. Daraus werden
        die Parameter berechnet, die robust gegen Verletzungen sind. Gegen
        Heteroskedastizität benutzen wir die HC3 Anpassung des Standardfehlers.
      </p>
      <img
        src="../../../assets/img/modmed/Mediation in SPSS PROCESS Bootstrapping.jpg"
        alt="Mediation in SPSS PROCESS Bootstrapping"
      />

      <p>
        Wenn wir uns den Output näher anschauen, sehen wir, dass es sich um zwei
        multiple lineare Regressionen handelt. Wir starten mit der
        Interpretation des ersten Modells, welches Pfad A repräsentiert, also
        den Einfluss von Interesse auf die Zahl der besuchten Veranstaltungen.
      </p>
      <img
        src="../../../assets/img/modmed/Mediation in SPSS PROCESS Pfad A.jpg"
        alt="Mediation in SPSS PROCESS Pfad A"
      />

      <p>
        An dieser Stelle können wir sehen, dass unser Modell eindeutig
        signifikant wird (F(1,46)=91.270, p &lt;.001, R²=.584). Das heißt, dass
        58.4 % der Varianz der Klausurleistung durch die in das Modell
        aufgenommen Variablen erklärt werden können. Wenn wir die Effektstärke
        nach Cohen (1992) berechnen, zeigt sich ein sehr starker Effekt
        (f²=1.40).
      </p>

      <a routerLink="/statistische-tests/effektstärkenrechner"
        >Effektstärkenrechner
      </a>

      <p>
        Das zweite Modell prüft Pfad B und C gemeinsam, da die abhängige
        Variable dieselbe ist.
      </p>

      <img
        src="../../../assets/img/modmed/Mediation in SPSS PROCESS Pfad B C.jpg"
        alt="Mediation in SPSS PROCESS Pfad B C"
      />

      <p>
        Auch an dieser Stelle können wir sehen, dass unser Modell signifikant
        wird (F(2,45)=78.490, p &lt;.001, R²=.655). Das heißt, dass 65.5 % der
        Varianz der Klausurleistung durch die in das Modell aufgenommen
        Variablen erklärt werden können. Wenn wir die Effektstärke nach Cohen
        (1992) berechnen, zeigt sich ein sehr starker Effekt (f²=1.90).
      </p>

      <p>
        Das Interesse spiegelt jetzt hier den Pfad C, also den direkten Pfad
        wider. Die Veranstaltung Pfad B, also den zweiten Teil des indirekten
        Effekts. Signifikant ist jedoch nur Interesse (b=13.594, t=.4.842,
        p&lt;.001). Die Bootstrapping Konfidenzintervalle können das Nullmodell
        (b=0) ausschließen (CI 95 [7.940 19.248]).
      </p>

      <p>
        Die Anzahl der besuchten Veranstaltungen spiegelt den Pfad B wider, also
        den zweiten Teil des indirekten Pfads. Hier zeigt sich, dass die
        Bootstrapping Konfidenzintervalle jedoch nicht das Nullmodell (b=0)
        ausschließen können (CI 95 [-.863 4.470]).
      </p>

      <p>
        Zusammenfassend heißt unser Ergebnis also, dass die Klausurleistung
        scheinbar direkt vom Interesse für Statistik beeinflusst wird.
        Einerseits beeinflusst das Interesse auch die Anzahl der besuchten
        Veranstaltungen, jedoch ist der Pfad B nicht signifikant geworden. Die
        Veranstaltungen beeinflussen wiederum nicht die Klausurleistung, weshalb
        nicht von einer Mediation bzw. einem indirekten Effekt gesprochen werden
        kann.
      </p>

      <p>
        zur Überprüfung dieser Annahme betrachten wir die Modellzusammenfassung
      </p>

      <img
        src="../../../assets/img/modmed/Mediation in SPSS PROCESS Indirekt direkt.jpg"
        alt="Mediation in SPSS PROCESS Indirekt direkt"
      />

      <p>
        Hier bestätigt sich unser Verdacht. Einerseits haben wir einen totalen
        Effekt (Gesamteffekt) von Interesse auf Punkte, jedoch kommt dieser
        durch den direkten Pfad C zustande (CI 95[7.949 19.248]. Beim indirekten
        Pfad kann das Nullmodell nicht ausgeschlossen werden (CI 95[-1.295
        7.911]).
      </p>

      <p>
        wir können also nicht von einer Mediation sprechen, auch wenn wir einen
        Einfluss des Interesses auf die Klausurleistung die Teilnahme von
        Lehrveranstaltungen gefunden haben.
      </p>

      <div
        class="button"
        routerLink="/statistische-tests/testuebersicht/moderation-mediation"
      >
        <span>Zurück zur Übersicht</span>
      </div>
      <br />
      <p>
        Fragen, Probleme, Anmerkungen? Dann schreib uns einfach und wir helfen
        dir sehr gerne weiter.
      </p>

      <div class="button" routerLink="/kostenloses-erstgepraech">
        <span>Kostenloses Erstgespräch</span>
      </div>

      <p>Dein Team von Hanseatic-Statistics.</p>
    </main>
  </div>
</div>
