<!-- create a 5x7 grid of divs each with a unique identifier and text from 1 to x and a legend for the grid. The legend should be clickable and should highligh the item it refers to and remove the highlight from all other items -->
<div class="grid-container">
  <div class="grid-item" *ngFor="let item of items; let i = index">
    <div class="grid-item-content" [id]="item.id" (click)="highlight(item.id)">
      {{ item.text }}
    </div>
  </div>
</div>
<div class="legend">
  <div class="legend-item" *ngFor="let item of items; let i = index">
    <div
      class="legend-item-content"
      [id]="item.id"
      (click)="highlight(item.id)"
    >
      {{ item.text }}
    </div>
  </div>
</div>
