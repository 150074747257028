import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dsgvo',
  templateUrl: './dsgvo.component.html',
  styleUrls: ['./dsgvo.component.scss']
})
export class DsgvoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
