<div class="component-wrapper">
  <div class="content-wrapper">
    <main>
      <h1>Moderationsbeispiel in SPSS</h1>

      <p>
        Die einfachste Möglichkeit eine Moderation in SPSS durchzuführen ist es,
        das externe Tool SPSS PROCESS von Andrew Hays (2015) zu installieren.
      </p>

      <img
        src="../../../assets/img/modmed/Moderation in SPSS PROCESS.jpg"
        alt="Moderation in SPSS PROCESS"
      />

      <p>
        Danach führen wir eine Moderation mit gelernter Zeit, besuchte
        Veranstaltungen und Punkte in der Statistikklausur durch
      </p>
      <img
        src="../../../assets/img/modmed/Moderation in SPSS PROCESS 2.jpg"
        alt="Moderation in SPSS PROCESS 2"
      />

      <p>
        Im nächsten Schritt definieren wir unser Modell. Einfache Moderationen
        werden mit Modell 1 berechnet. Die abhängige Variable ist Punkte, die
        unabhängige- Zeit und der Moderator die besuchten Veranstaltungen. Zudem
        wird ein Syntax Code für die Visualisierung erzeugt und wir stellen
        10.000 Bootstrapping Iterationen ein. Das heißt, dass 10.000
        Zufallsstichproben mit Zurücklegen, gezogen werden. Daraus werden die
        Parameter berechnet, die robust gegen Verletzungen sind. Gegen
        Heteroskedastizität benutzen wir die HC3 Anpassung des Standardfehlers
        und wir mean-centern die Variablen
      </p>

      <img
        src="../../../assets/img/modmed/moderation in SPSS PROCESS Bootstrapping.jpg"
        alt="moderation in SPSS PROCESS Bootstrapping"
      />

      <p>
        Wenn wir uns den Output näher anschauen, sehen wir, dass es sich um eine
        multiple lineare Regression mit Interaktionsterm handelt
      </p>

      <img
        src="../../../assets/img/modmed/Moderation in SPSS interpretieren.jpg"
        alt="Moderation in SPSS interpretieren"
      />

      <p>
        An dieser Stelle können wir sehen, dass unser Modell eindeutig
        signifikant wird (F(3,44)=132.808, p &lt;.001, R²=.760). Das heißt, dass
        76.0 % der Varianz der Klausurleistung durch die in das Modell
        aufgenommen Variablen erklärt werden können. Wenn wir die Effektstärke
        nach Cohen (1992) berechnen, zeigt sich ein sehr starker Effekt
        (f²=3.17).
      </p>

      <div class="button" routerLink="/statistische-tests/effektstärkenrechner">
        <span>Effektstärkenrechner</span>
      </div>

      <div
        class="button"
        routerLink="/statistische-tests/testuebersicht/moderation-mediation"
      >
        <span>Zurück zur Übersicht</span>
      </div>
      <br />
      <p>
        Fragen, Probleme, Anmerkungen? Dann schreib uns einfach und wir helfen
        dir sehr gerne weiter.
      </p>

      <div class="button" routerLink="/kostenloses-erstgepraech">
        <span>Kostenloses Erstgespräch</span>
      </div>

      <p>Dein Team von Hanseatic-Statistics.</p>
    </main>
  </div>
</div>
