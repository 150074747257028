import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private http: HttpClient, private titleService: Title) { }

  arbeiten: number;

  ngOnInit(): void {
    this.titleService.setTitle("Datenanalyse & Statistik SPPSS R STATA");
    this.http.get<{_id: string, anzahl: number}>('https://desire-rest-api.de:3000/konrad/arbeiten').subscribe(res => {
      this.arbeiten = res[0].anzahl
    })
  }
}
