<main>
  <h1>Statistik Auswertung in STATA</h1>
  <img style="max-width: 100px;" src="../../../assets/img/stata-logo.png" alt="STATA LOGO">
  <p>
    <b>STATA</b> ist ein Datenanalyseprogramm welches hauptsächlich im wirtschaftswissenschaftlichen- (WiWi, VWL, BWL,
    Finance) und zum Teil im medizinischen / epidemiologischen Bereich eingesetzt wird. Gemeinsam haben diese
    Fachbereiche, dass es oftmals um Zeitreihenanalysen und zeitliche Vorhersagen geht. Dafür ist <b>STATA</b> bestens
    geeignet, da
    sich Panel- bzw. Mehrebenenanalysen relativ einfach umsetzen lassen.
  </p>
  <p>
    Auch hier kommt ähnlich wie in <b>SPSS®</b> eine benutzerfreundliche Oberfläche und die Möglichkeit der
    Syntaxprogrammierung zusammen.
  </p>
  Download <b>STATA</b>: <a href="https://download.stata.com/download/" rel="noopener noreferrer"
    target="_blank">https://download.stata.com/download/</a> <br>
  <img src="../../../assets/img/STATA Screenshots/Stata IDE.png" alt="Stata IDE">
</main>
<p>Brauchst Du Hilfe bei deiner Auswertung in <b>STATA</b> kannst Du uns jederzeit kontaktieren.</p>
<div class="button" routerLink="/kostenloses-erstgepraech"><span>Kostenloses Erstgespräch</span>
</div>
<p> Wir melden uns dann am selben Tag bei Dir.</p>
