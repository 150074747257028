<div class="component-wrapper erstgespraech-wrapper">
  <div class="content-wrapper">
    <div class="blogPost">
      <img class="blog-image" src="{{ blogPost.blogImage }}" alt="" />
      <div class="author-date">
        von Konrad Krahl - {{ blogPost.date }} - Hamburg
      </div>
      <h1>{{ blogPost.title }}</h1>
      <div class="blog-text" [innerHTML]="blogPost.text"></div>
      <br />
      <div class="blog-keywords">
        {{ blogPost.keywords }}
      </div>
    </div>
  </div>
</div>
