<div class="component-wrapper preise">
  <main>
    <div class="content-wrapper">
      <h2>Dein Coaching für deinen Erfolg</h2>
      <p>Kontaktiren Sie uns für ein unverbindliches Beratungsgespräch</p>
      <div class="button" routerLink="/kostenloses-erstgepraech">
        <span>Kostenloses Erstgespräch</span>
      </div>
    </div>
  </main>
</div>
