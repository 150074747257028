import { Component } from '@angular/core';

@Component({
  selector: 'app-kruskal',
  templateUrl: './kruskal.component.html',
  styleUrls: ['./kruskal.component.scss']
})
export class KruskalComponent {

}
