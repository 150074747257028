import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mod-r',
  templateUrl: './mod-r.component.html',
  styleUrls: ['./mod-r.component.scss'],
})
export class ModRComponent implements OnInit {
  ngOnInit(): void {
    window.scrollTo(0, 0);
  }
}
