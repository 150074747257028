import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mod-spss',
  templateUrl: './mod-spss.component.html',
  styleUrls: ['./mod-spss.component.scss'],
})
export class ModSpssComponent implements OnInit {
  ngOnInit(): void {
    window.scrollTo(0, 0);
  }
}
