<h1>Fragebogen Auswertung in R </h1>
<h2>Import der Daten</h2>
<p>Zuerst musst du die Daten importieren. Über RStudio geht es ganz leicht über <i> Import Dataset </i> im rechten oberen Fenster. 
    Du hast die Möglichkeit Excel Daten direkt oder als .csv zu importieren. Auch SPSS oder STATA Datentypen sind einfach imortierbar. 
    Alternativ kannst du das ganze auch einfach mit diesen Befehlen in der Konsole laden</p>

<iframe width='100%' height='300'   
src='https://rdrr.io/snippets/embed/?code=%23%23Excel%20file%0A%0Alibrary(readxl)%0Atest%3C-read_excel(%22C%3A%2FUsers%2Fkonrad%20krahl%2Ftest.xlsx%22)%0A%0A%23%23CSV%20file%0A%0Aread.csv(%22C%3A%2FUsers%2Fkonrad%20krahl%2Ftest.xlsx%22)%0A%0A%23%23SPSS%0A%0Alibrary(haven)%0AforR%20%3C-%20read_sav(%22C%3A%2FUsers%2Fkonrad%20krahl%2Ftest.xlsx%22)%0AView(forR)%0A%0A%23%23STATA%0A%0Alibrary(haven)%0Adataset%20%3C-%20read_stata(%22C%3A%2FUsers%2Fkonrad%20krahl%2Ftest.xlsx%22)%0A' frameborder='0'>
</iframe>

<h2>Datenaufbereitung</h2>
<p>In RStudio stellt die Datenanasicht nun eine einfache zweidimensionale Übersicht deiner Daten dar. Zeilen sind Versuchspersonen und Spalten Variablen.</p>

<h4>Überflüssige Variablen Löschen</h4>

<p>Datenanalyse lebt von Übersichtlichkeit. Lösche also was du nicht brauchst, z.B. Browserdaten, Time Tracker, Indexvariablen etc..</p>
<h4>Versuchspersonen filtern</h4>
<p>Lösche die Versuchspersonen, die den Fragebogen abgebrochen oder gar nicht ausgefüllt haben.</p>

<iframe width='100%' height='300' 
src="https://rdrr.io/snippets/embed/?code=%23%23Daten%20erstellen%0A%0Adata%3C-data.frame(c(1%2C2%2C3%2C4%2C5%2C6)%2Cc(34%2C23%2C54%2C65%2C23%2C54)%2C%20c(3.4%2CNA%2C2.3%2C1.2%2C3.4%2C6.5))%0Acolnames(data)%3C-c('Versuchsperon'%2C'Alter'%2C'Item%201')%0A%0A%23%23L%C3%B6sche%20alle%20Beobachtungen%20der%203.%20Spalte%0A%0Adata%5B%2C3%5D%3C-NULL%0A%0A%23%23L%C3%B6sche%20Versuchsperon%201%2C%20alle%20Variablen%0A%0Adata%5B-c(1)%2C%5D" frameborder='0'>
</iframe>

<h4>Variablen labeln und beschriften</h4>

In R kannst du die Spalten (Variablen) ganz einfach umbenennen. Zudem kannst du Faktoren labeln, damit deine Tabellen und Grafiken aussagekräftiger sind <br><br>

<iframe width='100%' height='300' 
src="https://rdrr.io/snippets/embed/?code=%23%23Daten%20erstellen%0A%0Adata%3C-data.frame(c(1%2C2%2C3%2C4%2C5%2C6)%2Cc(34%2C23%2C54%2C65%2C23%2C54)%2C%20c(1%2C2%2C1%2C2%2C1%2C2))%0A%0A%23%23So%20sehen%20Grafiken%20und%20Tabellen%20ungelabelt%20aus%0A%0Atable(data%5B%2C3%5D)%0Abarplot(table(data%5B%2C3%5D)%2C%20main%3Dcolnames(data)%5B3%5D)%0A%0A%23%23Variablen%20benennen%0A%0Acolnames(data)%3C-c('Versuchsperon'%2C'Alter'%2C'Geschlecht')%0A%0A%23%23Variable%20labeln%0A%0Adata%5B%2C3%5D%3C-factor(data%5B%2C3%5D%2C%20levels%3Dc(1%2C2)%2C%20labels%3Dc('Weiblich'%2C'Maennlich'))%0A%0A%23%23So%20sehen%20Grafiken%20und%20Tabellen%20ungelabelt%20aus%0A%0Atable(data%5B%2C3%5D)%0Abarplot(table(data%5B%2C3%5D)%2C%20main%3Dcolnames(data)%5B3%5D)%0A%0A" frameborder='0'>
</iframe>
<h4>Fehlende Werte löschen oder ersetzen</h4>
Was machst du mit einzelnen fehlenden Werten? Man kann hier die gesamte Versuchsperson entfernen oder fehlende Werte ersetzten, z.B. durch eine Mittelwert Imputation. <br><br>
<h4>Skalenniveaus anpassen</h4>
Skalenniveaus stellen ein sehr wichtiges Thema dar. Von Ihnen hängt ab, was du mit deinen Daten machen kannst. Bestimme also, ob es nominale, ordinale oder metrische Daten sind. In R gibt es Faktoren, Geordnete Faktoren und metrische Daten. Für viele Funktionen ist das richtige Skalenniveau essenziell! <br><br>

<p><u style="cursor: pointer" routerLink="/skalenniveau">Zu den Skalenniveaus </u></p>

<iframe width='100%' height='300' 
src="https://rdrr.io/snippets/embed/?code=%23%23Daten%20erstellen%0A%0Adata%3C-data.frame(c(1%2C2%2C3%2C4%2C5%2C6)%2Cc(1%2C2%2C3%2C1%2C2%2C3)%2C%20c(1%2C2%2C1%2C2%2C1%2C2)%2C%20c(43%2C23%2C43%2C54%2C34%2C65))%0Acolnames(data)%3C-c('Versuchsperon'%2C'Einkommen'%2C'Geschlecht'%2C%20'Alter')%0A%0A%23%23Variable%20labeln%20nominal%0A%0Adata%5B%2C3%5D%3C-factor(data%5B%2C3%5D%2C%20levels%3Dc(1%2C2)%2C%20labels%3Dc('Weiblich'%2C'Maennlich'))%0A%0A%23%23Variable%20labeln%20metrisch%2Fnumerisch%0A%0Adata%5B%2C4%5D%3C-as.numeric(data%5B%2C4%5D)" frameborder='0'></iframe>

<h4>
Reliabilitätsanalyse</h4>
<p>Üblicherweise werden Konstrukte durch mehrere Items operationalisiert. Damit dies jedoch funktioniert, solltest du einmal die Reliabilität überprüfen. 
    Hier kannst du Cronbachs-Alpha oder McDonalds-Omega bestimmen.</p>
<h4>Skalen erstellen</h4>
<p>Ist deine Skala reliabel, kannst du die Items zu einem Score zusammenfassen. Dabei wird häufig der Mittelwert gewählt.</p>

<h2>Deskriptive Statistik</h2>
<p>Im ersten Schritt musst du deine Daten nun einmal beschrieben. 
    Hier spielt das Skalenniveau wieder eine entscheidende Rolle, denn von diesem ist der Informationsgehalt deiner Daten abhängig. 
    Dieser entscheidet, was du mit den Daten machen kannst. 
    Einen Mittelwert der Variable, die das Geschlecht einer Person angibt, zu berechnen, ergibt keinen Sinn. 
    Genau so wenig wie einfach Häufigkeiten einer Variablen anzugeben, die das Alter in Jahren misst. 
    Am besten gehst du wie folgt vor:</p>
<b>Häufigkeiten für nominale und ordinale Daten<br>
Mediane für ordinale Daten<br>
Statistische Kennwerte und Test auf Normalverteilung für metrische Daten</b><br><br>

<h2>Inferenzstatistik</h2>
Nun folgen die finalen Tests dein
<b>Den richtigen Test finden.</b>
Hier spielen nun deine Hypothesen und die Skalenniveaus eine entscheidende Rolle. In unserer Testübersicht kannst du dir einen Überblick verschaffen. <br><br>
<u style="cursor: pointer" routerLink="/statistische-tests/testuebersicht">Zur Testübersicht </u>
<h4>Im Hinblick auf Hypothesen durchführen</h4>
<p>Hier kannst du dir einen Überblick über die verschiedenen Arten der Hypothesentests verschaffen, wie Signifikanzwerte, Effektstärke oder Bayes-Statistiken.</p>
<u style="cursor: pointer" routerLink="/statistische-tests/hypothesen-tests">Zu den Hypothesentests </u>
