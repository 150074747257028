<div class="component-wrapper">
  <div class="content-wrapper">
    <main>
      <h1>Ein-/Mehrfaktorielle Varianzanalyse (ANOVA)</h1>

      <p>
        Die <b> ANOVA (Analysis of Variance) </b> ist ein relativ einfaches
        Verfahren zum Vergleich von mehr als 2 Gruppen hinsichtlich eines
        metrischen Merkmals. Hat man eine unabhängige Gruppenvariable, ist es
        eine einfaktorielle ANOVA. Hat man mehrere Gruppenvariable, ist es eine
        mehrfaktorielle ANOVA. Hat man hingegen noch Kontrollvariablen im Modell
        (nominal oder metrisch) ist es eine Kovarianzanalye oder ANCOVA
        (Analysis of Covariance). Bei mehreren abhängigen Variablen spricht man
        hingegen von einer MANOVA (Multivariate Analysis of Variance). Die
        folgende Darstellung soll bei der Entscheidung helfen
      </p>

      <img
        src="../../../assets/img/anova-one-way-test/ANOVA MANOVA ANCOVA MANCOVA.png"
        alt="ANOVA MANOVA ANCOVA MANCOVA"
      />

      <p>In unserer Abbildung haben wir also eine mehrfaktorielle ANCOVA.</p>

      <h2>Typ und Anwendung</h2>

      <p>
        <b>Unterschiedsanalyse</b> Gibt es einen Unterschied der Mittelwerte
        (arithmetische Mittel) zwischen mehr als zwei unabhängigen Gruppen?
        Wichtig ist zudem, dass es sich hier um eine Querschnittsanalyse
        handelt. Während hier zu einem Zeitpunkt mehr als zwei Gruppen
        verglichen werden, vergleicht die mANOVA für abhängige Stichproben eine
        Population zu mehr als zwei Zeitpunkten (Längsschnitt).
      </p>

      <h2>Beispiele</h2>
      <p>
        Unterscheiden sich Frauen, nicht binäre Personen und Männer hinsichtlich
        ihrer Körpergröße?
      </p>
      <p>
        Unterschieden sich drei Therapieformen hinsichtlich der Überlebensdauer
        in Monaten?
      </p>
      <p>
        Unterschieden sich drei Materialien hinsichtlich ihrer Belastbarkeit in
        N/mm²?
      </p>
      <p>
        Unterschieden sich drei Marketing-Strategien hinsichtlich ihrer Umsätze
        in €?
      </p>

      <h2>Voraussetzungen</h2>
      <p>Die abhängige(n) Variable(n) muss/müssen metrisch sein</p>
      <p>Die unabhängige(n) Variable(n) muss/müssen nominal sein</p>
      <p>
        Die Fehlervarianzen dürfen sich zwischen den Gruppen nicht signifikant
        unterscheiden (Levene-Test)
      </p>
      <p>
        Die abhängige(n) Variable(n) muss/müssen normalverteilt sein. Hier gibt
        es sicherlich mehrere Ansichten. Dem zentralen Grenzwertsatz
        entsprechend kann eine Verletzung dieser Voraussetzung bei Gruppengrößen
        >30 toleriert werden. Davon ist jedoch abzuraten, besonders bei sehr
        schiefen Verteilungen und den damit verbundenen Ausreißern.
      </p>
      <p>
        Bei Verletzungen der Normalverteilung, besonders bei starken Ausreißern
        oder sehr schiefen Verteilungen bietet sich immer auch der
        Kruskal-Wallis-Test an.
      </p>

      <h2>Beispiel SPSS</h2>
      <p>
        Gegeben sei ein einfacher Datensatz. 30 Studierende wurden hinsichtlich
        ihrer Statistikklausur befragt. Die Angabe erfolgte dabei in Prozent
        (Eine Schulnote stellt ein ordinales Skalenniveau dar und kann an dieser
        Stelle nicht verwendet werden!). Als unabhängige Variablen, die die
        Leistung beeinflusst haben, vermuten wir das Studienfach.
      </p>
      <img
        src="../../../assets/img/anova-one-way-test/ANOVA Daten.png"
        alt="ANOVA Daten"
      />

      <p>
        Über Analysieren->Allgemeines Lineares Modell->uni-/multivariat können
        wir unser Modell bauen. Wichtig sind an dieser Stelle auch die Tests der
        Voraussetzungen. Der Levene-Test ist in den Optionen integriert, über
        Analysieren ->Deskriptive Statistik -> Explorative Datenanalyse ->
        Diagramme -> Normalverteilungsdiagramme mit Test können wir den
        Shapiro-Wilk-Test auf Normalverteilung benutzen. Wichtig ist zudem der
        Post-Hoc Test, dieser vergleicht die Gruppen untereinander.
      </p>
      <p>
        Optional können wir uns das Modell auch selbst in der Syntax
        programmieren:
      </p>

      <span style="color: rgb(0, 132, 255)"
        >UNIANOVA <span style="color: rgb(0, 0, 0)">Resultat_in_Prozent</span>
        <span style="color: rgb(255, 0, 0)">BY </span
        ><span style="color: rgb(0, 0, 0)">Studienfach</span>
      </span>
      <span style="color: rgb(0, 128, 28)">/METHOD</span>=<span
        style="color: rgb(255, 0, 0)"
        >SSTYPE</span
      >(3)<br />
      <span style="color: rgb(0, 128, 28)">/INTERCEPT</span>=<span
        style="color: rgb(255, 0, 0)"
        >INCLUDE</span
      ><br />
      <span style="color: rgb(0, 128, 28)">/POSTHOC</span>=Studienfach(<span
        style="color: rgb(255, 0, 0)"
        >BONFERRONI</span
      >) <br />
      <span style="color: rgb(0, 128, 28)">/PRINT</span
      ><span style="color: rgb(255, 0, 0)"> ETASQ DESCRIPTIVE HOMOGENEITY </span
      ><br />
      <span style="color: rgb(0, 128, 28)">/CRITERIA</span>=<span
        style="color: rgb(255, 0, 0)"
        >ALPHA</span
      >(.05) <br />
      <span style="color: rgb(0, 128, 28)">/DESIGN</span>=Studienfach.

      <p>Als Resultat zeigt sich folgendes Ergebnis:</p>

      <img
        src="../../../assets/img/anova-one-way-test/NV Test.png"
        alt="NV Test"
      />

      <p>
        Wir können sehen, dass die Perfomance in der Grundgesamtheit
        normalverteilt ist.
      </p>

      <img
        src="../../../assets/img/anova-one-way-test/ANOVA Deskriptive Statistik.png"
        alt="ANOVA deskriptive Statistik"
      />

      <p>
        An dieser Stelle sehen wir bereits Mittelwertsunterschiede zwischen
        Gruppen, die PsychologInnen scheinen hier am besten abgeschnitten zu
        haben. Zudem zeigt der Levene Test, dass es keine signifikanten
        Unterschiede hinsichtlich der Fehlervarianzen zu geben scheint.
      </p>

      <img
        src="../../../assets/img/anova-one-way-test/ANOVA Signifikanz.png"
        alt="ANOVA Signifikanz"
      />

      <p>
        Im Test der Zwischensubjekteffekte zeigt sich ein signifikanter
        Unterschied zwischen den Gruppen, das partielle ETA² weist auf einen
        starken Effekt hin. Die Effektstärke nach Cohen (1992) kann hier
        berechnet werden.
      </p>

      <img
        src="../../../assets/img/anova-one-way-test/Post hoc Test.png"
        alt="Post hoc Test"
      />
      <h2>Beispiel R</h2>
      <p>
        Die Funktion „aov“ ist in R sehr einfach aufgebaut. Nachdem wir unseren
        Datensatz eingelesen haben, können wir hier ganz einfach unseren Test
        bauen. Wenn wir den Datensatz df in der Funktion definiert haben, können
        wir an erster Stelle die abhängige Variable definieren und dann die
        Gruppe. Die „aov“ Funktion adjustiert die Freiheitsgrade zudem
        automatisch, sollten sich die Varianzen unterscheiden. Die Voraussetzung
        der Varianzhomogenität wird also durch die Welch-Anpassung bereits
        erfüllt. Mithilfe von „levene.test“ können wir selbige dennoch prüfen.
        Außerdem können wir mit „shapiro.test“ die Voraussetzung der
        Normalverteilung prüfen und ein Histogramm plotten.
      </p>

      <iframe
        width="100%"
        height="300"
        src="https://rdrr.io/snippets/embed/?code=%23Datensatz%20erstellen%0A%23%23Variablen%20erstellen%0A%0AResultat_in_Prozent%3C-c(67%2C80%2C67%2C81%2C99%2C78%2C98%2C67%2C66%2C76%2C56%2C45%2C76%2C87%2C67%2C45%2C65%2C76%2C78%2C65%2C67%2C65%2C45%2C76%2C87%2C45%2C56%2C45%2C39%2C45)%0AStudiengang%3C-as.factor(c(rep(1%2C10)%2Crep(2%2C10)%2Crep(3%2C10)))%0A%0A%0A%0A%0A%23%23Zu%20einem%20Datensatz%20zusammenf%C3%BChren%0A%0Adf%3C-data.frame(Resultat_in_Prozent%2CStudiengang)%0A%0A%23%23%23TTest%20%0A%0Amodel%3C-aov(Resultat_in_Prozent~Studiengang%2C%20data%3Ddf)%0Asummary(model)%0A%0A%23%23%23Post%20Hoc%20Test%20ANOVA%0A%0ADescTools%3A%3APostHocTest(model%2C%20method%3Dc('bonferroni'))%0A%0A%23%23Voraussetzungen%0A%23%23%23Levene%20Test%0A%0Alawstat%3A%3Alevene.test(df%24Resultat_in_Prozent%2C%20df%24Studiengang%2C%20location%3Dc(%22median%22%2C%20%22mean%22%2C%20%22trim.mean%22))%0A%0A%23%23%23Shapiro%20Wilk%20Test%0A%0Ashapiro.test(df%24Resultat_in_Prozent)%0A%0A%23%23%23Histo%0A%0Alibrary(ggplot2)%0A%0Aggplot(df%2C%20aes(x%3DResultat_in_Prozent))%20%2B%20%0A%20%20%20%20%20%20%20%20%20%20geom_histogram(binwidth%3D10%2C%20fill%3D'%237aabd5'%2Ccolor%3D'white')%0A%0Aggplot(df%2C%20aes(x%3DStudiengang%2C%20y%3DResultat_in_Prozent))%2B%0A%20%20%20%20%20geom_boxplot(fill%3D'%237aabd5'%2Ccolor%3D'white')%2B%0A%20%20%20%20%20theme(%20legend.position%20%3D%20%22none%22%20)%2B%0A%20%20%20%20%20xlab('Studiengang')"
        frameborder="0"
      ></iframe>

      <h2>Interpretation</h2>

      <p>
        Die Interpretation der einfaktoriellen ANOVA ist relativ einfach. Wir
        können uns zuerst die Voraussetzungen anschauen. Hier zeigt sich, dass
        sowohl die Normalverteilung (W(30)=.942, p=.104; s. Abb.), wie auch die
        Varianzhomogenität (F(2,27)=.711, p=.500) gegeben sind. Der Levene-Test
        und der Shapiro-Wilk-Test dürfen also nicht signifikant werden. Bei der
        Interpretation der ANOVA zeigen sich signifikanten Unterschiede
        (F(2,27)=5.434, p=.010, ETA²=.287). Nach Cohen (1992) kann hier von
        einem starken Effekt gesprochen werden (f=.630). Bei der Überprüfung des
        Post-Hoc-Tests zeigen sich nur signifikante Unterschiede zwischen den
        Studierenden der Psychologie und der BWL (p=.008). Die Studierenden der
        Psychologie schneiden dabei im Schnitt 20.9 Prozentpunkte besser ab.
      </p>

      <p>
        Fragen, Probleme, Anmerkungen? Dann schreib und einfach und wir helfen
        dir sehr gerne weiter.
      </p>

      <div class="button" routerLink="/kostenloses-erstgepraech">
        <span>Kostenloses Erstgespräch</span>
      </div>
      <p>Dein Team von Hanseatic-Statistics</p>

      <p class="quellen">
        Quellen: Bortz, J. & Döring, N. (2016). Forschungsmethoden und
        Evaluation für Human- und Sozialwissenschaftler. 5. Auflage. Heidelberg:
        Springer. Backhaus, K., Erichson, B., Plinke, W. & Weiber, R. (2016).
        Multivariate Analysemethoden. Eine anwendungsorientierte Einführung. 14.
        Auflage. Berlin: Springer. Hirsig, R. (2006a). Statistische Methoden in
        den Sozialwissenschaften. Eine Einführung im Hinblick auf
        computergestützte Datenanalysen mit SPSS. Band I. 5. Auflage. Zürich:
        Seismo.
      </p>
    </main>
  </div>
</div>
