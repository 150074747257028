<div class="leistungen-wrapper component-wrapper">
  <main>
    <div class="content-wrapper">
      <h1>Wir helfen Dir bei deinem Statistikproblem</h1>
      <h2>Statistik Auswertung</h2>
      <p>Wir werten deine Daten für dich aus! Kontaktiere uns und wir machen dir ein faires Angebot</p>
      <h2>Statistik Beratung</h2>
      <p>
        Wir unterstützen Dich individuell bei deinem Projekt. Egal ob Haus-,
        Bachelor-/Masterarbeit oder bei deiner Promotion.
      </p>
      <p>
        Im Mittelpunkt unserer Beratung steht die uni- & multivariate
        Datenanalyse und statistische Auswertung mit modernen Analyseprogrammen
        wie SPSS, STATA, R, MatLab, Python, Jamovi, SmartPLS, JASP, PSPP und
        G*Power.
      </p>
    </div>
    <div class="zitat">
      <div class="zitat-text">
        <i
          >"Hi, ich habe heute das Ergebnis meiner Arbeit bekommen: eine 1,1!
          Danke nochmal für eure Hilfe!!!"</i
        >
        <div class="name">- Maria</div>
      </div>
    </div>
    <div class="content-wrapper">
      <p>
        Gemeinsam mit deinem persönlichen Betreuer besprichst du die
        Umsetzbarkeit deiner Hypothesen und konzipierst deren
        Operationalisierung. Darüber hinaus protokolliert ihr eine Syntax/Code
        und setzt die Datendarstellung und Analyse schriftlich um.
      </p>
      <p>
        Gerne helfen wir auch bei der Konzeption deines Methoden-, Ergebnis und
        Diskussionsteils.
      </p>
      <h2>Statistik Nachhilfe</h2>
      <p>
        Du brauchst Hilfe bei der Prüfungsvorbereitung?<br />
        Wir arbeiten mit Dir zusammen deine Schwächen auf und konzentrieren uns
        dabei auf die prüfungstypischen Problemstellungen. Im Mittelpunkt stehen
        dabei deskriptive- und inferenzstatistische (uni- und multivariate)
        Verfahren, sowie allgemeine Methodenschwerpunkte.
      </p>
      <p>
        „Wir bieten Nachhilfe auch bei der Auswertung von Daten in SPSS, R,
        STATA, JASP, JAMOVI, Python und Excel an. Kontaktiere uns hierzu
        einfach.“
      </p>

      <h2>Einfach und unkompliziert</h2>
      <p>
        Sag uns einfach wo der Schuh drückt und wir helfen Dir die Leistung zu
        erzielen die du Dir wünschst.
      </p>
      <h2>Wo wir dir helfen können</h2>
      <p>
        Grundsätzlich können wir ortsunabhängig helfen, da unsere Dienstleistung
        online stattfindet. Anfragen bekommen wir in der Regel aus allen
        Städten, die über eine Fachhochule und/oder eine Universität verfügen.
        Dazu gehören:
      </p>
      <p>
        Aachen Augsburg Bamberg Bayreuth Berlin Bielefeld Bochum Bonn
        Braunschweig Bremen Chemnitz Clausthal Cottbus Darmstadt Dortmund
        Dresden Düsseldorf Duisburg Erfurt Essen Flensburg Frankfurt-am-Main
        Frankfurt-an-der-Oder Freiberg (S) Freiberg (BW) Freiburg
        Friedrichshafen Fulda Gießen Göttingen Greifswald Hagen Halle Hamburg
        Hannover Heidelberg Hildesheim Ilmenau Ingolstadt Eichstätt Jena
        Kaiserslautern Karlsruhe Kassel Kiel Köln Koblenz-Landau Konstanz
        Leipzig Lübeck Lüneburg Magdeburg Mainz Mannheim Marburg München Münster
        Nürnberg Oldenburg Osnabrück Paderborn Passau Potsdam Regensburg Rostock
        Saarbrücken Siegen Speyer Stendal Stuttgart Trier Tübingen Ulm Weimar
        Wiesbaden Wuppertal Würzburg
      </p>
      <div class="button" routerLink="/kostenloses-erstgepraech">
        <span>Kostenloses Erstgespräch</span>
      </div>
    </div>
  </main>
</div>
