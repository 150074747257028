import { Component } from '@angular/core';
import { GaService } from './ga.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'hanseatic';

  constructor(private googleAnalyticsService: GaService) {}

  ngOnInit() {}
}
