<h1>Fragebogen Auswertung in JASP</h1>

<h2>Import der Daten</h2>

<p>
  Zuerst musst du die Daten importieren. Am besten liegen sie in einem Excel
  File als .csv Format vor. Alternativ können auch .sav Daten aus SPSS
  ausgelesen werden.
</p>

<img
  src="../../../assets/img/JASP Screenshot/fragebogen/Daten einlesen JASP.png"
  alt="Daten einlesen JASP"
/>

<h2>Datenaufbereitung</h2>
<p>
  Die Aufbereitung der Daten startet in der Regel mit dem Löschen von
  redundanten Variablen und Fällen. Leider kann man in JASP nicht ohne weiteres
  Spalten und Zeilen löschen, daher sollte man dies vorab in Excel tun.
</p>

<img
  src="../../../assets/img/JASP Screenshot/fragebogen/Variablen löschen JASP.png"
  alt="Variablen löschen JASP"
/>

<p>Die Datenmatrix in JASP sieht dann so aus.</p>

<img
  src="../../../assets/img/JASP Screenshot/fragebogen/Daten JASP.png"
  alt="Daten JASPg"
/>

<h2>Variablen labeln und beschriften</h2>
<p>
  Wie in anderen Programmen kann man auch in JASP die Kopfzeile der Datenmatrix
  aktivieren und den Werten Label zuteilen.
</p>

<img
  src="../../../assets/img/JASP Screenshot/fragebogen/Werte beschriften JASP.png"
  alt="Werte beschriften JASP"
/>

<h2>Skalenniveaus anpassen</h2>
<p>
  Skalenniveaus stellen ein sehr wichtiges Thema dar. Von Ihnen hängt ab, was du
  mit deinen Daten machen kannst. Bestimme also, ob es nominale, ordinale oder
  metrische Daten sind.

  <img
    src="../../../assets/img/JASP Screenshot/fragebogen/Skalenniveau JASP.png"
    alt="Skalenniveau JASP"
  />
</p>

<span routerLink="/skalenniveau" style="color: blue; cursor: pointer"
  ><u>Skalenniveau</u></span
>

<h3>Reliabilitätsanalyse</h3>
<p>
  Üblicherweise werden Konstrukte durch mehrere Items operationalisiert. Damit
  dies jedoch funktioniert, solltest du einmal die Reliabilität überprüfen. Hier
  kannst du Cronbachs-Alpha oder McDonalds-Omega bestimmen.
</p>

<h3>Skalen erstellen</h3>
<p>
  Ist deine Skala reliabel, kannst du die Items zu einem Score zusammenfassen.
  Dabei wird häufig der Mittelwert gewählt.

  <img
    src="../../../assets/img/JASP Screenshot/fragebogen/Skalenniveau JASP.png"
    alt="Skalenniveau JASP"
  />
</p>

<h2>Deskriptive Statistik</h2>
<p>
  Im ersten Schritt musst du deine Daten nun einmal beschrieben. Hier spielt das
  Skalenniveau wieder eine entscheidende Rolle, denn von diesem ist der
  Informationsgehalt deiner Daten abhängig. Dieser entscheidet, was du mit den
  Daten machen kannst. Einen Mittelwert der Variable, die das Geschlecht einer
  Person angibt, zu berechnen, ergibt keinen Sinn. Genau so wenig wie einfach
  Häufigkeiten einer Variablen anzugeben, die das Alter in Jahren misst. Am
  besten gehst du wie folgt vor:
</p>

<b
  >Häufigkeiten für nominale und ordinale Daten Mediane für ordinale Daten
  Statistische Kennwerte und Test auf Normalverteilung für metrische Daten</b
>

<h2>Inferenzstatistik</h2>
<p>Nun folgen die finalen Tests deiner Hypothesen.</p>
<h3>Den richtigen Test finden.</h3>
<p>
  Hier spielen nun deine Hypothesen und die Skalenniveaus eine entscheidende
  Rolle. In unserer Testübersicht kannst du dir einen Überblick verschaffen.
</p>

<span
  routerLink="/statistische-tests/testuebersicht"
  style="color: blue; cursor: pointer"
  ><u>Zur Testübersicht</u></span
>

<h3>Im Hinblick auf Hypothesen durchführen</h3>
<p>
  Hier kannst du dir einen Überblick über die verschiedenen Arten der
  Hypothesentests verschaffen, wie Signifikanzwerte, Effektstärke oder
  Bayes-Statistiken.
</p>

<span
  routerLink="/statistische-tests/hypothesen-tests"
  style="color: blue; cursor: pointer"
  ><u>Zu den Hypothesentests</u></span
>
