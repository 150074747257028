<div class="header-wrapper component-wrapper">
  <div class="header">
    <div class="headerbg">
      <img
        src="assets/img/header-bg.png"
        alt="Statistic background"
        class="header-bg"
        loading="lazy"
      />
      <img
        src="assets/img/header-bg-mobile.png"
        alt="Statistic background for mobile"
        class="header-bg-mobile"
        loading="lazy"
      />
    </div>
    <div class="logo">
      <img src="assets/img/logo_old.png" alt="Hanseatic logo" loading="lazy" />
    </div>
  </div>
  <div class="ham">
    <button
      class="hamburger hamburger--collapse"
      [ngClass]="{ 'is-active': navActive }"
      (click)="toggleNav()"
      aria-label="Toggle navigation"
    >
      <span class="hamburger-box">
        <span class="hamburger-inner"></span>
      </span>
    </button>
  </div>
  <nav
    class="navigation"
    [ngClass]="{ 'nav-active': navActive }"
    (click)="reload()"
    aria-label="Main navigation"
  >
    <div class="logo-container">
      <img
        routerLink="/"
        class="nav-logo"
        src="assets/img/logo-invert_old.png"
        alt="Hanseatic logo"
        loading="lazy"
      />
    </div>
    <ul class="nav-list" *ngIf="corporate && websiteEntered">
      <li>
        <a href="/business" aria-label="Datenanalyse für Unternehmen"
          >Datenanalyse für Unternehmen</a
        >
      </li>
      <li>
        <a href="/business/kontakt" aria-label="Kontakt">Kontakt</a>
      </li>
    </ul>
    <ul class="nav-list" *ngIf="!corporate && websiteEntered">
      <li>
        <a
          routerLinkActive="active-link"
          routerLink="/statistik-hilfe"
          aria-label="Statistik Hilfe"
          >Statistik Hilfe</a
        >
      </li>
      <li class="main-link" style="position: relative">
        <a aria-label="Statistik Auswertung / Datenanalyse">
          <span
            routerLinkActive="active-link"
            [routerLinkActiveOptions]="{ exact: false }"
            routerLink="/statistik-auswertung-datenanalyse/"
            >Statistik Auswertung / Datenanalyse</span
          >
          <ul class="dropdown">
            <li *ngFor="let tool of ['SPSS', 'R', 'JAMOVI', 'STATA', 'PYTHON', 'JASP', 'MATLAB', 'SmartPLS']">
              <a
                routerLinkActive="active-link"
                [routerLink]="'/statistik-auswertung-datenanalyse/' + tool.toLowerCase() + '-auswertung'"
                >{{ tool }}</a
              >
            </li>
          </ul>
        </a>
      </li>
      <li class="main-link" style="position: relative">
        <a aria-label="Fragebogen Auswertung">
          <span
            routerLinkActive="active-link"
            [routerLinkActiveOptions]="{ exact: false }"
            routerLink="/fragebogen-auswertung/"
            >Fragebogen Auswertung</span
          >
          <ul class="dropdown">
            <li *ngFor="let tool of ['SPSS', 'R', 'JASP']">
              <a
                routerLinkActive="active-link"
                [routerLink]="'/fragebogen-auswertung/fragebogen-auswertung-' + tool.toLowerCase()"
                >Fragebogen Auswertung in {{ tool }}</a
              >
            </li>
          </ul>
        </a>
      </li>
      <li class="main-link">
        <a aria-label="Statistische Tests">
          <span
            routerLinkActive="active-link"
            [routerLinkActiveOptions]="{ exact: true }"
            routerLink="/statistische-tests/testuebersicht"
            >Statistische Tests</span
          >
          <ul class="dropdown">
            <li>
              <a
                routerLinkActive="active-link"
                routerLink="/statistische-tests/testuebersicht"
                >Testübersicht</a
              >
            </li>
            <li class="secondary-link">
              <a>
                <span
                  routerLink="/statistische-tests/hypothesen-tests"
                  >Hypothesen Testen</span
                >
                <ul class="sub-dropdown">
                  <li *ngFor="let test of ['p-wert', 'effektstaerken', 'bayes-faktor']">
                    <a
                      routerLinkActive="active-link"
                      [routerLink]="'/statistische-tests/hypothesen-tests'"
                      [fragment]="test"
                      >{{ test | titlecase }}</a
                    >
                  </li>
                </ul>
              </a>
            </li>
            <li>
              <a routerLinkActive="active-link" routerLink="/skalenniveau"
                >Skalenniveau</a
              >
            </li>
          </ul>
        </a>
      </li>
      <li routerLinkActive="active-link" routerLink="/kostenloses-erstgepraech">
        <a aria-label="Kostenloses Erstgespräch">Kostenloses Erstgespräch</a>
      </li>
      <li routerLinkActive="active-link" routerLink="/qualifikationen">
        <a aria-label="Qualifikationen">Qualifikationen</a>
      </li>
      <li routerLinkActive="active-link" routerLink="/referenzen">
        <a aria-label="Referenzen">Referenzen</a>
      </li>
      <li routerLinkActive="active-link" routerLink="/preise">
        <a aria-label="Preise">Preise</a>
      </li>
      <li>
        <a
          [routerLinkActiveOptions]="{ exact: true }"
          routerLinkActive="active-link"
          routerLink="/blog"
          aria-label="Blog"
          >Blog</a
        >
      </li>
    </ul>
  </nav>
</div>