<div class="component-wrapper erstgespraech-wrapper">
  <div class="content-wrapper">
    <main>
      <div class="youtube-wrapper">
        <iframe
          *ngIf="allowCookies"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/CV7eBkmtR64"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <h4 *ngIf="!allowCookies">Youtube Einbindung</h4>
        <div *ngIf="!allowCookies" class="youtube-denied">
          Hier solltest du eigentlich ein YouTube Video zu sehen sein. Da wir aber deine Privatsphäre
          respektieren, binden wir es aufgrund deiner Cookie-Entscheidung nicht
          ein. Falls du dich doch dafür entscheiden solltest, akzeptiere unten
          einfach unsere Cookie-Richtlinie.
        </div>
      </div>
      <div class="entry">
        <div class="entry-button" routerLink="/home" (click)="enterWebsite()">
          <span>Für Privatpersonen</span>
        </div>
        <div
          class="entry-button"
          routerLink="/business"
          (click)="enterWebsite()"
        >
          <span>Für Unternehmen</span>
        </div>
      </div>
      <hr />
      <h1>Kontakt zu uns:</h1>
      <div class="contact-form">
        <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label for="name">Name / Firma<span>*</span></label>
            <input
              [ngClass]="{
                'invalid-input':
                  contactForm.controls.name.touched &&
                  !contactForm.controls.name.valid
              }"
              type="text"
              name="name"
              id="name"
              formControlName="name"
            />
            <span
              class="warning"
              *ngIf="
                contactForm.controls.name.touched &&
                !contactForm.controls.name.valid
              "
              >Feld bitte ausfüllen</span
            >
          </div>
          <div class="form-group">
            <label for="email">E-Mail <span>*</span></label>
            <input
              [ngClass]="{
                'invalid-input':
                  contactForm.controls.email.touched &&
                  !contactForm.controls.email.valid
              }"
              type="email"
              name="email"
              id="email"
              formControlName="email"
            />
            <span
              class="warning"
              *ngIf="
                contactForm.controls.email.touched &&
                !contactForm.controls.email.valid
              "
              >Feld bitte ausfüllen</span
            >
          </div>
          <div class="form-group">
            <label for="nachricht">Nachricht <span>*</span></label>
            <textarea
              [ngClass]="{
                'invalid-input':
                  contactForm.controls.nachricht.touched &&
                  !contactForm.controls.nachricht.valid
              }"
              name="nachricht"
              id="nachricht"
              cols="30"
              rows="10"
              formControlName="nachricht"
            ></textarea>
            <span
              class="warning"
              *ngIf="
                contactForm.controls.nachricht.touched &&
                !contactForm.controls.nachricht.valid
              "
              >Feld bitte ausfüllen</span
            >
          </div>
          <span style="font-size: 0.9rem"
            ><span style="color: red">*</span> Pflichtfeld</span
          ><br /><br />
          <div class="success" *ngIf="success">
            Vielen Dank für Ihre Nachricht! Wir werden Ihre Anfrage umgehend
            bearbeiten.
          </div>
          <button [disabled]="!contactForm.valid" type="submit" class="button">
            <span>Nachricht senden</span>
          </button>
        </form>
      </div>
    </main>
  </div>
</div>
