import { Component } from '@angular/core';

@Component({
  selector: 'app-anova-wiederholung',
  templateUrl: './anova-wiederholung.component.html',
  styleUrls: ['./anova-wiederholung.component.scss']
})
export class AnovaWiederholungComponent {

}
