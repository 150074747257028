<main>
  <h1>Statistik Auswertung in SPSS</h1>
  <img style="max-width: 100px;" src="../../../assets/img/spss-logo.png" alt="SPSS LOGO">
  <p> Das Programm <b>SPSS® von IBM</b> ist das wohl am häufigsten genutzte Medium für die Datenanalyse in Human- und
    Wirtschaftswissenschaften. Es wurde über Jahrzehnte immer beliebter, weil es eine sehr einfach zu bedienende
    Benutzeroberfläche besitzt. Über verschiedene Reiter kann man sich schnell und einfach zu den gängigsten
    Analysen durchklicken. Das Erstellen neuer Variablen durch Transformation oder Recodierung ist dabei auch
    vergleichsweise einfach. Gleichzeitig bietet die <b>SPSS Syntax</b> ein breites Spektrum individueller
    Programmierungen, die mit andern Programmiersprachen erweitert werden können. Der Grund warum <b>SPSS®</b> in
    den letzten Jahren jedoch immer weiter an Popularität eingebüßt hat liegt vermutlich am Preis. Ein
    Abonnement der neusten Version kostet aktuell 95,53€ im Monat. <b>Jedoch bietet IBM auch eine 14-tägige
      kostenlose Testversion an:</b> <br>
    <a href="https://www.ibm.com/account/reg/de-en/signup?formid=urx-19774" rel="noopener noreferrer"
      target="_blank">https://www.ibm.com/account/reg/de-en/signup?formid=urx-19774</a>
  </p>
  <p>
    Für eine gut geplante Bachelor-, Master- oder Hausarbeit ist dies durchaus ausreichend. Zudem bieten
    viele Fakultäten kostenlose Lizenzen an. Ein weiterer Nachteil von <b>SPSS®</b> liegt vermutlich in
    der reduzierten Flexibilität bezüglich Datenstrukturen und Analysen. Wer statistische Verfahren
    nutzen möchte, die nicht zu den Standardmethoden gehören, stößt mit <b>SPSS®</b> schnell an die
    Grenzen. Hier haben sich in den letzten Jahren immer mehr die Programmiersprachen <b>R</b>
    und <b>Python</b> durchgesetzt. Nicht zuletzt, weil diese open source und damit kostenlos
    sind.
  </p>
  <p>
    Für <b>SPSS</b> gibt es jedoch auch kostenlose Upgrades. Mit <b>PROCESS</b> hat Andrew
    Hayes ein kostenloses Macro erstellt mit dem du ganz einfach anspruchsvolle
    Mediationsmodelle, Moderationsmodelle und sämtliche
    Kombinationsmöglichkeiten daraus, berechnen kannst. Zudem kommt der Ansatz
    des Bootstrapping zum Einsatz. Den kostenlosen Download findest du hier: <br>
    <a href="https://www.processmacro.org/download.html" rel="noopener noreferrer"
      target="_blank">https://www.processmacro.org/download.html</a>
  </p>
  <p>
    Eine weitere Erweiterungen von <b>SPSS®</b> ist zum Beispiel auch
    <b>AMOS</b>. Dabei handelt es sich um ein einfach zu bedienendes Grafik
    Tool, mit denen du Konfirmatorische Faktoranalysen <b> (CFA) </b>
    und Strukturgleichungsmodelle <b> (SEM/ SGM)</b> berechnen
    kannst. <b>AMOS</b> ist wieder kostenpflichtig, IBM bietet
    jedoch eine 30-tägige Testversion an (s. AGB IBM). <br>
    <a href="https://www.ibm.com/account/reg/de-en/signup?formid=urx-14553" rel="noopener noreferrer"
      target="_blank">https://www.ibm.com/account/reg/de-en/signup?formid=urx-14553</a>
  </p>
  <img src="../../../assets/img/SPSS Screenshots/spss-datenansicht.png" alt="SPSS Datenansicht">
  <p>
    Die Handhabung der Analysen ist in <b>SPSS®</b>
    vergleichsweise einfach. Hier ist die
    Datenansicht zu sehen.
  </p>
  <img src="../../../assets/img/SPSS Screenshots/variablen-ansicht.png" alt="Variablenansicht">
  <p>
    Zudem bietet <b>SPSS®</b> eine Variablenansicht
    mit der Variableneigenschaften wie
    Skalenniveaus, Variablentypen, Label…etc.
    ganz einfach verändert werden können.
  </p>
  <img src="../../../assets/img/SPSS Screenshots/syntax-ansicht.png" alt="Syntaxansicht">
  <p>Die <b>SPSS®</b> Syntax bietet die
    Möglichkeit alle Schritte auch zu
    programmieren und damit zu
    protokollieren. Gerade bei großen
    Datenmengen kann man hier viel Zeit
    sparen. Zudem sind oft nicht alle
    möglichen Transformationen mit den
    Drop-Down-Menüs möglich. <br>
    Weitere kostenlose Alternativen zu
    <b>SPSS®</b> stellen Programme mit
    ähnlicher Benutzeroberfläche wie
    <b>PSPP</b>, <b>Jamovi</b> oder
    <b>Jasp</b> dar.
  </p>
</main>
<p>
  Brauchst Du Hilfe bei deiner Auswertung in <b>SPSS®</b> kannst Du uns jederzeit kontaktieren.
</p>
<div class="button" routerLink="/kostenloses-erstgepraech">
  <span>Kostenloses Erstgespräch</span>
</div>
<p>
  Wir melden uns dann am selben Tag bei Dir.
</p>
