import { Component } from '@angular/core';

@Component({
  selector: 'app-forecasting',
  templateUrl: './forecasting.component.html',
  styleUrls: ['./forecasting.component.scss']
})
export class ForecastingComponent {

}
