import { Component, OnInit } from '@angular/core';
import { BlogViewService } from './blog-view.service';
import { BlogPost } from '../shared/BlogPost';

@Component({
  selector: 'app-blog-view',
  templateUrl: './blog-view.component.html',
  styleUrls: ['./blog-view.component.scss'],
})
export class BlogViewComponent implements OnInit {
  constructor(private blogService: BlogViewService) {}

  allBlogPosts: BlogPost[] = null;
  releasedBlogPosts: BlogPost[] = null;
  loaded = false;
  blogShortText: string;

  ngOnInit(): void {
    this.blogService.getAllBlogPosts().subscribe((fetchedBlogPosts) => {
      if (fetchedBlogPosts.length > 0) {
        this.allBlogPosts = fetchedBlogPosts.map((blogPost) => {
          blogPost.keywords = blogPost.keywords.replaceAll(',', ' -');
          blogPost.blogImage =
            'https://desire-rest-api.de:3000/images/blogImages/' +
            blogPost.blogImage;
          let endWord = 250;
          for (let i = 250; i < 300; i++) {
            if (blogPost.rawtext[i] === ' ') {
              break;
            }
            endWord++;
          }
          blogPost.rawtext = blogPost.rawtext.substring(0, endWord);
          this.loaded = true;
          return blogPost;
        });
        this.releasedBlogPosts = this.allBlogPosts
          .filter((x) => x.released)
          .reverse();
      }
    });
  }
}
