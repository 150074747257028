import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { Bewertung } from 'src/app/shared/Bewertung';

@Component({
  selector: 'app-edit-reviews',
  templateUrl: './edit-reviews.component.html',
  styleUrls: ['./edit-reviews.component.scss'],
})
export class EditReviewsComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    private cookieService: CookieService
  ) {}

  bewertungenForm: FormGroup;
  editBewertungenForm: FormGroup;
  selectReviewForm: FormGroup;

  neueBewertung = false;
  editBewertung = false;
  selectBewertung = false;

  bewertungenSubmitted = false;
  password: string;
  alleBewertungen: Bewertung[] = [];
  bewertungToEdit: Bewertung;

  ngOnInit() {
    this.bewertungenForm = this.fb.group({
      headline: [''],
      maintext: [''],
    });
    this.password = this.cookieService.get('Login');

    this.http
      .get<Bewertung[]>('https://desire-rest-api.de:3000/konrad/bewertungen')
      .subscribe((res) => {
        const newRes = res.map((review) => {
          const newBewertung = review.bewertung
            .split('</b>')[0]
            .replaceAll('<b>', '');
          return { _id: review._id, bewertung: newBewertung };
        });
        this.alleBewertungen = newRes;
        this.alleBewertungen.sort((a, b) => {
          if (
            a.bewertung.split('.')[2].substring(0, 4) <
              b.bewertung.split('.')[2].substring(0, 4) &&
            a.bewertung.split('.')[1] < b.bewertung.split('.')[1] &&
            a.bewertung.split('.')[0] < b.bewertung.split('.')[0]
          ) {
            return 1;
          }
          if (
            a.bewertung.split('.')[2].substring(0, 4) ==
              b.bewertung.split('.')[2].substring(0, 4) &&
            a.bewertung.split('.')[1] < b.bewertung.split('.')[1] &&
            a.bewertung.split('.')[0] < b.bewertung.split('.')[0]
          ) {
            return 1;
          }
          if (
            a.bewertung.split('.')[2].substring(0, 4) ==
              b.bewertung.split('.')[2].substring(0, 4) &&
            a.bewertung.split('.')[1] == b.bewertung.split('.')[1] &&
            a.bewertung.split('.')[0] < b.bewertung.split('.')[0]
          ) {
            return 1;
          }
          return -1;
        });
      });

    this.selectReviewForm = this.fb.group({
      selectReview: null,
    });

    this.editBewertungenForm = this.fb.group({
      headline: null,
      maintext: null,
    });
  }

  newReview() {
    this.neueBewertung = true;
    this.editBewertung = false;
    this.selectBewertung = false;

    this.bewertungenForm = this.fb.group({
      headline: [''],
      maintext: [''],
    });
  }

  findReview() {
    this.neueBewertung = false;
    this.selectBewertung = true;

    this.selectReviewForm = this.fb.group({
      selectReview: this.alleBewertungen,
    });
  }

  editReview() {
    this.neueBewertung = false;
    this.editBewertung = true;

    this.bewertungToEdit = this.selectReviewForm.value.selectReview;
    this.http
      .get<Bewertung>(
        'https://desire-rest-api.de:3000/konrad/bewertungen/' +
          this.bewertungToEdit
      )
      .subscribe((fetchedReview) => {
        const bewertungSplit = fetchedReview.bewertung.split('</b>');

        const bewertungHeadline = bewertungSplit[0].replaceAll('<b>', '');
        const bewertungMain = bewertungSplit[1]
          .replaceAll('<p>', '')
          .replaceAll('</p>', '')
          .replaceAll('<br>', '\n');
        this.editBewertungenForm = this.fb.group({
          headline: bewertungHeadline,
          maintext: bewertungMain,
        });
      });
  }

  submitEditedBewertung() {
    const headline = '<b>' + this.editBewertungenForm.value.headline + '</b>';
    const bewertungsText = this.editBewertungenForm.value.maintext.replaceAll(
      '\n',
      '<br>'
    );
    const bewertung = {
      bewertung: headline + bewertungsText,
      password: this.password,
    };
    const ID = this.bewertungToEdit;

    this.http
      .put(
        'https://desire-rest-api.de:3000/konrad/bewertungen/' + ID,
        bewertung
      )
      .subscribe();
    this.editBewertungenForm.reset();
  }

  submitBewertung() {
    const headline = '<b>' + this.bewertungenForm.value.headline + '</b>';
    const bewertungsText =
      '<p>' +
      this.bewertungenForm.value.maintext.replaceAll('\n', '<br>') +
      '</p>';
    const bewertung = {
      bewertung: headline + bewertungsText,
      password: this.password,
    };

    this.http
      .post('https://desire-rest-api.de:3000/konrad/bewertungen', bewertung)
      .subscribe();
    this.bewertungenForm.reset();
    this.bewertungenSubmitted = true;
    setTimeout(() => {
      this.bewertungenSubmitted = false;
    }, 5000);
  }

  deleteBewerung() {
    const ID = this.bewertungToEdit;
    if (confirm('Bewertung wirklich löschen?')) {
      this.http
        .delete('https://desire-rest-api.de:3000/konrad/bewertungen/' + ID)
        .subscribe();
      this.bewertungenForm.reset();
    }
  }
}
