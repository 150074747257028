<div class="component-wrapper footer-wrapper">
  <div class="footer">
    <div class="kontakt">
      <div>Hanseatic Statistics</div>
      <div>Tarpenbekstr. 49</div>
      <div>20251 Hamburg</div>
      <div><a href="tel:+494022634973">+49 40 22634973</a></div>
      <div>
        <a href="mailto:Beratung@Hanseatic-Statistics.de"
          >Beratung&#64;Hanseatic-Statistics.de</a
        >
      </div>
    </div>
    <div class="footer-links">
      <a routerLink="/impressum">Impressum</a><span> | </span>
      <a routerLink="/disclaimer">Disclaimer</a><span> | </span>
      <a routerLink="/datenschutz">Datenschutz</a><span> | </span>
      <a routerLink="/login">Login</a>
    </div>
  </div>
</div>
