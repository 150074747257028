import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-hypothesen',
  templateUrl: './hypothesen.component.html',
  styleUrls: ['./hypothesen.component.scss'],
})
export class HypothesenComponent implements OnInit {
  constructor(private route: ActivatedRoute, private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle('Hanseatic Statistics - Hypothesen testen');
    this.route.fragment.subscribe((fragment: string) => {
      if (fragment) this.scroll(fragment);
    });
  }

  scroll(id: string) {
    const el = document.getElementById(id);
    el.scrollIntoView();
    window.scrollBy(0, -100);
  }
}
