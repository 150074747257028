import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-med-r',
  templateUrl: './med-r.component.html',
  styleUrls: ['./med-r.component.scss'],
})
export class MedRComponent implements OnInit {
  ngOnInit(): void {
    window.scrollTo(0, 0);
  }
}
