import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { BlogPost } from '../shared/BlogPost';

@Injectable({
  providedIn: 'root',
})
export class BlogViewService {
  constructor(private http: HttpClient, private cookieService: CookieService) {
    this.cookieService.get('Login');
  }

  getBlogPost(blogID: string) {
    return this.http.get<BlogPost>('https://desire-rest-api.de:3000/blog/' + blogID);
  }

  getAllBlogPosts() {
    return this.http.get<BlogPost[]>('https://desire-rest-api.de:3000/blog');
  }

  postBlog(blogPost: FormData) {
    return this.http.post('https://desire-rest-api.de:3000/blog', blogPost);
  }

  updateBlog(ID: string, blogPost: BlogPost) {
    return this.http.put('https://desire-rest-api.de:3000/blog/' + ID, blogPost)
  }

  deleteBlog(ID: string) {
    return this.http.delete('https://desire-rest-api.de:3000/blog/' + ID)
  }

}
