import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-python-auswertung',
  templateUrl: './python-auswertung.component.html',
  styleUrls: ['./python-auswertung.component.scss']
})
export class PythonAuswertungComponent implements OnInit {

  constructor(private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle('Hanseatic Statistics - Statistik Auswertung (PYTHON)')
  }

}
