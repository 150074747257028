import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BlogViewComponent } from './blog-view/blog-view.component';
import { SingleBlogViewComponent } from './blog-view/single-blog-view/single-blog-view.component';
import { BusinessComponent } from './business/business.component';

import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { DsgvoComponent } from './dsgvo/dsgvo.component';
import { EffekstaerkenrechnerComponent } from './effekstaerkenrechner/effekstaerkenrechner.component';
import { ErstgespraechComponent } from './erstgespraech/erstgespraech.component';
import { FragebogenJamoviComponent } from './fragebogen/fragebogen-jamovi/fragebogen-jamovi.component';
import { FragebogenJaspComponent } from './fragebogen/fragebogen-jasp/fragebogen-jasp.component';
import { FragebogenRComponent } from './fragebogen/fragebogen-r/fragebogen-r.component';
import { FragebogenSpssComponent } from './fragebogen/fragebogen-spss/fragebogen-spss.component';
import { FragebogenStataComponent } from './fragebogen/fragebogen-stata/fragebogen-stata.component';
import { FragebogenComponent } from './fragebogen/fragebogen.component';
import { HomeComponent } from './home/home.component';
import { HypothesenComponent } from './hypothesen/hypothesen.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { LeistungenComponent } from './leistungen/leistungen.component';
import { EditArCountComponent } from './login/edit-ar-count/edit-ar-count.component';
import { EditBlogComponent } from './login/edit-blog/edit-blog.component';
import { EditPriceComponent } from './login/edit-price/edit-price.component';
import { EditReviewsComponent } from './login/edit-reviews/edit-reviews.component';
import { LoginComponent } from './login/login.component';
import { PreiseComponent } from './preise/preise.component';
import { QualiComponent } from './quali/quali.component';
import { ReferenzenComponent } from './referenzen/referenzen.component';
import { SkalenniveauComponent } from './skalenniveau/skalenniveau.component';
import { AnovaOneWayComponent } from './stat-test/anova-one-way/anova-one-way.component';
import { LinearRegComponent } from './stat-test/linear-reg/linear-reg.component';
import { LogRegComponent } from './stat-test/log-reg/log-reg.component';
import { MedRComponent } from './stat-test/mod-med/med-r/med-r.component';
import { MedSpssComponent } from './stat-test/mod-med/med-spss/med-spss.component';
import { ModMedComponent } from './stat-test/mod-med/mod-med.component';
import { ModRComponent } from './stat-test/mod-med/mod-r/mod-r.component';
import { ModSpssComponent } from './stat-test/mod-med/mod-spss/mod-spss.component';
import { StatTestComponent } from './stat-test/stat-test.component';
import { TTestLComponent } from './stat-test/t-test-l/t-test-l.component';
import { TTestQComponent } from './stat-test/t-test-q/t-test-q.component';
import { UTestQComponent } from './stat-test/u-test-q/u-test-q.component';
import { JamoviAuswertungComponent } from './statistik-auswertung/jamovi-auswertung/jamovi-auswertung.component';
import { JaspAuswertungComponent } from './statistik-auswertung/jasp-auswertung/jasp-auswertung.component';
import { MatlabAuswertungComponent } from './statistik-auswertung/matlab-auswertung/matlab-auswertung.component';
import { PlsAuswertungComponent } from './statistik-auswertung/pls-auswertung/pls-auswertung.component';
import { PythonAuswertungComponent } from './statistik-auswertung/python-auswertung/python-auswertung.component';
import { RAuswertungComponent } from './statistik-auswertung/r-auswertung/r-auswertung.component';
import { SpssAuswertungComponent } from './statistik-auswertung/spss-auswertung/spss-auswertung.component';
import { StataAuswertungComponent } from './statistik-auswertung/stata-auswertung/stata-auswertung.component';
import { StatistikAuswertungComponent } from './statistik-auswertung/statistik-auswertung.component';
import { TestDevComponent } from './test-dev/test-dev.component';
import { HomeCorporateComponent } from './home-corporate/home-corporate.component';
import { EntryComponent } from './entry/entry.component';
import { VisualsierungComponent } from './visualsierung/visualsierung.component';
import { ForecastingComponent } from './forecasting/forecasting.component';
import { DatenanalyseComponent } from './datenanalyse/datenanalyse.component';
import { PreisCorpComponent } from './preis-corp/preis-corp.component';
import { ErstgespraechBusinessComponent } from './erstgespraech-business/erstgespraech-business.component';
import { AnovaWiederholungComponent } from './stat-test/anova-wiederholung/anova-wiederholung.component';
import { KruskalComponent } from './stat-test/kruskal/kruskal.component';
import { WilcoxonComponent } from './stat-test/wilcoxon/wilcoxon.component';

const appRoutes: Routes = [
  { path: '', component: EntryComponent },
  { path: 'home', component: HomeComponent },
  {
    path: 'business',
    component: BusinessComponent,
  },
  { path: 'business/kontakt', component: ErstgespraechBusinessComponent },
  { path: 'business/preise', component: PreisCorpComponent },

  { path: 'blog', component: BlogViewComponent },
  { path: 'blog/:blogID', component: SingleBlogViewComponent },
  { path: 'statistik-hilfe', component: LeistungenComponent },
  { path: 'statistische-tests', component: StatTestComponent },
  { path: 'test-dev', component: TestDevComponent },
  {
    path: 'statistische-tests/testuebersicht',
    component: StatTestComponent,
    children: [
      { path: 'lineare-regression', component: LinearRegComponent },
      { path: 'logistische-regression', component: LogRegComponent },
      { path: 't-test-unabhängig', component: TTestQComponent },
      { path: 'u-test-unabhängig', component: UTestQComponent },
      { path: 't-test-abhängig', component: TTestLComponent },
      { path: 'anova-one-way-multiple', component: AnovaOneWayComponent },
      {
        path: 'anova-wiederholte-messung',
        component: AnovaWiederholungComponent,
      },
      {
        path: 'moderation-mediation',
        component: ModMedComponent,
      },
      { path: 'kruskal-wallis', component: KruskalComponent},
      { path: 'wilcoxon', component: WilcoxonComponent}
    ],
  },

  {
    path: 'moderation-in-spss',
    component: ModSpssComponent,
  },
  {
    path: 'moderation-in-r',
    component: ModRComponent,
  },
  {
    path: 'mediation-in-spss',
    component: MedSpssComponent,
  },
  {
    path: 'mediation-in-r',
    component: MedRComponent,
  },
  {
    path: 'statistische-tests/hypothesen-tests',
    component: HypothesenComponent,
  },
  {
    path: 'statistische-tests/effektstärkenrechner',
    component: EffekstaerkenrechnerComponent,
  },
  {
    path: 'statistik-auswertung-datenanalyse',
    component: StatistikAuswertungComponent,
    children: [
      { path: 'spss-auswertung', component: SpssAuswertungComponent },
      { path: 'r-auswertung', component: RAuswertungComponent },
      { path: 'jamovi-auswertung', component: JamoviAuswertungComponent },
      { path: 'stata-auswertung', component: StataAuswertungComponent },
      { path: 'python-auswertung', component: PythonAuswertungComponent },
      { path: 'jasp-auswertung', component: JaspAuswertungComponent },
      { path: 'matlab-auswertung', component: MatlabAuswertungComponent },
      { path: 'smartpls-auswertung', component: PlsAuswertungComponent },
    ],
  },
  {
    path: 'fragebogen-auswertung',
    component: FragebogenComponent,
    children: [
      {
        path: 'fragebogen-auswertung-spss',
        component: FragebogenSpssComponent,
      },
      {
        path: 'fragebogen-auswertung-jasp',
        component: FragebogenJaspComponent,
      },
      {
        path: 'fragebogen-auswertung-jamovi',
        component: FragebogenJamoviComponent,
      },
      { path: 'fragebogen-auswertung-r', component: FragebogenRComponent },
      {
        path: 'fragebogen-auswertung-stata',
        component: FragebogenStataComponent,
      },
    ],
  },
  { path: 'kostenloses-erstgepraech', component: ErstgespraechComponent },
  { path: 'qualifikationen', component: QualiComponent },
  { path: 'referenzen', component: ReferenzenComponent },
  { path: 'preise', component: PreiseComponent },
  { path: 'impressum', component: ImpressumComponent },
  { path: 'datenschutz', component: DsgvoComponent },
  { path: 'disclaimer', component: DisclaimerComponent },
  {
    path: 'login',
    component: LoginComponent,
    children: [
      { path: 'edit-blog', component: EditBlogComponent },
      { path: 'edit-abschlussarbeiten', component: EditArCountComponent },
      { path: 'edit-reviews', component: EditReviewsComponent },
      { path: 'edit-price', component: EditPriceComponent },
    ],
  },
  { path: 'skalenniveau', component: SkalenniveauComponent },
  { path: 'home-corporate', component: HomeCorporateComponent },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(appRoutes, {})],
  exports: [RouterModule],
})
export class RoutingModule {}
