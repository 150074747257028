import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fragebogen-spss',
  templateUrl: './fragebogen-spss.component.html',
  styleUrls: ['./fragebogen-spss.component.scss']
})
export class FragebogenSpssComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
