<main>
  <h1>Statistik Auswertung in Python</h1>
  <img style="max-width: 100px;" src="../../../assets/img/python-logo.png" alt="PYTHON LOGO">
  <p>Eine Datenanalyse und Statistikauswertung mit der vollwertigen Programmiersprache <b>Python</b> gehört zu den
    anspruchsvollsten Möglichkeiten der Datenauswertung. <b>Python</b> kann eigentlich alles und ist daher die erste
    Wahl in den Disziplinen Data Science und Data Analysis. Besonders beliebt ist <b>Python</b> seit einigen
    Jahren im Bereich des Machine Learnings. Damit hat sich diese Sprache für wissenschaftliche Zwecke in
    beinahe allen Naturwissenschaften qualifiziert.
  </p>
  <p>
    Weil <b>Python</b> aber eigentlich alles kann und als vollwertige Programmiersprache eher in den
    IT-Bereich zählt, ist die Schwelle zu einer flüssigen und schlüssigen Analyse jedoch recht hoch.
  </p>

  <b>Python</b> ist open source und kostenfrei downloadbar unter: <br>
  <a href="https://www.python.org/downloads/" rel="noopener noreferrer"
    target="_blank">https://www.python.org/downloads/</a>
  <p>
    Ähnlich wie in <b>R</b> bietet sich auch hier die Benutzung einer IDE an. Zu empfehlen ist
    dabei das <b>Jupyter Notebook</b>, <b>Google Colab</b> oder <b>Spyder</b> for scientific
    purposes.
  </p>
  <a href="https://jupyter.org/" rel="noopener">https://jupyter.org/</a> <br>
  <a href="https://www.spyder-ide.org/" rel="noopener">https://www.spyder-ide.org/</a> <br>
  <img src="../../../assets/img/Python Screenshots/python-bitcoin-prediction.png" alt="Python Bitcoin prediction"> <br>
  Bitcoin Kursvorhersage mit <b>Python</b> in <b>Google Colab</b> mit Hilfe
  eines RNN (Krahl, 2021). <br><br>

</main>
<p>
  Brauchst Du Hilfe bei deiner Auswertung in <b>Python</b> kannst Du
  uns jederzeit kontaktieren.
</p>
<div class="button" routerLink="/kostenloses-erstgepraech">
  <span>Kostenloses Erstgespräch</span>
</div>
<p>Wir melden uns dann am selben Tag bei Dir.</p>
