import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { GtmService } from '../services/gtm.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-erstgespraech',
  templateUrl: './erstgespraech.component.html',
  styleUrls: ['./erstgespraech.component.scss'],
})
export class ErstgespraechComponent implements OnInit {
  constructor(
    private http: HttpClient, 
    private titleService: Title, 
    private gtmService: GtmService, 
    private router: Router,
    private cookieService: CookieService
  ) {}

  contactForm: UntypedFormGroup;
  success = false;
  
  ngOnInit(): void {
    this.titleService.setTitle('Hanseatic Statistics - Erstgespräch');
    this.contactForm = new UntypedFormGroup({
      name: new UntypedFormControl(null, Validators.required),
      email: new UntypedFormControl(null, [
        Validators.required,
        Validators.email,
      ]),
      nachricht: new UntypedFormControl(null, Validators.required),
    });
    
    // Auf Routen-Änderungen reagieren und Page-View-Events senden
    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        // Nur Events senden, wenn Cookies akzeptiert wurden
        if (this.cookieService.get('cookieconsent_status') === 'allow') {
          const gtmTag = {
            event: 'page',
            pageName: item.url
          };
          this.gtmService.pushEvent(gtmTag);
          console.log('Page-View-Event gesendet:', item.url);
        }
      }
    });

    // GTM manuell laden (zum Testen)
    if (this.cookieService.get('cookieconsent_status') === 'allow') {
      // GTM nur laden, wenn Einwilligung vorliegt
      this.gtmService.loadGtm();
      console.log('GTM wurde manuell in ErstgespraechComponent geladen');
    }
  }

  onSubmit() {
    const mail = '<b>E-Mail: </b>' + this.contactForm.value.email + '<br>';
    const name = '<b>Name: </b>' + this.contactForm.value.name + '<br>';
    const nachricht =
      '<b>Nachricht: </b>' + this.contactForm.value.nachricht + '<br>';
    const contactMessage = name + mail + nachricht;
    
    // Formular-Event senden, wenn Cookies akzeptiert wurden
    if (this.cookieService.get('cookieconsent_status') === 'allow') {
      this.gtmService.pushEvent({
        event: 'form_submit',
        category: 'contact',
        action: 'submit',
        label: 'erstgespraech_contact_form',
        value: 1
      });
      console.log('Formular-Submit-Event wurde gesendet');
    }
    
    this.http
      .post(
        'https://desire-rest-api.de:3000/konrad/contact',
        { content: contactMessage },
        { responseType: 'text' }
      )
      .subscribe(() => {
        this.contactForm.reset();
        this.success = true;
        
        // Erfolgs-Event senden, wenn Cookies akzeptiert wurden
        if (this.cookieService.get('cookieconsent_status') === 'allow') {
          this.gtmService.pushEvent({
            event: 'form_submit_success',
            category: 'contact',
            action: 'success',
            label: 'erstgespraech_contact_form'
          });
          console.log('Formular-Success-Event wurde gesendet');
        }
        
        setTimeout(() => {
          this.success = false;
        }, 5000);
      },
      (error) => {
        // Fehler-Event senden, wenn Cookies akzeptiert wurden
        if (this.cookieService.get('cookieconsent_status') === 'allow') {
          this.gtmService.pushEvent({
            event: 'form_submit_error',
            category: 'contact',
            action: 'error',
            label: 'erstgespraech_contact_form',
            error: error.status || 'unknown'
          });
          console.log('Formular-Error-Event wurde gesendet');
        }
      });
  }
}