import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-quali',
  templateUrl: './quali.component.html',
  styleUrls: ['./quali.component.scss']
})
export class QualiComponent implements OnInit {
  constructor(private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle('Hanseatic Statistics - Qualifikationen');
  }

  mas = [
    {
      name: 'David',
      img: '../../assets/img/ma/David.jpg',
      text: 'Ich habe 10 Jahre Erfahrung in der Lehre von Studenten, habe Psychologie studiert und in Neurowissenschaften promoviert und weiß wie das Gehirn am besten lernt bzw. kann individuell gut einschätzen, was der/die jeweilige Student/in benötigt, um Wissen zu integrieren und zu behalten. Ich finde es wichtig, erstmal einzuschätzen wie die individuelle Person am besten lernt und dann den Unterricht so zu gestalten, dass man einen optimalen Lerneffekt erzeugt.',
    },
    {
      name: 'Dario',
      img: '../../assets/img/ma/Dario.jpg',
      text: 'Während meiner Schulzeit entdeckte ich eine Faszination für Wahrscheinlichkeitsrechnung und Statistik - das Quantifizieren des Unvorhersehbaren und die Transformation des scheinbar Chaotischen in etwas Greifbares und Vorhersehbares. Dennoch führte mich mein Interesse an zwischenmenschlichen Beziehungen und dem menschlichen Verhalten zum Studium der Psychologie. Hier, neben dem Studium der klinischen Psychologie, erkannte ich, wie sehr mir die Statistik lag. Nach meinem Studium verwendete ich diese neu entdeckte Stärke in der Forschung zur Persönlichkeitspsychologie, wo meine Vorliebe für Methodik und Statistik gefordert und gefördert wurde. Ein Wechsel in die klinische Praxis folgte, in der ich zwei Jahre lang tätig war. Trotz dieser beruflichen Veränderung ließ mich meine Leidenschaft für Zahlen und Daten nicht los. Daher entschied ich mich, ein weiteres Studium in Data Science zu beginnen, dass ich zurzeit abschließe. Mit dieser einzigartigen Kombination aus psychologischer und statistischer Expertise trage ich zur Vielfalt unseres Teams bei. Ich freue mich darauf, sowohl mein Wissen über menschliches Verhalten als auch meine Fähigkeiten in der Statistik und Datenanalyse einzubringen.',
    },
    {
      name: 'Manuel',
      img: '../../assets/img/ma/Manuel.jpg',
      text: 'Bereits während meines Studiums der Wirtschaftswissenschaften an der Leibniz Universität führte ich für wissenschaftliche Arbeiten statistische Analysen durch und brachte mir die dafür nötigen Kenntnisse in R weitestgehend selbst bei. In meiner jetzigen Tätigkeit als Fondsmanager für ETF´s habe ich eher weniger mit Statistik zu tun, weshalb ich die Tätigkeit als Dozent für Hanseatic Statistics als willkommene Möglichkeit betrachte meine Statistikkenntnisse auch weiterhin zu nutzen. Durch meinen Wirtschaftshintergrund habe ich einen „praktischeren“ Bezug zu Statistik und kann mich dementsprechend sehr gut in jene hineinversetzen, bei denen Statistik eher ein Nebenthema ist. Daher kann ich die Anfangs komplex anmutenden Themen der Statistik gut und leicht verständlich erklären.',
    },
    {
      name: 'Patrick',
      img: '../../assets/img/ma/Patrick.jpg',
      text: 'Hi, ich bin Patrick Kaschel und habe Psychologie sowie Survey-Statistik im Master studiert. Ich helfe schon länger gerne Personen bei Statistikfragen und arbeite nun nebenberuflich hier im Team mit. Teilweise publiziere ich zudem auch selbst und fertige Statistik für Publikationen in der Forschung an. ',
    },
    {
      name: 'Ramon',
      img: '../../assets/img/ma/Ramon.jpg',
      text: 'Hallo, mein Name ist Ramon Bringewatt. Ich bin Arbeits- und Organisationspsychologe (M.Sc.) und habe meine Masterarbeit zum Thema "Klassische und moderne Klassifikationsverfahren" geschrieben. Seit 2019 bin ich in Vollzeit als freiberuflicher Statistiker tätig. Mein Schwerpunkt ist das interdisziplinäre Arbeiten.',
    },
    {
      name: 'Tim',
      img: '../../assets/img/ma/Tim.jpg',
      text: 'Hi, ich bin Tim Søren Beige. Ich habe 2017 meinen Masterabschluss in Statistik an der TU Dortmund gemacht. Bereits während meines Studiums habe ich durch zahlreiche Tutoren und Dozentenjobs meinen Spaß an der Lehre gefunden und versuche auch heute noch leidenschaftlich gerne auch Nicht-Statistikern über Hanseatic die Magie der Zahlen näher zu bringen. Meine statistischen Stärken liegen im Bereich der Prognosemodelle mittels Regressionsverfahren und anderen Verfahren im Bereich maschinelles Lernen (Random Forest, Neuronale Netze, GBM, etc.) sowie der Zusammenhangs- und der Varianzanalyse und dem Credit-Scoring. Ich freue mich auf die Zusammenarbeit mit dir/euch.',
    },
    {
      name: 'Lukas',
      img: '../../assets/img/ma/Lukas.jpg',
      text: 'Lu hat Sozialökonomik im Master an der Friedrich-Alexander-Universität Erlangen-Nürnberg (FAU) studiert. Während des Studiums hat Lu beim Institut für Arbeitsmarkt- und Berufsforschung (IAB) in Nürnberg sowie beim Deutschen Institut für Wirtschaftsforschung (DIW) gearbeitet. Während der Zeit bei Hanseatic Statistics hat Lu bereits sehr vielfältige Projekte in den Bereichen Soziologie, Psychologie, Finanzökonomik, Politikwissenschaften und Medizin abgeschlossen.',
    },
    {
      name: 'William',
      img: '../../assets/img/ma/William.jpg',
      text: 'Hello World, mein Name is William Nguyen Quoc Phong, und ich bin ein freiberuflicher Dozent und Data-Coach bei Hanseatic Statistics. Als Unternehmensberater habe ich mich auf die Bereiche Business Intelligence, Business Analysis und Data Analysis spezialisiert.      Meinen akademischen Hintergrund habe ich mit einem Master-Abschluss in Betriebswirtschaftslehre (M.Sc.) mit Schwerpunkten in "Investment & Finanzierung" an der Universität Cottbus erworben. Während meiner akademischen Laufbahn habe ich vielfältige Erfahrungen in den Bereichen Datenanalyse, Business Intelligence & Analytics, Statistik, Data Science und Machine Learning durch verschiedene Studien- und Post-Graduate-Programme gesammelt. Ich verbinde meine akademische Expertise mit praktischer Erfahrung, die ich bei renommierten Unternehmens- und Wirtschaftsprüfungsberatungsgesellschaft und Banken gesammelt habe. Mein professioneller Hintergrund in Statistik und Datenanalyse ermöglicht es mir, komplexe Projekte in verschiedenen Branchen, darunter Wirtschaft, Politik, Soziologie, Medizin und Psychologie, erfolgreich mit Tools wie R, Jamovi, SPSS und Python durchzuführen. Mein Tech-Stack umfasst auch SQL, Power BI und Tableau, mit denen ich Daten in aussagekräftige Einblicke verwaneln kann. Mein Ziel ist es, dein Projekt in der computergestützen Analyse und Forschung zu unterstützen um neue Erkenntnisse zu gewinnen oder datenbasierte Entscheidungen zu treffen.',
    },
    {
      name: "Christoph",
      img: '../../assets/img/ma/Christoph.jpg',
      text: "Herzlich willkommen! Mein Name ist Christoph Beckmann. Als Psychologe (M.Sc.) hege ich eine tiefe Leidenschaft für komplexe Forschungsfragen. Mein akademischer Werdegang ermöglichte mir den Erwerb fundierter wissenschaftlicher Kenntnisse, die ich nun begeistert mit Ihnen teilen möchte. Insbesondere während meiner Forschung zu den Auswirkungen des Homeoffice auf Arbeitnehmer und Führungskräfte erkannte ich die transformative Kraft datengetriebener Analysen – ein prägender Moment in meiner beruflichen Laufbahn. Bereits vor und während meines Studiums war ich im Bereich der Programmierung tätig. Diese technische Neigung intensivierte meine Faszination für Statistik und Daten. In der Folge spezialisierte ich mich auf Data Science und erlangte zusätzliche Qualifikationen durch renommierte Zertifikate von Weltkonzernen wie IBM und Google.Mit meinem umfassenden Wissen in Psychologie, Programmierung und Datenanalyse biete ich eine Perspektive, die sowohl menschliche Aspekte als auch technische Expertise berücksichtigt. Datenanalysen bringen oft verborgene Muster und Verbindungen ans Licht, die unsere Sichtweise erweitern und das Potenzial besitzen, unsere Welt zu beeinflussen. Mein Ziel? Die Geheimnisse in Ihren Daten zu enthüllen, sie in klare Einsichten zu übersetzen und Sie bei der akademischen Darstellung zu unterstützen."
    },
    {
      name: "Theresa",
      img: '../../assets/img/ma/Theresa.png',
      text: "Hallo, ich bin Theresa und habe Psychologie im Master studiert. Bereits während des Studiums fand ich Statistik super spannend und habe einige vertiefende Kurse und Nebenfächer belegt. Mittlerweile arbeite ich in der klinischen Praxis, habe dort aber schnell gemerkt, dass mir die Beschäftigung mit statistischen Fragestellungen fehlt. Seit Oktober 2023 arbeite ich nun nebenberuflich hier im Team mit."
    },
    {
      name: "Niklas",
      img: '../../assets/img/ma/Niklas.jpg',
      text: "Nach meinem Studium der Volkswirtschaftslehre an der Universität Göttingen mit dem Schwerpunkt Internationale Wirtschaft und empirische Methodik habe ich schließlich angefangen als Dozent fürdie Universität Leuphana empirische Methodik und angewandte Statistik sowie Software-Kurse mit Stata zu unterrichten. Darüber hinaus gebe ich für Mitarbeiter:innen des ifo Wirtschaftsforschungsinstitut und der Deutschen Forschungsgemeinschaft (DFG) regelmäßig Seminare im Bereich der Statistik- und Ökonometrie und setze hierfür die Software Stata, Python und R ein. Außerdem habe ich mich als Dozent darauf spezialisiert, Studierende in dem methodischenTeil ihrer Bachelor- und Masterarbeiten zu beraten, zu meinem Spezialgebiet gehören u.a.(dynamische) Panel-Datenanalysen, eigene Grafik-Programme für Konturenplots, innovative Korrelations-Heatmaps und 3-D-Scatterplots mit Stata, Python und R. Darüber hinaus habe ich einen weiteren Schwerpunkt im Bereich von Forecastingtechniken mit Methoden des Maschinellen Lernens (Lasso-Elastic Net, Random Forest und SVM etc.) sowie Neuronalen Netzen, die ich in Hinblick auf ihre Vorhersagegüte vergleichend gegenüberstelle bzw. miteinander kombiniere und für meine Doktorarbeit für Forecastings einsetzen werde.Darüber hinaus habe ich im Laufe meiner Dissertation ein neues Programm mit Stata zur Umsetzung eines neuen empirisches Testverfahren über Homoskedastizität entwickelt.In der Lehre an der Leuphana-Universität habe ich u.a. anschauliche Grafiken und Digitale Tools mithilfe von Smartphones als Fernbedienung für interaktive Umfragen und innovatives Lernen eingesetzt, um Studierende für analytische Fächer zu motivieren."
    },
  ];
}
