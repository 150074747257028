import { Component } from '@angular/core';

@Component({
  selector: 'app-home-corporate',
  templateUrl: './home-corporate.component.html',
  styleUrls: ['./home-corporate.component.scss']
})
export class HomeCorporateComponent {

}
