<div class="component-wrapper impressum">
  <div class="content-wrapper">
    <h4>Impressum</h4>
    <p><u>Angaben gem. § 5 TMG:</u></p>
    <p>
      Konrad, Krahl <br />
      Tarpenbekstr. 49<br />
      20251 Hamburg
    </p>
    <br />
    <u>Kontaktaufnahme:</u>
    <p>
      Telefon:
      <a style="color: black" href="tel:+494022634973">+49 40 22634973</a>
    </p>
    <p>
      E-Mail:
      <a style="color: black" href="mailto:Beratung@Hanseatic-Statistics.de"
        >Beratung&#64;Hanseatic-Statistics.de</a
      >
    </p>
    <br />
    <p>
      <u>Umsatzsteuer-Identifikationsnummer gem. § 27 a Umsatzsteuergesetz:</u>
    </p>
    <b>Umsatzsteuer-ID:</b> 49/129/02647
  </div>
</div>
