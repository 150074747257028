<div class="component-wrapper login">
  <div class="content-wrapper">
    <div *ngIf="!loggedIn" class="login-form">
      <form [formGroup]="loginForm" (ngSubmit)="submitLogin()">
        <div class="form-group">
          <label for="password">Passwort</label><br />
          <input
            type="password"
            name="password"
            id="password"
            formControlName="password"
          />
        </div>
        <button class="button" type="submit"><span>Submit</span></button>
      </form>
    </div>

    <div *ngIf="loggedIn" class="admin-area">
      <div class="buttons">
        <div
        routerLink="edit-abschlussarbeiten"
          [ngClass]="{ 'green-bg': showAbschlussarbeitenform }"
          (click)="handleShowAbschlussarbeiten()"
          class="button-small"
        >
          Abschlussarbeiten
        </div>
        <div
        routerLink="edit-reviews"
          [ngClass]="{ 'green-bg': showBewerungsform }"
          (click)="handleShowBewerbungen()"
          class="button-small"
        >
          Bewertungen
        </div>
        <div
        routerLink="edit-blog"
          [ngClass]="{ 'green-bg': showBlogInterface }"
          (click)="handleShowBlog()"
          class="button-small"
        >
          Blog Interface
        </div>
        <div
        routerLink="edit-price"
          [ngClass]="{ 'green-bg': showPriceInterface }"
          (click)="handleShowPrice()"
          class="button-small"
        >
          Preise
        </div>
      </div>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
