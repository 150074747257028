import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fragebogen-r',
  templateUrl: './fragebogen-r.component.html',
  styleUrls: ['./fragebogen-r.component.scss']
})
export class FragebogenRComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
