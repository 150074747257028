<main>
  <h1>Statistik Auswertung in R</h1>
  <img
    style="max-width: 100px"
    src="../../../assets/img/r-logo.png"
    alt="R LOGO"
  />
  <p>
    Die Programmiersprache <b>R </b>ist eines der mächtigsten Tools in der Data
    Science und Data Analysis Welt. Sie ist frei zugänglich, kostenlos und wird
    von tausenden WissenschaftlerInnen ständig weiterentwickelt. Jeder kann
    eigene Packages schreiben und integrieren. Der Vorteil von <b>R</b> liegt
    klar darin, dass es beinahe alles kann. Da die Sprache alt ist (1993),
    wurden für beinahe alle statistischen Probleme bereits Lösungen gefunden.
    Mit modernen Entwicklerumgebungen wie <b>R Studio</b>, ist das Programmieren
    relativ einfach geworden. Der Einstieg ist schwerer als zum Beispiel mit
    <b>SPSS®</b>. Wenn du dich jedoch erst einmal durch die Logik der
    <b>R</b> Datenstrukturen, -Funktionen, -Metafunktionen etc. gearbeitet hast,
    steht dir ein kostenloses Tool zur Verfügung, mit dem du beinahe jedes
    Problem lösen kannst. <br />
    Dies ist vermutlich auch der Grund, warum <b>R</b> in den letzten Jahren
    immer häufiger in den Human-, Natur und Wirtschaftswissenschaften wie
    Psychologie, Wirtschaftspsychologie, Medizin, BWL, VWL, etc. anzutreffen
    ist.
  </p>
  Download <b>R</b>:
  <a
    href="https://cran.r-project.org/"
    rel="noopener noreferrer"
    target="_blank"
    >https://cran.r-project.org/</a
  >
  <br />
  Download IDE <b>R Studio</b>:
  <a
    href="https://www.rstudio.com/products/rstudio/download/"
    rel="noopener noreferrer"
    target="_blank"
    >https://www.rstudio.com/products/rstudio/download/</a
  >
  <p>
    <img src="../../../assets/img/R Screenshots/r-studio.png" alt="R Studio" />
    In <b>R Studio</b> kann man ein Projekt in <b>R</b> relativ einfach
    umsetzen. Standardmäßig stehen hier 4 Felder für die Datenanalyse zur
    Verfügung. Oben Links befindet sich dein Skript. Hier kannst du Code
    schreiben und anschließend abspielen. Darunter befindet sich die Konsole.
    Diese zeigt Ergebnisse an, eignet sich aber auch für die Code Eingabe. Oben
    rechts befindet sich die Umgebung. Alle Objekte, eigene Funktionen, Data
    Frames, etc. sind hier abgebildet. Darunter ist ein zusätzliches Fenster für
    Dateien oder Grafiken.
  </p>
</main>
Brauchst Du Hilfe bei deiner Auswertung in <b>R</b> kannst Du uns jederzeit
kontaktieren.
<div class="button" routerLink="/kostenloses-erstgepraech">
  <span>Kostenloses Erstgespräch</span>
</div>
<p>Wir melden uns dann am selben Tag bei Dir.</p>
<h2>Das Hanseatic Statistics R Package</h2>
<p>
  Weil wir Profis in der Datenanalyse mit R sind, haben wir auch unser eigenes
  Package programmiert. Hier siehst du ein Beispiel dafür.
</p>

<div class="youtube-wrapper">
  <p *ngIf="allowCookies">
    Hier eine Hilfe, wie ihr das richtige Testverfahren findet
  </p>
  <iframe
    *ngIf="allowCookies"
    width="560"
    height="315"
    src="https://www.youtube.com/embed/qFqr7i_S31I"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  ></iframe>
  <h4 *ngIf="!allowCookies">Youtube Einbindung</h4>
  <div *ngIf="!allowCookies" class="youtube-denied">
    Hier solltest du eigentlich ein YouTube Video mit einer Erklärung sehen, wie
    man unsere Testauswahl benutzt. Da wir aber deine Privatsphäre respektieren,
    binden wir es aufgrund deiner Cookie-Entscheidung nicht ein. Falls du dich
    doch dafür entscheiden solltest, akzeptiere unten einfach unsere
    Cookie-Richtlinie.
  </div>
</div>
