<main>
  <h1>Statistik Auswertung in Jamovi</h1>
  <img style="max-width: 100px;" src="../../../assets/img/jamovi-logo.png" alt="JAMOVI LOGO">
  <p>Jamovi bietet im Grunde die Synthese aus einer einfachen Benutzeroberfläche, wie der von <b>SPSS®</b>, und der
    Programmiersprache <b>R</b>. Die gesamte Datenaufbereitung und Analyse funktionieren über Drop-Down-Menüs, jedoch
    können etliche, auf <b>R</b> basierende Module installiert werden. <b>Jamovi</b> ist ein kostenloses, open
    source Programm. Zudem sind bereits viele moderne, bzw. wieder moderne Ansätze der Statistik
    integriert. In den meisten Analysen sind sowohl klassische Statistik (Signifikanztests),
    Effektstärkenberechnung nach Cohen (1988; 1992) und bayesianische Statistik integriert. Zudem
    erstellet <b>Jamovi</b> recht einfach einen ästhetischen grafischen Output.</p>
  Download <b>JAMOVI</b>: <a href="https://www.jamovi.org/download.html"
    rel="noopener noreferrer" target="_blank">https://www.jamovi.org/download.html</a>
  <p>Ein Nachteil ist jedoch, dass besonders die Datentransformation und Umstrukturierung
    Kenntnisse in <b>R</b> erfordern. Wer wiederum bereits Kenntnisse in <b>R</b> besitzt,
    empfindet die Datenaufbereitung großer Datenmengen in <b>Jamovi</b> wahrscheinlich
    als unnötig umständlich. <br>
    Wegen der genannten Vorteile kommt <b>Jamovi</b> jedoch in den vergangenen
    Jahren immer häufiger in human-, natur- und wirtschaftswissenschaftlichen
    Bereichen zum Einsatz.</p>
    <img src="../../../assets/img/Jamovi Screenshot/jamovi-IDE.png" alt="Jamovi IDE">
</main>
<p>Brauchst Du Hilfe bei deiner Auswertung in <b>Jamovi</b> kannst Du uns
  jederzeit kontaktieren.</p>
<div class="button" routerLink="/kostenloses-erstgepraech">
  <span>Kostenloses Erstgespräch</span>
</div>
<p>Wir melden uns dann am selben Tag bei Dir.</p>
