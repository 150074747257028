<div class="stat-test component-wrapper">
  <div *ngIf="showTest" class="content-wrapper">
    <h1>Auswahl des geeigneten statistischen Tests</h1>
    <p>
      Hier findest du eine Übersicht aller gängigen Verfahren und Tests, welche
      zur Datenanalyse eignen. Damit du bei den ganzen verschiedenen Verfahren
      nicht den Überblick verlierst, haben wir dir eine interaktive Übersicht
      gebaut, die dich sicher zur der für dich passenden Lösung führt.
    </p>
    <h2>Welcher Test ist zu wählen?</h2>
    <p>
      Um herauszufinden welchen Test du benutzen musst stehen am Anfang
      Überlegungen zum Untersuchungsdesign und der Hypothesenformulierung.
      Nachdem die unabhängigen- (UV) und abhängigen (AV) Variablen definiert
      sind, musst du die Skalenniveaus bestimmen. Von diesen hängt ab, ob du
      beispielsweise parametrische oder nicht-parametrische Verfahren nutzen
      kannst. Zudem haben fast alle Verfahren Voraussetzungen die geprüft werden
      müssen. Dazu zählt immer auch eine ausreichende interne Konsistenz/
      Reliabilität bei der Skalenerstellung. Und zuletzt musst du entscheiden,
      mit welcher Software/ Programmiersprache du deine Analyse umsetzen willst.
    </p>

    <div class="youtube-wrapper">
      <p *ngIf="allowCookies">
        Hier eine Hilfe, wie ihr das richtige Testverfahren findet
      </p>
      <iframe
        *ngIf="allowCookies"
        width="560"
        height="315"
        src="https://www.youtube.com/embed/zmuNAAE4t6w"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <h4 *ngIf="!allowCookies">Youtube Einbindung</h4>
      <div *ngIf="!allowCookies" class="youtube-denied">
        Hier solltest du eigentlich ein YouTube Video mit einer Erklärung sehen,
        wie man unsere Testauswahl benutzt. Da wir aber deine Privatsphäre
        respektieren, binden wir es aufgrund deiner Cookie-Entscheidung nicht
        ein. Falls du dich doch dafür entscheiden solltest, akzeptiere unten
        einfach unsere Cookie-Richtlinie.
      </div>
    </div>
    <h2>Datenanalyse: Verfahren und Tests:</h2>
  </div>
  <!-- ----------------- TESTBAUM WRAPPER ----------------- -->
  <div
    style="margin-top: 2rem"
    class="button button-left"
    *ngIf="!showTest"
    routerLink="/statistische-tests/testuebersicht"
    (click)="showTest = true; resetTests()"
  >
    <span>Zurück zur Testauswahl</span>
  </div>
  <div class="test-baum" *ngIf="showTest">
    <div class="button button-left delete-button" (click)="resetTests()">
      <span>Testauswahl zurücksetzen</span>
    </div>
    <!-- ----------------- LEGENDE MOBILE ----------------- -->
    <div class="legende-mobile">
      <div>
        <input type="checkbox" [(ngModel)]="uvn" id="uvn" />
        <label for="uvn">UV nominal (2 Level / > 2 Level)</label>
      </div>
      <div>
        <input type="checkbox" [(ngModel)]="uvo" id="uvo" />
        <label for="uvo">UV ordinal</label>
      </div>
      <div>
        <input type="checkbox" [(ngModel)]="uvm" id="uvm" />
        <label for="uvm">UV metrisch</label>
      </div>
      <div>
        <input type="checkbox" [(ngModel)]="multuv" id="multuv" />
        <label for="multuv">Mehrere UV möglich</label>
      </div>
      <div>
        <input type="checkbox" [(ngModel)]="avn" id="avn" />
        <label for="avn">AV nominal</label>
      </div>
      <div>
        <input type="checkbox" [(ngModel)]="avo" id="avo" />
        <label for="avo">AV ordinal</label>
      </div>
      <div>
        <input type="checkbox" [(ngModel)]="avm" id="avm" />
        <label for="avm">AV metrisch</label>
      </div>
      <div>
        <input type="checkbox" [(ngModel)]="multav" id="multav" />
        <label for="multav">Mehrere AV möglich</label>
      </div>
      <div>
        <input type="checkbox" [(ngModel)]="var1" id="var1" />
        <label for="var1">max. 1 Variable im Test</label>
      </div>
      <div>
        <input type="checkbox" [(ngModel)]="quer" id="quer" />
        <label for="quer">Querschnitt</label>
      </div>
      <div>
        <input type="checkbox" [(ngModel)]="laengs" id="laengs" />
        <label for="laengs">Längsschnitt</label>
      </div>
    </div>
    <!-- ----------------- LEGENDE DESKTOP ----------------- -->
    <div class="legende-desktop">
      <input type="checkbox" id="uvnc" [(ngModel)]="uvnc" class="checkbox" />
      <label for="uvnc" [ngClass]="{ active: uvnc }">
        <div (mouseenter)="uvn = true" (mouseleave)="uvn = false">
          <span class="uv-n"></span>UV nominal (2 Level / > 2Level)
        </div>
      </label>
      <input type="checkbox" id="uvoc" [(ngModel)]="uvoc" class="checkbox" />
      <label for="uvoc" [ngClass]="{ active: uvoc }">
        <div (mouseenter)="uvo = true" (mouseleave)="uvo = false">
          <span class="uv-o"></span>UV ordinal
        </div>
      </label>
      <input type="checkbox" id="uvmc" [(ngModel)]="uvmc" class="checkbox" />
      <label for="uvmc" [ngClass]="{ active: uvmc }">
        <div (mouseenter)="uvm = true" (mouseleave)="uvm = false">
          <span class="uv-m"></span>UV metrisch
        </div>
      </label>
      <input type="checkbox" id="var1c" [(ngModel)]="var1c" class="checkbox" />
      <label for="var1c" [ngClass]="{ active: var1c }">
        <div (mouseenter)="var1 = true" (mouseleave)="var1 = false">
          <span class="var-1"></span>Es gibt nur eine Variable im Test
        </div>
      </label>
      <input type="checkbox" id="qc" [(ngModel)]="qc" class="checkbox" />
      <label for="qc" [ngClass]="{ active: qc }">
        <div (mouseenter)="q = true" (mouseleave)="q = false">
          <span class="quer-leg"></span>Querschnitt
        </div>
      </label>
      <input type="checkbox" id="lc" [(ngModel)]="lc" class="checkbox" />
      <label for="lc" [ngClass]="{ active: lc }">
        <div (mouseenter)="l = true" (mouseleave)="l = false">
          <span class="laengs-leg"></span>Längsschnitt
        </div>
      </label>
      <input type="checkbox" id="avnc" [(ngModel)]="avnc" class="checkbox" />
      <label for="avnc" [ngClass]="{ active: avnc }">
        <div (mouseenter)="avn = true" (mouseleave)="avn = false">
          <span class="av-n"></span>AV nominal
        </div>
      </label>
      <input type="checkbox" id="avoc" [(ngModel)]="avoc" class="checkbox" />
      <label for="avoc" [ngClass]="{ active: avoc }">
        <div (mouseenter)="avo = true" (mouseleave)="avo = false">
          <span class="av-o"></span>AV ordinal
        </div>
      </label>
      <input type="checkbox" id="avmc" [(ngModel)]="avmc" class="checkbox" />
      <label for="avmc" [ngClass]="{ active: avmc }">
        <div (mouseenter)="avm = true" (mouseleave)="avm = false">
          <span class="av-m"></span>AV metrisch
        </div>
      </label>
      <input
        type="checkbox"
        id="multavc"
        [(ngModel)]="multavc"
        class="checkbox"
      />
      <label for="multavc" [ngClass]="{ active: multavc }">
        <div (mouseenter)="multav = true" (mouseleave)="multav = false">
          <span class="mult-av"></span>Mehrere AV möglich
        </div>
      </label>
      <input
        type="checkbox"
        id="multuvc"
        [(ngModel)]="multuvc"
        class="checkbox"
      />
      <label for="multuvc" [ngClass]="{ active: multuvc }">
        <div (mouseenter)="multuv = true" (mouseleave)="multuv = false">
          <span class="mult-uv"></span>Mehrere UV möglich
        </div>
      </label>
    </div>
    <!-- ----------------- TESTBAUM MOBILE ----------------- -->
    <div class="test-baum-inner-mobile">
      <h2>Testverfahren</h2>

      <!-- ----------------- unterschiede ----------------- -->

      <div
        routerLink="t-test-unabhängig"
        (click)="showTest = false"
        [ngClass]="{
          'shine-out':
            uvo ||
            uvm ||
            var1 ||
            quer ||
            laengs ||
            avn ||
            avo ||
            multuv ||
            multav ||
            l ||
            uvoc ||
            uvmc ||
            var1c ||
            querc ||
            laengsc ||
            avnc ||
            avoc ||
            multuvc ||
            multavc ||
            l
        }"
        class="head t-test-quer av-m uv-n q"
      >
        T-Test (unabhängig)
      </div>
      <div
        routerLink="u-test-unabhängig"
        (click)="showTest = false"
        [ngClass]="{
          'shine-out':
            uvo ||
            uvm ||
            var1 ||
            quer ||
            laengs ||
            avn ||
            avm ||
            multuv ||
            multav ||
            l ||
            uvoc ||
            uvmc ||
            var1c ||
            querc ||
            laengsc ||
            avnc ||
            avmc ||
            multuvc ||
            multavc ||
            lc
        }"
        class="head u-test-quer av-o uv-n q"
      >
        U-Test
      </div>
      <div
        routerLink="anova-one-way-multiple"
        (click)="showTest = false"
        [ngClass]="{
          'shine-out':
            uvo ||
            uvm ||
            var1 ||
            quer ||
            laengs ||
            avn ||
            avo ||
            multav ||
            l ||
            uvoc ||
            uvmc ||
            var1c ||
            querc ||
            laengsc ||
            avnc ||
            avoc ||
            multavc ||
            lc
        }"
        class="head anova-quer av-m uv-n q"
      >
        ANOVA (einfaktoriell, mehrfaktoriell)
      </div>
      <div
        (click)="showTest = false"
        routerLink="kruskal-wallis"
        [ngClass]="{
          'shine-out':
            uvo ||
            uvm ||
            var1 ||
            quer ||
            laengs ||
            avn ||
            avm ||
            multav ||
            l ||
            uvoc ||
            uvmc ||
            var1c ||
            querc ||
            laengsc ||
            avnc ||
            avmc ||
            multavc ||
            lc
        }"
        class="head kruskal av-o uv-n q"
      >
        Kruskal Wallis
      </div>
      <div
        routerLink="t-test-abhängig"
        (click)="showTest = false"
        [ngClass]="{
          'shine-out':
            uvn ||
            uvo ||
            uvm ||
            var1 ||
            quer ||
            laengs ||
            avn ||
            avo ||
            multuv ||
            multav ||
            q ||
            uvnc ||
            uvoc ||
            uvmc ||
            var1c ||
            querc ||
            laengsc ||
            avnc ||
            avoc ||
            multuvc ||
            multavc ||
            qc
        }"
        class="head t-test-laengs av-m uv-n l"
      >
        T-Test (abhängig)
      </div>
      <div
        (click)="showTest = false"
        routerLink="wilcoxon"
        [ngClass]="{
          'shine-out':
            uvn ||
            uvo ||
            uvm ||
            var1 ||
            quer ||
            laengs ||
            avn ||
            avm ||
            multuv ||
            multav ||
            q ||
            uvnc ||
            uvoc ||
            uvmc ||
            var1c ||
            querc ||
            laengsc ||
            avnc ||
            avmc ||
            multuvc ||
            multavc ||
            qc
        }"
        class="head wilcoxon av-o uv-n l"
      >
        Wilcoxon
      </div>
      <div
        routerLink="anova-wiederholte-messung"
        (click)="showTest = false"
        [ngClass]="{
          'shine-out':
            uvn ||
            uvo ||
            uvm ||
            var1 ||
            quer ||
            laengs ||
            avn ||
            avo ||
            multav ||
            q ||
            uvoc ||
            uvnc ||
            uvmc ||
            var1c ||
            querc ||
            laengsc ||
            avnc ||
            avoc ||
            multavc ||
            qc
        }"
        class="head anova-laengs av-m uv-n l"
      >
        ANOVA (wiederholte Messungen)
      </div>
      <div
        (click)="showTest = false"
        [ngClass]="{
          'shine-out':
            uvn ||
            uvo ||
            uvm ||
            var1 ||
            quer ||
            laengs ||
            avn ||
            avm ||
            multav ||
            q ||
            uvoc ||
            uvnc ||
            uvmc ||
            var1c ||
            querc ||
            laengsc ||
            avnc ||
            avmc ||
            multavc ||
            qc
        }"
        class="head friedmann av-o uv-n l"
      >
        Friedmann
      </div>
      <div class="head varianzen ebene-2">Varianzen</div>
      <div
        (click)="showTest = false"
        [ngClass]="{
          'shine-out':
            uvn ||
            uvo ||
            uvm ||
            quer ||
            laengs ||
            avn ||
            avo ||
            multuv ||
            multav ||
            l ||
            q ||
            uvnc ||
            uvoc ||
            uvmc ||
            querc ||
            laengsc ||
            avnc ||
            avoc ||
            multuvc ||
            multavc ||
            lc ||
            qc
        }"
        class="head chi2-var av-m var-1"
      >
        Chi²
      </div>
      <div
        (click)="showTest = false"
        [ngClass]="{
          'shine-out':
            uvo ||
            uvm ||
            var1 ||
            quer ||
            laengs ||
            avn ||
            avo ||
            multuv ||
            multav ||
            l ||
            q ||
            uvoc ||
            uvmc ||
            var1c ||
            querc ||
            laengsc ||
            avnc ||
            avoc ||
            multuvc ||
            multavc ||
            lc ||
            qc
        }"
        class="head f-test av-m uv-n"
      >
        F-Test
      </div>
      <div class="head proportionen ebene-2">Proportionen</div>

      <div
        (click)="showTest = false"
        [ngClass]="{
          'shine-out':
            uvn ||
            uvo ||
            uvm ||
            quer ||
            laengs ||
            avn ||
            avo ||
            multuv ||
            multav ||
            l ||
            q ||
            uvnc ||
            uvoc ||
            uvmc ||
            querc ||
            laengsc ||
            avnc ||
            avoc ||
            multuvc ||
            multavc ||
            lc ||
            qc
        }"
        class="head binom av-m var-1"
      >
        Binomial
      </div>

      <div
        (click)="showTest = false"
        [ngClass]="{
          'shine-out':
            uvo ||
            uvm ||
            var1 ||
            quer ||
            laengs ||
            avm ||
            multuv ||
            multav ||
            l ||
            q ||
            uvoc ||
            uvmc ||
            var1c ||
            querc ||
            laengsc ||
            avmc ||
            multuvc ||
            multavc ||
            lc ||
            qc
        }"
        class="head pearson uv-n av-n-av-o"
      >
        Pearson Chi²
      </div>
      <!-- ----------------- zusammenhänge ----------------- -->
      <div
        (click)="showTest = false"
        [ngClass]="{
          'shine-out':
            uvo ||
            uvm ||
            var1 ||
            quer ||
            laengs ||
            avm ||
            multuv ||
            multav ||
            l ||
            q ||
            uvoc ||
            uvmc ||
            var1c ||
            querc ||
            laengsc ||
            avmc ||
            multuvc ||
            multavc ||
            lc ||
            qc
        }"
        class="head chi2-zu uv-n av-n-av-o"
      >
        Chi²
      </div>
      <div
        (click)="showTest = false"
        [ngClass]="{
          'shine-out':
            uvn ||
            var1 ||
            quer ||
            laengs ||
            avn ||
            multuv ||
            multav ||
            l ||
            q ||
            uvnc ||
            var1c ||
            querc ||
            laengsc ||
            avnc ||
            multuvc ||
            multavc ||
            lc ||
            qc
        }"
        class="head spearman uv-o-m av-o-av-m"
      >
        Spearman Korrelation
      </div>
      <div
        (click)="showTest = false"
        [ngClass]="{
          'shine-out':
            uvn ||
            uvo ||
            var1 ||
            quer ||
            laengs ||
            avn ||
            avo ||
            multav ||
            l ||
            q ||
            uvnc ||
            uvoc ||
            var1c ||
            querc ||
            laengsc ||
            avnc ||
            avoc ||
            multavc ||
            lc ||
            qc
        }"
        class="head pearson-corr uv-m av-m"
      >
        Pearson Korrelation
      </div>
      <div
        routerLink="lineare-regression"
        (click)="showTest = false"
        [ngClass]="{
          'shine-out':
            uvo ||
            var1 ||
            quer ||
            laengs ||
            avn ||
            avo ||
            multav ||
            l ||
            q ||
            uvoc ||
            var1c ||
            querc ||
            laengsc ||
            avnc ||
            avoc ||
            multavc ||
            lc ||
            qc
        }"
        class="head lin-r av-m uv-n-uv-m"
      >
        Lineare Regression
      </div>
      <div
        routerLink="logistische-regression"
        (click)="showTest = false"
        [ngClass]="{
          'shine-out':
            uvo ||
            var1 ||
            quer ||
            laengs ||
            avo ||
            avm ||
            multuv ||
            multav ||
            l ||
            q ||
            uvoc ||
            var1c ||
            querc ||
            laengsc ||
            avoc ||
            avmc ||
            multuvc ||
            multavc ||
            lc ||
            qc
        }"
        class="head log-r av-n uv-n-uv-m"
      >
        Logistische Regression
      </div>
      <!-- ----------------- strukturreduktion ----------------- -->
      <div
        (click)="showTest = false"
        [ngClass]="{
          'shine-out':
            var1 ||
            quer ||
            laengs ||
            uvn ||
            uvo ||
            uvm ||
            avn ||
            multuv ||
            multav ||
            l ||
            q ||
            var1c ||
            querc ||
            laengsc ||
            uvnc ||
            avnc ||
            avoc ||
            avmc ||
            multuvc ||
            multavc ||
            lc ||
            qc
        }"
        class="head fac-anal av-o-av-m"
      >
        Faktoranalyse PCA/PAF
      </div>
      <div
        (click)="showTest = false"
        [ngClass]="{
          'shine-out':
            var1 ||
            quer ||
            laengs ||
            uvn ||
            uvo ||
            uvm ||
            multuv ||
            multav ||
            l ||
            q ||
            var1c ||
            querc ||
            laengsc ||
            avnc ||
            uvnc ||
            avoc ||
            avmc ||
            multuvc ||
            multavc ||
            lc ||
            qc
        }"
        class="head clust-anal av-n-av-o-av-m"
      >
        Clusteranalyse
      </div>
      <div
        (click)="showTest = false"
        routerLink="anova-one-way-multiple"
        [ngClass]="{
          'shine-out':
            uvo ||
            uvm ||
            var1 ||
            quer ||
            laengs ||
            avn ||
            avo ||
            l ||
            uvoc ||
            uvmc ||
            var1c ||
            querc ||
            laengsc ||
            avnc ||
            avoc ||
            lc
        }"
        class="head ancova av-m uv-n q"
      >
        ANCOVA / MANOVA / MANCOVA
      </div>
      <div
        (click)="showTest = false"
        routerLink="moderation-mediation"
        [ngClass]="{
          'shine-out':
            uvo ||
            uvm ||
            var1 ||
            quer ||
            laengs ||
            avn ||
            avo ||
            l ||
            uvoc ||
            uvmc ||
            var1c ||
            querc ||
            laengsc ||
            avnc ||
            avoc ||
            lc
        }"
        class="head sem av-m uv-n q"
      >
        Moderation / Mediation / SEM
      </div>
      <div
        (click)="showTest = false"
        [ngClass]="{
          'shine-out':
            uvo ||
            uvm ||
            var1 ||
            quer ||
            laengs ||
            avn ||
            avo ||
            q ||
            uvoc ||
            uvmc ||
            var1c ||
            querc ||
            laengsc ||
            avnc ||
            avoc ||
            qc
        }"
        class="head mult-level av-m uv-n q"
      >
        Gemischte Modelle / Multi Level
      </div>
      <div class="copyright">© Konrad Krahl - {{ jahr }}</div>
    </div>
    <!-- ----------------- TESTBAUM DESKTOP ----------------- -->
    <div class="test-baum-inner">
      <!-- ----------------- unterschiede ----------------- -->
      <div class="head unterschiede ebene-1">Unterschiede</div>

      <div class="head zentrale-tendenz ebene-2">Zentrale Tendenz</div>
      <div
        class="head outer-quer outer-multav ancova"
        [ngClass]="{
          shine:
            uvo ||
            uvm ||
            var1 ||
            quer ||
            laengs ||
            avn ||
            avo ||
            l ||
            uvoc ||
            uvmc ||
            var1c ||
            querc ||
            laengsc ||
            avnc ||
            avoc ||
            lc
        }"
      >
        <div
          (click)="showTest = false"
          class="center-box inner-box av-m uv-n q"
          routerLink="anova-one-way-multiple"
        >
          ANCOVA / MANOVA / MANCOVA
        </div>
      </div>
      <div
        class="outer-quer outer-multav sem head"
        [ngClass]="{
          shine:
            uvo ||
            uvm ||
            var1 ||
            quer ||
            laengs ||
            avn ||
            avo ||
            l ||
            uvoc ||
            uvmc ||
            var1c ||
            querc ||
            laengsc ||
            avnc ||
            avoc ||
            lc
        }"
      >
        <div
          (click)="showTest = false"
          class="center-box inner-box av-m uv-n q"
          routerLink="moderation-mediation"
        >
          Moderation / Mediation / SEM
        </div>
      </div>

      <div
        class="outer-laengs outer-multav head mult-level"
        [ngClass]="{
          shine:
            uvo ||
            uvm ||
            var1 ||
            quer ||
            laengs ||
            avn ||
            avo ||
            q ||
            uvoc ||
            uvmc ||
            var1c ||
            querc ||
            laengsc ||
            avnc ||
            avoc ||
            qc
        }"
      >
        <div
          (click)="showTest = false"
          class="center-box inner-box av-m uv-n q"
        >
          Gemischte Modelle / Multi Level
        </div>
      </div>

      <div
        class="outer-quer head t-test-quer"
        [ngClass]="{
          shine:
            uvo ||
            uvm ||
            var1 ||
            quer ||
            laengs ||
            avn ||
            avo ||
            multuv ||
            multav ||
            l ||
            uvoc ||
            uvmc ||
            var1c ||
            querc ||
            laengsc ||
            avnc ||
            avoc ||
            multuvc ||
            multavc ||
            lc
        }"
      >
        <div
          routerLink="t-test-unabhängig"
          (click)="showTest = false"
          class="center-box inner-box av-m uv-n q"
        >
          T-Test (unabhängig)
        </div>
      </div>

      <div
        class="outer-quer head u-test-quer"
        [ngClass]="{
          shine:
            uvo ||
            uvm ||
            var1 ||
            quer ||
            laengs ||
            avn ||
            avm ||
            multuv ||
            multav ||
            l ||
            uvoc ||
            uvmc ||
            var1c ||
            querc ||
            laengsc ||
            avnc ||
            avmc ||
            multuvc ||
            multavc ||
            lc
        }"
      >
        <div
          routerLink="u-test-unabhängig"
          (click)="showTest = false"
          class="center-box inner-box av-o uv-n q"
        >
          U-Test
        </div>
      </div>

      <div
        class="outer-quer head anova-quer"
        [ngClass]="{
          shine:
            uvo ||
            uvm ||
            var1 ||
            quer ||
            laengs ||
            avn ||
            avo ||
            multav ||
            l ||
            uvoc ||
            uvmc ||
            var1c ||
            querc ||
            laengsc ||
            avnc ||
            avoc ||
            multavc ||
            lc
        }"
      >
        <div
          routerLink="anova-one-way-multiple"
          (click)="showTest = false"
          class="center-box inner-box av-m uv-n q"
        >
          ANOVA (einfaktoriell, mehrfaktoriell)
        </div>
      </div>

      <div
        class="outer-quer head kruskal"
        [ngClass]="{
          shine:
            uvo ||
            uvm ||
            var1 ||
            quer ||
            laengs ||
            avn ||
            avm ||
            multav ||
            l ||
            uvoc ||
            uvmc ||
            var1c ||
            querc ||
            laengsc ||
            avnc ||
            avmc ||
            multavc ||
            lc
        }"
      >
        <div
          (click)="showTest = false"
          routerLink="kruskal-wallis"
          class="center-box inner-box av-o uv-n q"
        >
          Kruskal Wallis
        </div>
      </div>

      <div
        class="outer-laengs outer-multav head t-test-laengs"
        [ngClass]="{
          shine:
            uvn ||
            uvo ||
            uvm ||
            var1 ||
            quer ||
            laengs ||
            avn ||
            avo ||
            multuv ||
            q ||
            uvnc ||
            uvoc ||
            uvmc ||
            var1c ||
            multuvc ||
            querc ||
            laengsc ||
            avnc ||
            avoc ||
            qc
        }"
      >
        <div
          routerLink="t-test-abhängig"
          (click)="showTest = false"
          class="center-box inner-box av-m uv-n l"
        >
          T-Test (abhängig)
        </div>
      </div>

      <div
        class="outer-laengs outer-multav head wilcoxon"
        [ngClass]="{
          shine:
            uvn ||
            uvo ||
            uvm ||
            var1 ||
            quer ||
            laengs ||
            avn ||
            avm ||
            multuv ||
            q ||
            uvnc ||
            uvoc ||
            uvmc ||
            var1c ||
            querc ||
            multuvc ||
            laengsc ||
            avnc ||
            avmc ||
            qc
        }"
      >
        <div
          (click)="showTest = false"
          routerLink="wilcoxon"
          class="inner-box center-box av-o uv-n l"
        >
          Wilcoxon
        </div>
      </div>

      <div
        class="outer-laengs outer-multav head anova-laengs"
        [ngClass]="{
          shine:
            uvn ||
            uvo ||
            uvm ||
            var1 ||
            quer ||
            laengs ||
            avn ||
            avo ||
            q ||
            uvnc ||
            uvoc ||
            uvmc ||
            var1c ||
            querc ||
            laengsc ||
            avnc ||
            avoc ||
            qc
        }"
      >
        <div
          routerLink="anova-wiederholte-messung"
          (click)="showTest = false"
          class="center-box inner-box av-m uv-n l"
        >
          ANOVA (wiederholte Messungen)
        </div>
      </div>

      <div
        class="outer-laengs outer-multav head friedmann"
        [ngClass]="{
          shine:
            uvn ||
            uvo ||
            uvm ||
            var1 ||
            quer ||
            laengs ||
            avn ||
            avm ||
            q ||
            uvnc ||
            uvoc ||
            uvmc ||
            var1c ||
            querc ||
            laengsc ||
            avnc ||
            avmc ||
            qc
        }"
      >
        <div
          (click)="showTest = false"
          class="center-box inner-box av-o uv-n l"
        >
          Friedmann
        </div>
      </div>

      <div class="head varianzen ebene-2">Varianzen</div>

      <div
        class="outer-quer head chi2-var"
        [ngClass]="{
          shine:
            uvn ||
            uvo ||
            uvm ||
            quer ||
            laengs ||
            avn ||
            avo ||
            multuv ||
            multav ||
            l ||
            uvnc ||
            uvoc ||
            uvmc ||
            querc ||
            laengsc ||
            avnc ||
            avoc ||
            multuvc ||
            multavc ||
            lc
        }"
      >
        <div (click)="showTest = false" class="center-box inner-box av-m var-1">
          Chi²
        </div>
      </div>

      <div
        class="outer-quer head f-test"
        [ngClass]="{
          shine:
            uvo ||
            uvm ||
            var1 ||
            quer ||
            laengs ||
            avn ||
            avo ||
            multuv ||
            multav ||
            l ||
            uvoc ||
            uvmc ||
            var1c ||
            querc ||
            laengsc ||
            avnc ||
            avoc ||
            multuvc ||
            multavc ||
            lc
        }"
      >
        <div (click)="showTest = false" class="center-box inner-box av-m uv-n">
          F-Test
        </div>
      </div>

      <div class="head proportionen ebene-2">Proportionen</div>

      <div
        class="outer-quer head binom"
        [ngClass]="{
          shine:
            uvn ||
            uvo ||
            uvm ||
            quer ||
            laengs ||
            avn ||
            avo ||
            multuv ||
            multav ||
            l ||
            uvnc ||
            uvoc ||
            uvmc ||
            querc ||
            laengsc ||
            avnc ||
            avoc ||
            multuvc ||
            multavc ||
            lc
        }"
      >
        <div (click)="showTest = false" class="center-box inner-box av-m var-1">
          Binomial
        </div>
      </div>

      <div
        class="outer-quer head pearson"
        [ngClass]="{
          shine:
            uvo ||
            uvm ||
            var1 ||
            quer ||
            laengs ||
            avm ||
            multuv ||
            multav ||
            l ||
            uvoc ||
            uvmc ||
            var1c ||
            querc ||
            laengsc ||
            avmc ||
            multuvc ||
            multavc ||
            lc
        }"
      >
        <div
          (click)="showTest = false"
          class="center-box inner-box uv-n av-n-av-o"
        >
          Pearson Chi²
        </div>
      </div>

      <!-- ----------------- zusammenhänge ----------------- -->
      <div class="head zusammenhänge ebene-1">Zusammenhänge</div>

      <div
        class="outer-quer head chi2-zu"
        [ngClass]="{
          shine:
            uvo ||
            uvm ||
            var1 ||
            quer ||
            laengs ||
            avm ||
            multuv ||
            multav ||
            l ||
            uvoc ||
            uvmc ||
            var1c ||
            querc ||
            laengsc ||
            avmc ||
            multuvc ||
            multavc ||
            lc
        }"
      >
        <div
          (click)="showTest = false"
          class="center-box inner-box uv-n av-n-av-o"
        >
          Chi²
        </div>
      </div>

      <div
        class="outer-quer head spearman"
        [ngClass]="{
          shine:
            uvn ||
            var1 ||
            quer ||
            laengs ||
            avn ||
            multuv ||
            multav ||
            l ||
            uvnc ||
            var1c ||
            querc ||
            laengsc ||
            avnc ||
            multuvc ||
            multavc ||
            lc
        }"
      >
        <div
          (click)="showTest = false"
          class="center-box inner-box uv-o-m av-o-av-m"
        >
          Spearman Korrelation
        </div>
      </div>

      <div
        class="outer-quer head pearson-corr"
        [ngClass]="{
          shine:
            uvn ||
            uvo ||
            var1 ||
            quer ||
            laengs ||
            avn ||
            avo ||
            multav ||
            l ||
            uvnc ||
            uvoc ||
            var1c ||
            querc ||
            laengsc ||
            avnc ||
            avoc ||
            multavc ||
            lc
        }"
      >
        <div (click)="showTest = false" class="center-box inner-box uv-m av-m">
          Pearson Korrelation
        </div>
      </div>

      <div
        class="outer-quer head lin-r"
        [ngClass]="{
          shine:
            uvo ||
            var1 ||
            quer ||
            laengs ||
            avn ||
            avo ||
            multav ||
            l ||
            uvoc ||
            var1c ||
            querc ||
            laengsc ||
            avnc ||
            avoc ||
            multavc ||
            lc
        }"
      >
        <div
          routerLink="lineare-regression"
          (click)="showTest = false"
          class="center-box inner-box av-m uv-n-uv-m"
        >
          Lineare Regression
        </div>
      </div>

      <div
        class="outer-quer head log-r"
        [ngClass]="{
          shine:
            uvo ||
            var1 ||
            quer ||
            laengs ||
            avo ||
            avm ||
            multuv ||
            multav ||
            l ||
            uvoc ||
            var1c ||
            querc ||
            laengsc ||
            avoc ||
            avmc ||
            multuvc ||
            multavc ||
            lc
        }"
      >
        <div
          routerLink="logistische-regression"
          (click)="showTest = false"
          class="center-box inner-box av-n uv-n-uv-m"
        >
          Logistische Regression
        </div>
      </div>

      <div
        class="mult-uv-border"
        [ngClass]="{
          shine:
            var1 ||
            quer ||
            laengs ||
            avn ||
            avo ||
            avm ||
            avnc ||
            avoc ||
            avmc ||
            uvn ||
            uvo ||
            uvm ||
            avn ||
            multav ||
            l ||
            uvnc ||
            uvmc ||
            var1c ||
            querc ||
            laengsc ||
            avnc ||
            avoc ||
            avmc ||
            uvoc ||
            multavc ||
            lc
        }"
      ></div>
      <!-- ----------------- strukturreduktion ----------------- -->
      <div class="head strukturreduktion ebene-1">Strukturreduktion</div>

      <div
        class="outer-quer head fac-anal"
        [ngClass]="{
          shine:
            var1 ||
            quer ||
            laengs ||
            uvn ||
            uvo ||
            uvm ||
            avn ||
            multuv ||
            multav ||
            l ||
            uvnc ||
            uvmc ||
            var1c ||
            querc ||
            laengsc ||
            avnc ||
            avmc ||
            uvoc ||
            multuvc ||
            multavc ||
            lc
        }"
      >
        <div
          (click)="showTest = false"
          class="center-box inner-box av-o-av-m blue-back"
        >
          Factoranalyse PCA/PAF
        </div>
      </div>

      <div
        class="outer-quer head clust-anal"
        [ngClass]="{
          shine:
            var1 ||
            quer ||
            laengs ||
            uvn ||
            uvo ||
            uvm ||
            multuv ||
            multav ||
            l ||
            uvnc ||
            uvmc ||
            var1c ||
            querc ||
            laengsc ||
            avmc ||
            multuvc ||
            uvoc ||
            multavc ||
            lc
        }"
      >
        <div
          (click)="showTest = false"
          class="center-box inner-box av-n-av-o-av-m blue-back"
        >
          Clusteranalyse
        </div>
      </div>
    </div>
    <div class="content-wrapper">
      <div class="copyright" style="color: white; font-size: 0.8rem">
        * angelehnt an Uni Zürich Statistik
      </div>
      <br />
      <div class="copyright" style="color: white">
        © Konrad Krahl - {{ jahr }}
      </div>
    </div>
  </div>
  <div *ngIf="!showTest" class="content-wrapper">
    <h4>Ausgewählter Test</h4>
  </div>
  <router-outlet></router-outlet>
</div>
