import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

declare let gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class GaService {
  constructor(public router: Router) {
    if (typeof gtag === 'function') {
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
          gtag('config', 'G-1PPRVP974X', {
            page_path: event.urlAfterRedirects,
          });
        });
    }
  }
}
