import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-erstgespraech',
  templateUrl: './erstgespraech.component.html',
  styleUrls: ['./erstgespraech.component.scss'],
})
export class ErstgespraechComponent implements OnInit {
  constructor(private http: HttpClient, private titleService: Title, private gtmService: GoogleTagManagerService, private router: Router) {}

  contactForm: UntypedFormGroup;
  success = false;
  ngOnInit(): void {
    this.titleService.setTitle('Hanseatic Statistics - Erstgespräch');
    this.contactForm = new UntypedFormGroup({
      name: new UntypedFormControl(null, Validators.required),
      email: new UntypedFormControl(null, [
        Validators.required,
        Validators.email,
      ]),
      nachricht: new UntypedFormControl(null, Validators.required),
    });
    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url
        };
        this.gtmService.pushTag(gtmTag);
      }
    });

    this.gtmService.addGtmToDom();
  }

  onSubmit() {
    const mail = '<b>E-Mail: </b>' + this.contactForm.value.email + '<br>';
    const name = '<b>Name: </b>' + this.contactForm.value.name + '<br>';
    const nachricht =
      '<b>Nachricht: </b>' + this.contactForm.value.nachricht + '<br>';
    const contactMessage = name + mail + nachricht;
    this.http
      .post(
        'https://desire-rest-api.de:3000/konrad/contact',
        { content: contactMessage },
        { responseType: 'text' }
      )
      .subscribe(() => {
        this.contactForm.reset();
        this.success = true;
        setTimeout(() => {
          this.success = false;
        }, 5000);
      });
  }

  
}
